import Text from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { Colors } from "../../../utils/constants";
import IconButton from "@mui/material/IconButton";
import RSSIcon from "@mui/icons-material/RssFeed";
import InfoIcon from "@mui/icons-material/InfoOutlined";
import MoreIcon from "@mui/icons-material/MoreVert";
import { alertStyles as styles, CustomTooltip } from "./Alerts.style";
import { useGetSearchAlerts } from "../../../api/MyLibrary/SearchAlerts.api";
import { useAppDispatch, useAppSelector } from "../../../store/hooks";
import { useNavigate } from "react-router-dom";
import { GetSearchAlertData } from "../../../types/GetSearchAlerts.types";
import {
  clearSearchTerm,
  updateAdvSearchQuery,
  updateAll,
  updateAllFacets,
  updateAuthorFacets,
  updateDataType,
  updateFullText,
  updateNewSearchStatus,
  updatePage,
  updateRow,
  updateSearchResults,
  updateSearchTerm,
  updateSubjectFacets,
} from "../../../store/slice/searchSlice";
import { useEffect, useState } from "react";
import {
  clearAllFilters,
  clearAllFiltersValue,
  clearPublicationFilter,
  updateAllFilter,
  updateAuthor,
  updateConsortiaCollection,
  updateCountryOfPublication,
  updateCountryOfPublishingAuthor,
  updateEarlyOnline,
  updateInstitution,
  updateJournal,
  updateJournalRank,
  updateMyLibraryCollection,
  updateOpenAccess,
  updatePersonalLibrary,
  updatePublisher,
  updateResearch,
  updateResearcher,
  updateSort,
  updateSourceType,
  updateSpeaker,
  updateSubject,
  updateYearFrom,
} from "../../../store/slice/filterSlice";
import SearchAlertMenu from "./SearchAlertMenu";
import RSSAlertModal from "./RssAlert.modal";
import { stringSanitizer } from "../../SearchHistory/SearchHistory";
import { queryConstructor } from "../../../api/formQuery";
import {
  authorJsonFacetQuery,
  basicSearchForCounts,
  basicSearchMain,
  basicSearchQuery,
  jsonFacetQuery,
  subjectJsonFacetQuery,
} from "../../../api/Search/BasicSearch.api";
import {
  checkArticleExistance,
  findAlertTypeSetting,
  linkToDisplay,
} from "../../SearchHistory/HistoryTable";
import { updateQuery } from "../../../store/slice/queryForCountSlice";
import { getQueryParams } from "../../../utils/helper";
import {
  updateAuthorSearchResults,
  updateAuthorSort,
} from "../../../store/slice/authorFinderSlice";
import { setSearch } from "../../../api/Search/SetSearch";
import {
  updateSetInNumber,
  updateSetSearchQuery,
  updateSetSearchStatus,
} from "../../../store/slice/setSearch";
import { updateQueries } from "../../../store/slice/appliedQueries";
import { CircularProgress, Tooltip } from "@mui/material";
import {
  getPersonalLibraryCollection,
  personalCoreCount,
} from "../../../api/Search/CollectionApi";

const SearchAlerts = () => {
  const selector = useAppSelector((state) => state.login);
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const [selectedRow, updateSelectedRow] = useState<GetSearchAlertData>();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openRss, setOpenRss] = useState(false);
  const [rssData, setRssData] = useState();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const userId = useAppSelector((state) => state.login.user_id);
  const customerData = useAppSelector(
    ({ customer }) => customer.customerDetails
  );
  const [rowNumber, setRowNumber] = useState<null | number>(null);

  const open = Boolean(anchorEl);

  const { data: searchAlerts, refetch } = useGetSearchAlerts(
    selector.user_id,
    false
  );

  useEffect(() => {
    dispatch(clearAllFilters());
    dispatch(clearSearchTerm());
    refetch();
  }, []);

  function closeRssModal() {
    setOpenRss(false);
  }

  function openMenu(
    event: React.MouseEvent<HTMLElement>,
    row: GetSearchAlertData
  ) {
    setAnchorEl(event.currentTarget);
    updateSelectedRow(row);
  }

  const closeMenu = () => {
    setAnchorEl(null);
  };

  function openRssMenu(row: any) {
    setOpenRss(true);
    setRssData(row);
  }

  const handleKeywordClick = async (
    dirtyString: string,
    searchURL: string,
    searchType: string,
    searchtypeId: number
  ) => {
    if (isSearching) return;
    setIsSearching(true);

    const searchTerm = stringSanitizer(dirtyString);

    // for DF-1795
    // if (searchTerm.includes("#S")) {
    //   handleSetSearchClick(searchURL);
    //   return;
    // }
    if (!searchTerm) {
      dispatch(clearSearchTerm());
      setIsSearching(false);
      return;
    }

    const advanceSearchTerms = [
      "resourcemaster_id",
      "title_fz",
      "yearfrom",
      "keywords_fz",
      "author_address_fz",
      "issn",
      "articledoi",
      "author_all_fz",
      "resource_name_tk",
    ];

    let isAdvanceSearchTerm = advanceSearchTerms.some((term) =>
      new RegExp(`\\b${term}\\b`).test(dirtyString)
    );

    const anotherSetOfAdvSearchTerms = [
      "(resourcemaster_id",
      "(title_fz",
      "(yearfrom",
      "(keywords_fz",
      "(author_address_fz",
      "(issn",
      "(articledoi",
      "(author_all_fz",
      "(resource_name_tk",
      "(titleKeywordsAbs",
    ];

    try {
      dispatch(clearSearchTerm());
      dispatch(clearAllFilters());
      dispatch(clearAllFiltersValue());
      dispatch(clearPublicationFilter());

      const missingQueryObj = {
        rows: 15,
        op_mode: "and",
      };

      const fullTextQuery = await queryConstructor({
        "fq__(fulltext": `true OR acl_group=(${customerData?.informaticscustomer_id}))`,
        ...missingQueryObj,
        sort: "dateofpublication desc",
        datsearchtype_id: searchtypeId,
      });
      const allTextQuery = await queryConstructor({
        ...missingQueryObj,
        sort: "dateofpublication desc",
        datsearchtype_id: searchtypeId,
      });

      // replace the "&" with "~_~"
      let replacedQuery = searchURL.replace(/ & /g, ` ~_~ `);
      let fqProfileQuery = `&fq__profileuser_id=${userId}`;

      let actualFullTextQuery = fullTextQuery + "&" + replacedQuery;
      let actualAllextQuery =
        replacedQuery.replace(
          `&fq__(fulltext=true OR acl_group=(${customerData?.informaticscustomer_id}))`,
          ""
        ) +
        "&" +
        allTextQuery;

      const queryParams = getQueryParams(actualAllextQuery);
      let countApiCall, recordApiCall;

      if (queryParams?.fq__profileuser_id) {
        countApiCall = personalCoreCount;
        recordApiCall = getPersonalLibraryCollection;
      } else {
        countApiCall = basicSearchForCounts;
        // recordApiCall = basicSearchMain;
        recordApiCall = basicSearchQuery;
      }

      actualFullTextQuery = actualFullTextQuery.replace(fqProfileQuery, "");
      actualAllextQuery = actualAllextQuery.replace(fqProfileQuery, "");

      const fullTextData = await recordApiCall(actualFullTextQuery);
      // const allText = await countApiCall(actualAllextQuery);
      // const fulltext = await countApiCall(actualFullTextQuery);

      await checkArticleExistance(fullTextData?.docs, userId, dispatch);
      await findAlertTypeSetting(fullTextData?.docs, userId, dispatch);
      await linkToDisplay(fullTextData?.docs, customerData, dispatch);

      dispatch(
        updateQuery({
          fullTextQuery: actualFullTextQuery,
          allTextQuery: actualAllextQuery,
        })
      );

      const queryParamsKeys = Object.keys(queryParams);
      let isAdv = anotherSetOfAdvSearchTerms.some((term) =>
        queryParamsKeys.includes(term)
      );
      isAdvanceSearchTerm = isAdvanceSearchTerm || isAdv;

      updateQueryParamsToRedux(queryParams, dirtyString, isAdvanceSearchTerm);

      dispatch(updateSearchResults(fullTextData));
      dispatch(updateFullText(true));
      dispatch(updateAll(false));
      dispatch(updateRow(15));
      dispatch(updatePage(1));
      dispatch(updateSort("dateofpublication desc"));
      dispatch(updateNewSearchStatus(true));

      if (isAdvanceSearchTerm) {
        let advSearchObj: any = {};
        let arr = [...advanceSearchTerms, ...anotherSetOfAdvSearchTerms];

        arr.forEach((term) => {
          if (queryParams[term]) {
            advSearchObj[term] = queryParams[term];
          }
        });

        const advQuery = await queryConstructor(advSearchObj);
        let decodedQuery = decodeURIComponent(advQuery);
        dispatch(updateAdvSearchQuery(decodedQuery));
      }

      setIsSearching(false);
      const titleKeywordsAbs = queryParams?.titleKeywordsAbs;
      let x = dirtyString.replace(
        /author_address_fz:|title_fz:|keywords_fz:|author_all_fz:|issn:|articledoi:|resourcemaster_id:|yearfrom:/gi,
        ""
      );
      if (searchType === "Author Search" && fullTextData?.docs) {
        dispatch(updateAuthorSearchResults(fullTextData));
        navigate("/authorfindersearch?searchterm=" + searchTerm);
        dispatch(updateAuthorSort("dateofpublication desc"));
        return;
      }

      if (fullTextData?.docs) {
        navigate("/basicSearchScreen?searchterm=" + searchTerm, {
          //navigate("/basicSearchScreen?searchterm=" + titleKeywordsAbs, {
          state: {
            searchTerm: x,
            fullTextQuery: fullTextQuery,
            allQuery: searchURL,
            // allCount: allText?.hits,
            // fullCount: fulltext?.hits,
            allCount: 0,
            fullCount: 0,
          },
        });
      }

      const subjectFacetData = await subjectJsonFacetQuery(actualFullTextQuery);
      const authorFacetData = await authorJsonFacetQuery(actualFullTextQuery);
      const otherFacetData = await jsonFacetQuery(actualFullTextQuery);

      //JsonFacets
      dispatch(updateSubjectFacets(subjectFacetData?.jsonfacets));
      dispatch(updateAuthorFacets(authorFacetData?.jsonfacets));
      dispatch(updateAllFacets(otherFacetData?.jsonfacets));
    } catch (error) {
      setIsSearching(false);
    }
  };

  async function handleSetSearchClick(searchUrl: string) {
    const queryParams = getQueryParams(searchUrl);
    console.log("queryParams", queryParams);
    Object.assign(queryParams, { sort: "dateofpublication desc" });
    const isOnlyConsortiaAccess = customerData?.product_type === "7";
    if (isOnlyConsortiaAccess) {
      Object.assign(queryParams, {
        fq__acl_group: customerData.consortia_filter,
      });
    }
    const query = await queryConstructor(queryParams);
    const response = await setSearch(query);
    console.log("response", response);

    dispatch(updateSearchResults(response));
    queryParams?.set_number_in &&
      dispatch(updateSetInNumber(queryParams?.set_number_in));

    dispatch(
      updateAllFilter({
        key: "search-term",
        value: queryParams?.set_number_in,
      })
    );

    dispatch(
      updateQueries({
        key: "searchTerm",
        value: queryParams?.set_number_in,
      })
    );

    dispatch(updateSetSearchQuery(query));
    dispatch(updateSetSearchStatus(true));
    const allText = await setSearch(query, "all", "count");
    const fullText = await setSearch(query, "fulltext", "count");

    if (response?.docs) {
      navigate("/basicSearchScreen?searchterm=" + queryParams?.set_number_in, {
        state: {
          allCount: allText?.hits,
          fullCount: fullText?.hits,
        },
      });
    }
  }

  const updateQueryParamsToRedux = (
    queryParams: any,
    dirtyString: any,
    isAdvTerm?: boolean
  ) => {
    // Updating search term
    const titleKeywordsAbs = queryParams?.titleKeywordsAbs;

    titleKeywordsAbs && dispatch(updateSearchTerm(titleKeywordsAbs));
    if (
      queryParams?.author_address_fz ||
      queryParams?.title_fz ||
      queryParams?.keywords_fz ||
      queryParams?.author_all_fz ||
      queryParams?.issn ||
      queryParams?.articledoi ||
      queryParams?.resourcemaster_id ||
      queryParams?.yearfrom ||
      queryParams?.resource_name_tk ||
      isAdvTerm
    ) {
      let x = dirtyString.replace(
        /author_address_fz:|title_fz:|keywords_fz:|author_all_fz:|issn:|articledoi:|resourcemaster_id:|yearfrom:/gi,
        ""
      );
      dispatch(
        updateAllFilter({
          key: "search-term",
          value: x,
        })
      );
    } else {
      dispatch(
        updateAllFilter({
          key: "search-term",
          value: titleKeywordsAbs,
        })
      );
    }
    dispatch(
      updateQueries({
        key: "searchTerm",
        value: titleKeywordsAbs,
      })
    );

    if (queryParams?.fq__fulltext) {
      dispatch(updateOpenAccess(true));
    }

    // Updating publication date filter
    if (queryParams?.fq__early_online) {
      dispatch(updateEarlyOnline(true));
      dispatch(
        updateQueries({
          key: "earlyOnline",
          value: true,
        })
      );
    }

    if (queryParams?.fq__yearfrom) {
      dispatch(updateYearFrom(queryParams.fq__yearfrom));
      dispatch(
        updateQueries({
          key: "currentYear",
          value: queryParams.fq__yearfrom,
        })
      );
    }

    // Updating Collection filter
    if (queryParams?.fq__acl_group) {
      if (queryParams.fq__acl_group === customerData?.my_library_filter) {
        dispatch(updateMyLibraryCollection(true));
        dispatch(
          updateQueries({
            key: "myLibraryCollection",
            value: true,
          })
        );
      } else if (queryParams.fq__acl_group === customerData?.consortia_filter) {
        dispatch(updateConsortiaCollection(true));
        dispatch(
          updateQueries({
            key: "consortiaSubscriptions",
            value: true,
          })
        );
      }
    }
    if (queryParams?.fq__profileuser_id) {
      dispatch(updatePersonalLibrary(true));
      dispatch(
        updateQueries({
          key: "myPersonalLibraryCollection",
          value: true,
        })
      );
    }

    // Updating resource type
    if (queryParams?.fq__resource_type) {
      let arr = queryParams?.fq__resource_type
        .replace(/[^a-zA-Z0-9\s]/g, "")
        .split(" OR ")
        ?.map((str: string) => Number(str));

      dispatch(updateDataType(arr));
    }

    // Updating Filters
    if (queryParams?.fq__data_type) {
      // Document type filter
      const dataType = queryParams?.fq__data_type
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateSourceType(dataType));
      dispatch(
        updateQueries({
          key: "data_type",
          value: queryParams?.fq__data_type,
        })
      );
      dispatch(
        updateAllFilter({
          key: "data_type",
          value: queryParams?.fq__data_type,
        })
      );
    }

    if (queryParams?.fq__subjects_name_l3) {
      let subjectQuery = queryParams?.fq__subjects_name_l3
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });

      // Update subject filter
      dispatch(updateSubject(subjectQuery));
      dispatch(
        updateQueries({
          key: "subject",
          value: queryParams?.fq__subjects_name_l3,
        })
      );
      dispatch(
        updateAllFilter({
          key: "subject",
          value: queryParams?.fq__subjects_name_l3,
        })
      );
    }

    if (queryParams?.fq__authors_tk) {
      const authorParam = queryParams?.fq__authors_tk
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateAuthor(authorParam));
      dispatch(
        updateQueries({
          key: "author",
          value: queryParams?.fq__authors_tk,
        })
      );
      dispatch(
        updateAllFilter({
          key: "author",
          value: queryParams?.fq__authors_tk,
        })
      );
    }

    if (queryParams?.fq__speakers) {
      const speakerParam = queryParams?.fq__speakers
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateSpeaker(speakerParam));
      dispatch(
        updateQueries({
          key: "speaker",
          value: queryParams?.fq__speakers,
        })
      );
      dispatch(
        updateAllFilter({
          key: "speaker",
          value: queryParams?.fq__speakers,
        })
      );
    }

    if (queryParams?.fq__guide_name_tk) {
      const guideParam = queryParams?.fq__guide_name_tk
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateResearch(guideParam));
      dispatch(
        updateQueries({
          key: "research",
          value: queryParams?.fq__guide_name_tk,
        })
      );
      dispatch(
        updateAllFilter({
          key: "research",
          value: queryParams?.fq__guide_name_tk,
        })
      );
    }

    if (queryParams?.fq__researcher_tk) {
      const researcherParam = queryParams?.fq__researcher_tk
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateResearcher(researcherParam));
      dispatch(
        updateQueries({
          key: "researcher",
          value: queryParams?.fq__researcher_tk,
        })
      );
      dispatch(
        updateAllFilter({
          key: "researcher",
          value: queryParams?.fq__researcher_tk,
        })
      );
    }

    if (queryParams?.fq__journal_name) {
      const journalParam = queryParams?.fq__journal_name
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateJournal(journalParam));
      dispatch(
        updateQueries({
          key: "journal",
          value: queryParams?.fq__journal_name,
        })
      );
      dispatch(
        updateAllFilter({
          key: "journal",
          value: queryParams?.fq__journal_name,
        })
      );
    }

    if (queryParams?.fq__primary_publisher_country) {
      const primaryPublisherParam = queryParams?.fq__primary_publisher_country
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateCountryOfPublication(primaryPublisherParam));
      dispatch(
        updateQueries({
          key: "countryOfPublication",
          value: queryParams?.fq__primary_publisher_country,
        })
      );
      dispatch(
        updateAllFilter({
          key: "countryOfPublication",
          value: queryParams?.fq__primary_publisher_country,
        })
      );
    }

    if (queryParams?.fq__publisher_name) {
      const publisherNameParam = queryParams?.fq__publisher_name
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updatePublisher(publisherNameParam));
      dispatch(
        updateQueries({
          key: "publisher",
          value: queryParams?.fq__publisher_name,
        })
      );
      dispatch(
        updateAllFilter({
          key: "publisher",
          value: queryParams?.fq__publisher_name,
        })
      );
    }

    if (queryParams?.fq__author_address) {
      const authorAddressParam = queryParams?.fq__author_address
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateInstitution(authorAddressParam));
      dispatch(
        updateQueries({
          key: "institutions",
          value: queryParams?.fq__author_address,
        })
      );
      dispatch(
        updateAllFilter({
          key: "institutions",
          value: queryParams?.fq__author_address,
        })
      );
    }

    if (queryParams?.fq__filter_metrix) {
      const journalRankParam = queryParams?.fq__filter_metrix
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateJournalRank(journalRankParam));
      dispatch(
        updateQueries({
          key: "journalRank",
          value: queryParams?.fq__filter_metrix,
        })
      );
      dispatch(
        updateAllFilter({
          key: "journalRank",
          value: queryParams?.fq__filter_metrix,
        })
      );
    }

    if (queryParams?.fq__author_country_name) {
      const countryOfPublishingAuthorParam =
        queryParams?.fq__author_country_name.split("OR").map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateCountryOfPublishingAuthor(countryOfPublishingAuthorParam));
      dispatch(
        updateQueries({
          key: "countryOfPublishingAuthor",
          value: queryParams?.fq__author_country_name,
        })
      );
      dispatch(
        updateAllFilter({
          key: "countryOfPublishingAuthor",
          value: queryParams?.fq__author_country_name,
        })
      );
    }
  };

  const windowWidth = window.innerWidth;

  return (
    <TableContainer component={Paper}>
      <Table sx={{ width: "100%" }} aria-label="simple table">
        <TableHead>
          <TableRow sx={{ backgroundColor: Colors.coolGray100 }}>
            <TableCell style={{ width: windowWidth / 4 }}>
              Alert Topic
            </TableCell>
            <TableCell style={{ width: windowWidth / 4 }}>Date</TableCell>
            <TableCell style={{ width: windowWidth / 4 }}>
              Search Type
            </TableCell>
            <TableCell style={{ width: windowWidth / 4 }}></TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {searchAlerts &&
            searchAlerts?.map((row, i) => (
              <TableRow
                key={row.alert_id}
                sx={{
                  "&:last-child td, &:last-child th": { border: 0 },
                }}
              >
                <TableCell component="th" scope="row" style={{ maxWidth: 600 }}>
                  <Text fontSize="0.875rem">{row.alert_topic}</Text>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Text fontSize="0.875rem">{row.created_on}</Text>
                </TableCell>
                <TableCell component="th" scope="row">
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box sx={styles.searchType}>
                      <Text fontSize="0.875rem">
                        {row.search_type === "Advanced Search"
                          ? "Search Builder"
                          : row.search_type}
                      </Text>
                      {/* <CustomTooltip
                        title={row?.mouse_over_data}
                        arrow
                        placement="top"
                      >
                        <IconButton size="small">
                          <InfoIcon fontSize="inherit" />
                        </IconButton>
                      </CustomTooltip> */}
                    </Box>
                  </Box>
                </TableCell>
                <TableCell>
                  <Box sx={styles.searchTypeOptions}>
                    {isSearching && rowNumber === i ? (
                      <CircularProgress color="primary" size={28} />
                    ) : (
                      <Text
                        sx={styles.viewResult}
                        onClick={() => {
                          handleKeywordClick(
                            row.searchterms,
                            row.search_url,
                            row.search_type,
                            row.searchtype_id
                          );
                          setRowNumber(i);
                        }}
                      >
                        View Results{" "}
                        <span style={{ color: "#000", opacity: 0.8 }}>
                          ({row.result_count?.toLocaleString("en-US")})
                        </span>
                      </Text>
                    )}
                    <Tooltip
                      title={row.alert_type > 0 ? "Modify Alert" : "Set Alert"}
                    >
                      <IconButton
                        aria-label="rss"
                        sx={{
                          "&:hover": {
                            color: "#F48120",
                          },
                          color:
                            row?.alert_type === 1 ||
                            row?.alert_type === 2 ||
                            row?.alert_type === 3
                              ? "#F48120"
                              : "",
                          width: "80px",
                        }}
                        onClick={() => openRssMenu(row)}
                      >
                        <RSSIcon />
                      </IconButton>
                    </Tooltip>
                    {/* <IconButton
                      onClick={(e) => openMenu(e, row)}
                      sx={{ width: "80px" }}
                    >
                      <MoreIcon />
                    </IconButton> */}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
      <SearchAlertMenu
        open={open}
        onClose={closeMenu}
        anchor={anchorEl}
        currentAlert={selectedRow}
        refetch={refetch}
      />
      <RSSAlertModal
        show={openRss}
        onClose={closeRssModal}
        alertData={rssData}
        refetch={refetch}
        setOpenRss={setOpenRss}
      />
    </TableContainer>
  );
};

export default SearchAlerts;
