import { store } from "../../store";
import api from "../endpoints";
import http from "../httpService";
import { staticData, fq__resource_type, getJsonFacets } from "./BasicSearch.api";

// Koha api - Library OPAC collection filter
export async function getOpacData(params?: string) {
  try {
    let url = `${api.baseApi}/api/koha_catalog_api/koha_catalog_api?per_page=20&${params}`;
    const res = await http.get(url);
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}

export async function getPersonalLibraryCollection(query: any) {
  const data = store.getState().searchReducer.dataType;
   const facetData = getJsonFacets(data);
  try {
    let userId = sessionStorage.getItem("user_id");
    let url =
      `${api.baseApi}/api/indexing/solr/personalcore` +
      "?json_facet=" +
       JSON.stringify(staticData) +
      // JSON.stringify(facetData) +
      "&" +
      query;
    const urlWithProfile = url + `&fq__profileuser_id=${userId}`;
    const { data: res } = await http.get(urlWithProfile);
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}

export async function personalCoreCount(query: string) {
  try {
    let userId = sessionStorage.getItem("user_id");
    let url = `${api.baseApi}/api/indexing/solr/personalcore?page=1&rows=15&${query}`;
    const urlWithProfile = url + `&fq__profileuser_id=${userId}`;
    const { data: res } = await http.get(urlWithProfile);
    return res?.data;
  } catch (err) {
    console.log(err);
  }
}

