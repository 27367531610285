import axios from "axios";
import api from "./endpoints";
const authorFinderURL = api.baseApi + `/api/indexingGen/solrGeneral/authorcore`;

export async function authorFinderApi(query: any) {
  try {
    //console.log("Query Component Data", decodeURIComponent(`${query}`));
    const urlDecode = decodeURIComponent(`${query}`);

    // {api}/api/indexingGen/solrGeneral/authorcore?page=1&group_ngroups=true&group_field=author_name_lf&author_name_lf="Rao, Kollu N."

    let //   page = 1,
      group_ngroups = "true",
      json_facet = {
        // datsubjectmasterlevel2_id: {
        //   type: "terms",
        //   field: "datsubjectmasterlevel2_id",
        //   limit: 100,
        // },
        author_affiliation: {
          type: "terms",
          field: "author_affiliation",
          limit: 100,
        },
        author_name_initial: {
          type: "terms",
          field: "author_name_initial",
          limit: 100,
        },
        subjects_name_l3: {
          type: "terms",
          field: "subjects_name_l3",
          limit: 100,
        },
      },
      group_field = "author_name_lf";

    let apiURL =
      authorFinderURL +
      `?group_ngroups=${group_ngroups}&group_field=${group_field}&json_facet=${JSON.stringify(
        json_facet
      )}&${urlDecode}`;

    //console.log("API URL", apiURL);

    // Get search result
    let response = await axios.get(apiURL);
    if (response.status === 200 && response.data.data) {
      //console.log("Author List Response Data", response.data.data);
      return response.data.data;
    }
  } catch (err) {
    return null;
  }
}

export async function authorFindernGroupQuery(query: any) {
  try {
    const urlDecode = decodeURIComponent(`${query}`);
    let //   page = 1,
      group_ngroups = "true",
      group_field = "author_name_lf";

    let apiURL =
      authorFinderURL +
      `?group_ngroups=${group_ngroups}&group_field=${group_field}&${urlDecode}`;

    // Get search result
    let response = await axios.get(apiURL);
    if (response.status === 200 && response.data.data) {
      //console.log("Author List Response Data", response.data.data);
      return response.data.data;
    }
  } catch (err) {
    return null;
  }
}

export async function authorFinderFacetsQuery(query: any) {
  try {
    const urlDecode = decodeURIComponent(`${query}`);
    let //   page = 1,
      json_facet = {
        author_affiliation: {
          type: "terms",
          field: "author_affiliation",
          limit: 100,
        },
        author_name_initial: {
          type: "terms",
          field: "author_name_initial",
          limit: 100,
        },
        subjects_name_l3: {
          type: "terms",
          field: "subjects_name_l3",
          limit: 100,
        },
      };
      
      // group_field = "author_name_lf";

      let apiURL =
      authorFinderURL +
      `?json_facet=${JSON.stringify(
        json_facet
      )}&${urlDecode}`;
      

    // let apiURL =
    //   authorFinderURL +
    //   `?group_field=${group_field}&json_facet=${JSON.stringify(
    //     json_facet
    //   )}&${urlDecode}`;

    // Get search result
    let response = await axios.get(apiURL);
    if (response.status === 200 && response.data.data) {
      //console.log("Author List Response Data", response.data.data);
      return response.data.data;
    }
  } catch (err) {
    return null;
  }
}

export async function authorSearchForCounts(query: any) {
  // const fq__resource_type = "( 1 OR 2 OR 3 OR 4 OR 8 OR 24 )";;
  // const authors = JSON.stringify(authorData);
  // console.log('JSON.stringify(authorData)', authors)

  const json_facet_data = {
    Collections_OpenAccess: {
      type: "query",
      q: "(fulltext_ACL:0 OR ishybridopenaccess:true)",
    },
    data_type: {
      type: "terms",
      field: "data_type",
      limit: 30,
    },
    subjects_name_l3: {
      type: "terms",
      field: "subjects_name_l3",
      limit: 100,
    },
    yearfrom: {
      type: "terms",
      field: "yearfrom",
      limit: 100,
      sort: "index desc",
    },
    publisher_name: {
      type: "terms",
      field: "publisher_name",
      limit: 100,
    },
    authors_tk: {
      type: "terms",
      field: "authors_tk",
      limit: 100,
    },
    primary_publisher_country: {
      type: "terms",
      field: "primary_publisher_country",
      limit: 100,
    },
    filter_metrix: {
      type: "terms",
      field: "filter_metrix",
      limit: 100,
    },
    journal_name: {
      type: "terms",
      field: "journal_name",
      limit: 100,
    },
    guide_name_tk: {
      type: "terms",
      field: "guide_name_tk",
      limit: 100,
    },
    speakers: {
      type: "terms",
      field: "speakers",
      limit: 100,
    },
    openaccess_type: {
      type: "terms",
      field: "openaccess_type",
      limit: 100,
    },
    author_address: {
      type: "terms",
      field: "author_address",
      limit: 100,
    },
    author_country_name: {
      type: "terms",
      field: "author_country_name",
      limit: 100,
    },
  };

  try {
    const myLibraryFilter = sessionStorage.getItem("my_library_filter");
    const consortiaFilter = sessionStorage.getItem("consortia_filter");
    const facetData = { ...json_facet_data };

    if (myLibraryFilter) {
      Object.assign(facetData, {
        Collections_LibrarySubscribed: { type: "query", q: myLibraryFilter },
      });
    }
    if (consortiaFilter) {
      Object.assign(facetData, {
        Collections_ConsotiaSubscribed: { type: "query", q: consortiaFilter },
      });
    }

    const countURL =
      api.baseApi +
      `/api/indexing/solr/articlecore2?rows=20&json_facet=${JSON.stringify(
        facetData
      )}&${query}`;
    //console.log("authorSearchForCounts URL", countURL);

    let res = await axios.get(countURL);

    //console.log("authorSearchForCounts Response", res);
    //console.log("DATTTTTTTTTTTTTTTTTAAAAAAAAAAAAAAAAAAAAAA", res.data.data);
    return res.data.data;
  } catch (error) {
    console.log("Couldnt Fetch Search Count: ", error);
    throw error;
  }
}

export async function getAuthorSuggestionList(facet_prefix: any,encodedSubjects:any,dataTypeValue:any) {
  try {
    let apiUrl =
      api.baseApi +
      `/api/indexingGen/solrGeneral/authorcore?group_ngroups=true&group_field=author_lastname&fq__resourcetype_id=${dataTypeValue}&fq__datsubjectmasterlevel2_id=${encodedSubjects}&page=1&sort=author_lastname asc&fl=author_lastname`;

    let response = await axios.get(apiUrl, {
      params: {
        //  facet_prefix:'joji'
        author_lastname: `${facet_prefix}*`,
      },
    });
    console.log(
      "Response from API getAuthorSuggestionList",
      response.data.data?.grouped?.author_lastname?.groups
    );

    return response;
  } catch (err) {
    return null;
  }
}

export async function getAuthorSuggestionListFirstName(facet_prefix: any,encodedSubjects:any,dataTypeValue:any) {
  try {
    let apiUrl =
      api.baseApi +
      `/api/indexingGen/solrGeneral/authorcore?group_ngroups=true&group_field=author_firstname&fq__resourcetype_id=${dataTypeValue}&fq__datsubjectmasterlevel2_id=${encodedSubjects}&page=1&sort=author_firstname asc&fl=author_firstname`;

    let response = await axios.get(apiUrl, {
      params: {
        //  facet_prefix:'joji'
        author_firstname: `${facet_prefix}*`,
      },
    });
    console.log(
      "Response from API getAuthorSuggestionList",
      response.data.data?.grouped?.author_firstname?.groups
    );

    return response;
  } catch (err) {
    return null;
  }
}
