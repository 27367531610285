import ControlPointIcon from "@mui/icons-material/ControlPoint";
import {
  Button,
  Checkbox,
  Divider,
  FormGroup,
  Typography,
} from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import { Box } from "@mui/system";
import { useEffect, useState } from "react";
import {
  default as FilterCategory,
  default as FilterRanking,
  default as SourceType,
} from "../../components/filterPopups/ModalOne";
import FilterYear from "../../components/filterPopups/ModalThree";
import {
  default as FilterAuthor,
  default as FilterCountry,
  default as FilterJournal,
  default as FilterPublishers,
  default as FilterResearchGuide,
  default as FilterSpeaker,
  default as FilterSubject,
  default as FilterUniversity,
  default as PublishingAuthor,
} from "../../components/filterPopups/ModalTwoOfAuthor";
import { Colors } from "../../utils/constants";
import { getParamsByName, goToTop } from "../../utils/helper";

// Redux
import { useSelector } from "react-redux";
import {
  clearAllFilters,
  removeAppliedFilters,
  updateAllFilter,
  updateAuthor,
  updateCountryOfPublication,
  updateCountryOfPublishingAuthor,
  updateDateset,
  updateInstitution,
  updateJournal,
  updateJournalRank,
  updateOpenAccess,
  updatePublisher,
  updateResearch,
  updateRetractedArticle,
  updateSourceIndex,
  updateSourceType,
  updateSpeaker,
  updateSubject,
} from "../../store/slice/filterSlice";
import { AuthorFinderObj, ReducerObj } from "../../types/interface";

import { onAuthorResult as search } from "../../screens/AuthorFinder/onAuthorResult";

// import authorsearch from "../../screens/AuthorFinder/onAuthorResult"
import { usagelogApi } from "../../api/usageReportApi";
import NewIcon from "../../assets/img/New.png";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { updateTempSearchTerm } from "../../store/slice/authorFinderSlice";

// Types

type YearType = { val: string; count: string };

interface PropType {
  yearFromData?: YearType[];
  jsonFacets?: any;
}

const AuthorSearchFilter = (props: PropType) => {
  // Props
  const { yearFromData, jsonFacets } = props;
  const dispatch = useAppDispatch();
  const searchTerm = getParamsByName("searchterm");
  const selectedFilters: any = useSelector(
    (state: ReducerObj) => state.filterReducer
  );
  const searchReducer: any = useSelector(
    (state: ReducerObj) => state.searchReducer
  );

  const authorReducer: any = useSelector(
    (state: AuthorFinderObj) => state.authorReducer
  );

  const [sourceTypeModalStatus, setSourceTypeModalStatus] = useState(false);
  const [filterSubjectModalStatus, setFilterSubjectModalStatus] =
    useState(false);
  const [filterYearModalStatus, setFilterYearModalStatus] = useState(false);
  const [filterCategoryModalStatus, setFilterCategoryModalStatus] =
    useState(false);
  const [filterAuthorModalStatus, setFilterAuthorModalStatus] = useState(false);
  const [filterSpeakerModalStatus, setFilterSpeakerModalStatus] =
    useState(false);
  const [filterResearchModalStatus, setFilterResearchModalStatus] =
    useState(false);
  const [filterJournalModalStatus, setFilterJournalModalStatus] =
    useState(false);
  const [filterRankingModalStatus, setFilterRankingModalStatus] =
    useState(false);
  const [filterSourceModalStatus, setFilterSourceModalStatus] = useState(false);
  const [filterCountryModalStatus, setFilterCountryModalStatus] =
    useState(false);
  const [filterPublisherModalStatus, setFilterPublisherModalStatus] =
    useState(false);
  const [filterUnivModalStatus, setFilterUnivModalStatus] = useState(false);
  const [triggerCount, setTriggerCount] = useState<number>(0);
  const [copaModalStatus, setCopaModalStatus] = useState(false);

  // Applied filter values
  const sourceIndex = useSelector(
    (state: ReducerObj) => state.filterReducer.sourceIndex
  );
  const openAccess = useAppSelector((state) => state.filterReducer.openAccess);

  const retractedArticle = useAppSelector(
    (state) => state.filterReducer.retractedArticle
  );

  const datasetAvailability = useAppSelector(
    (state) => state.filterReducer.datesetAvailability
  );

  let onlyDataset = true;
  searchReducer?.searchResult.docs?.map((item: any) => {
    if (item?.resource_type !== 6) {
      onlyDataset = false;
    }
  });

  //jsonFacets
  const otherFacetData: any = useAppSelector(
    (state) => state.searchReducer?.allFacets
  );

  const authorFacetData: any = useAppSelector(
    (state) => state.searchReducer?.authorFacets
  );

  const subjectFacetData: any = useAppSelector(
    (state) => state.searchReducer?.subjectFacets
  );
  useEffect(() => {
    if (triggerCount) applySearch();
  }, [triggerCount]);

  const profileData = useSelector((state: any) => state.login);

  const profileData1 = useSelector(
    (state: any) => state.customer.customerDetails
  );

  const contextId =
    selectedFilters.collectionValue === "J-GateCollection"
      ? 1
      : selectedFilters.collectionValue === "ConsortiaSubscriptions"
      ? 2
      : selectedFilters.collectionValue === "MyLibraryCollection"
      ? 3
      : selectedFilters.collectionValue === "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionValue === "ONOSCollections"
      ? 5
      : selectedFilters.collectionValue === "Library OPAC"
      ? 6
      : selectedFilters.collectionValue === "Open Access Collections"
      ? 7
      : null;

  const logBasicSearchUsageData = (usageActionID: any) => {
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;
    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  // Handle source type modal status changes
  const handleChangeSouceType = () => {
    setSourceTypeModalStatus(false);
  };

  // Handle Filter subject modal status changes
  const handleChangeFilterSubject = () => {
    setFilterSubjectModalStatus(false);
  };

  // Handle Filter year modal status changes
  const handleChangeFilterYear = () => {
    setFilterYearModalStatus(false);
  };

  // Handle Filter category modal status changes
  const handleChangeFilterCategory = () => {
    setFilterCategoryModalStatus(false);
  };

  // Handle Filter Author modal status changes
  const handleChangeFilterAuthor = () => {
    setFilterAuthorModalStatus(false);
  };

  // Handle Filter speaker modal status changes
  const handleChangeFilterSpeaker = () => {
    setFilterSpeakerModalStatus(false);
  };

  // Handle Filter Research modal status changes
  const handleChangeFilterResearch = () => {
    setFilterResearchModalStatus(false);
  };

  // Handle Filter journal modal status changes
  const handleChangeFilterJournal = () => {
    setFilterJournalModalStatus(false);
  };

  // Handle Filter ranking modal status changes
  const handleChangeFilterRanking = () => {
    setFilterRankingModalStatus(false);
  };

  // Handle Filter source modal status changes
  const handleChangeFilterSource = () => {
    setFilterSourceModalStatus(false);
  };

  // Handle Filter country modal status changes
  const handleChangeFilterCountry = () => {
    setFilterCountryModalStatus(false);
  };

  // Handle Filter publisher modal status changes
  const handleChangeFilterPublisher = () => {
    setFilterPublisherModalStatus(false);
  };

  // Handle Filter univ modal status changes
  const handleChangeFilterUniv = () => {
    setFilterUnivModalStatus(false);
  };

  // Get filterd values
  const getSouceTypeValues = (data: string[]) => {
    dispatch(updateSourceType(data));
    let appliedFilter = data?.map((val) => {
      return {
        key: "data_type",
        value: val,
      };
    });
    dispatch(updateAllFilter(appliedFilter));
  };

  // Get category values
  const getCategoryTypeValues = (data: string[]) => {
    console.log(data);
  };

  // Get subject values
  const getSubjectTypeValues = (data: string[]) => {
    dispatch(updateSubject(data));
    let appliedFilter = data?.map((val) => {
      return {
        key: "subject",
        value: val,
      };
    });

    dispatch(updateAllFilter(appliedFilter));
    logBasicSearchUsageData(56);
  };

  // Get Author values
  const getAuthorTypeValues = (data: string[]) => {
    dispatch(updateAuthor(data));
    let appliedFilter = data?.map((val) => {
      return {
        key: "author",
        value: val,
      };
    });

    dispatch(updateAllFilter(appliedFilter));

    dispatch(updateTempSearchTerm(authorReducer.searchTerm));
    logBasicSearchUsageData(53);
  };
  // Get speaker values
  const getSpeakerTypeValues = (data: string[]) => {
    dispatch(updateSpeaker(data));

    let appliedFilter = data?.map((val) => {
      return {
        key: "speaker",
        value: val,
      };
    });

    dispatch(updateAllFilter(appliedFilter));
    logBasicSearchUsageData(54);
  };

  // Get research values
  const getResearchTypeValues = (data: string[]) => {
    dispatch(updateResearch(data));
    logBasicSearchUsageData(55);
  };

  // Get journal values
  const getJournalTypeValues = (data: string[]) => {
    dispatch(updateJournal(data));

    let appliedFilter = data?.map((val) => {
      return {
        key: "journal",
        value: val,
      };
    });

    dispatch(updateAllFilter(appliedFilter));
    logBasicSearchUsageData(59);
  };

  // Get ranking values
  const getRankingTypeValues = (data: string[]) => {
    dispatch(updateJournalRank(data));

    let appliedFilter = data?.map((val) => {
      return {
        key: "journalRank",
        value: val,
      };
    });

    dispatch(updateAllFilter(appliedFilter));
    logBasicSearchUsageData(52);
  };

  // Get cop values
  const getCopTypeValues = (data: string[]) => {
    dispatch(updateCountryOfPublication(data));

    let appliedFilter = data?.map((val) => {
      return {
        key: "countryOfPublication",
        value: val,
      };
    });

    dispatch(updateAllFilter(appliedFilter));
    logBasicSearchUsageData(58);
  };

  // Get cop values
  const getCopaTypeValues = (data: string[]) => {
    dispatch(updateCountryOfPublishingAuthor(data));

    let appliedFilter = data?.map((val) => {
      return {
        key: "countryOfPublishingAuthor",
        value: val,
      };
    });

    dispatch(updateAllFilter(appliedFilter));
    logBasicSearchUsageData(60);
  };

  // Get publisher values
  const getPublisherTypeValues = (data: string[]) => {
    dispatch(updatePublisher(data));

    let appliedFilter = data?.map((val) => {
      return {
        key: "publisher",
        value: val,
      };
    });

    dispatch(updateAllFilter(appliedFilter));
    logBasicSearchUsageData(51);
  };

  // Get univ values
  const getUnivTypeValues = (data: string[]) => {
    dispatch(updateInstitution(data));

    let appliedFilter = data?.map((val) => {
      return {
        key: "institutions",
        value: val,
      };
    });

    dispatch(updateAllFilter(appliedFilter));
    logBasicSearchUsageData(57);
  };

  // Get year values
  const getYearTypeValues = (data: string[]) => {
    console.log(data);
  };

  // function apply filter
  async function applyFilter() {
    if (!searchTerm) return;
    setTriggerCount((prev) => prev + 1);

    goToTop();
  }

  async function applySearch() {
    dispatch(search("", null, true));
  }

  // clear all filters
  function handleClearAllFilters() {
    dispatch(clearAllFilters());
    dispatch(removeAppliedFilters());
    setTriggerCount((prev) => prev + 1);
  }

  // Handle independent filter source index
  function handleSourceIndex() {
    dispatch(updateSourceIndex(!sourceIndex));
    setTriggerCount((prev) => prev + 1);
    logBasicSearchUsageData(65);
  }

  function handleOpenAccess() {
    dispatch(updateOpenAccess(!openAccess));
    setTriggerCount((prev) => prev + 1);
    logBasicSearchUsageData(62);
  }

  function handleRetractedArticles() {
    dispatch(updateRetractedArticle(!retractedArticle));
    setTriggerCount((prev) => prev + 1);
    logBasicSearchUsageData(63);
  }

  function handleDateSet() {
    dispatch(updateDateset(!datasetAvailability));
    setTriggerCount((prev) => prev + 1);
    logBasicSearchUsageData(64);
  }

  /****Exclude author search term from list start */
  const [searchtermval, setSearchtermval] = useState<any>([]);
  const [bucketArray, setBucketArray] = useState<any>([]);

  const [coauthorArray, setCoauthorArray] = useState<any>([]);
  useEffect(() => {
    let tempJsonfacet: any[] = jsonFacets?.authors_tk?.buckets;

    let tempSearchterm: any[] = authorReducer.searchTerm;

    const authorList: any = [];
    const lowercaseWords = tempSearchterm?.map((word: any) =>
      word.toLowerCase()
    );

    lowercaseWords?.map((element: any, index: number) => {
      let key = index + 1;
      authorList[index] = {
        val: element,
        count: key,
      };
    });

    setSearchtermval(lowercaseWords);
    setBucketArray(authorList);

    var deSelectedRows: any[] = tempJsonfacet;

    var selectedRows: any[] = authorList;

    selectedRows = deSelectedRows?.filter(function (cv: any) {
      return !selectedRows?.find(function (e: any) {
        return e.val == cv.val;
      });
    });
    setCoauthorArray(selectedRows);
  }, []);

  const shouldDisplayRetracted =
    otherFacetData?.is_retracted?.buckets?.filter(
      (obj: any) => obj.val === true
    ).length > 0;

  return (
    <>
      <Box sx={webStyle.leftBox}>
        <Box sx={[webStyle.flexBox]}>
          <Typography
            sx={{
              alignSelf: "center",
              fontFamily: "Lora",
              fontWeight: 700,
              fontSize: "1.125rem",
            }}
          >
            Filters
          </Typography>
          <Button onClick={handleClearAllFilters}>Clear All</Button>
        </Box>

        {Array.isArray(otherFacetData?.data_type?.buckets) &&
        otherFacetData?.data_type?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Document Type
              </Typography>
              <Button onClick={() => setSourceTypeModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(subjectFacetData?.subjects_name_l3?.buckets) &&
        subjectFacetData?.subjects_name_l3?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Subject
              </Typography>
              <Button onClick={() => setFilterSubjectModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(authorFacetData?.authors_tk?.buckets) &&
        authorFacetData?.authors_tk?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Co-Authors
              </Typography>
              <Button onClick={() => setFilterAuthorModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(authorFacetData?.speakers?.buckets) &&
        authorFacetData?.speakers?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Speaker
              </Typography>
              <Button onClick={() => setFilterSpeakerModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(authorFacetData?.guide_name_tk?.buckets) &&
        authorFacetData?.guide_name_tk?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                {/* Guides (Ph.D Theses) */}
                Research Guides
              </Typography>
              <Button onClick={() => setFilterResearchModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(otherFacetData?.journal_name?.buckets) &&
        otherFacetData?.journal_name?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Journal
              </Typography>
              <Button onClick={() => setFilterJournalModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(otherFacetData?.filter_metrix?.buckets) &&
        otherFacetData?.filter_metrix?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Journal Ranking
              </Typography>
              <Button onClick={() => setFilterRankingModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(otherFacetData?.primary_publisher_country?.buckets) &&
        otherFacetData?.primary_publisher_country?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Publication Country
              </Typography>
              <Button onClick={() => setFilterCountryModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(authorFacetData?.author_country_name?.buckets) &&
        authorFacetData?.author_country_name?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                {/* Country of Publishing Author */}
                Author Country
              </Typography>
              <Button onClick={() => setCopaModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : (
          ""
        )}

        {Array.isArray(otherFacetData?.publisher_name?.buckets) &&
        otherFacetData?.publisher_name?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Publishers
              </Typography>
              <Button onClick={() => setFilterPublisherModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        {Array.isArray(authorFacetData?.author_address?.buckets) &&
        authorFacetData?.author_address?.buckets.length ? (
          <>
            <Box sx={[webStyle.flexBox]} mt={"2%"} mb={"2%"}>
              <Typography
                sx={{ fontSize: "0.875rem", alignSelf: "center" }}
                fontFamily={"Lora"}
                fontWeight={"600"}
              >
                Institution
              </Typography>
              <Button onClick={() => setFilterUnivModalStatus(true)}>
                <ControlPointIcon></ControlPointIcon>
              </Button>
            </Box>
            <Divider />
          </>
        ) : null}

        <Box>
          <Typography
            mt={2}
            mb={2}
            sx={{
              fontSize: "0.875rem",
              alignSelf: "center",
              fontFamily: "Lora",
              fontWeight: "600",
            }}
          >
            Independent Filters
          </Typography>
          <Divider />

          <FormGroup>
            <FormControlLabel
              control={<Checkbox checked={openAccess} />}
              label="Open Access"
              onChange={handleOpenAccess}
              componentsProps={{
                typography: {
                  fontFamily: "Lora",
                  fontWeight: "600",
                  fontSize: "14px",
                },
              }}
            />
            {Array.isArray(otherFacetData?.is_retracted?.buckets) &&
              shouldDisplayRetracted && (
                <FormControlLabel
                  control={<Checkbox />}
                  onChange={handleRetractedArticles}
                  sx={{ fontFamily: "Lora", fontWeight: "600" }}
                  componentsProps={{
                    typography: {
                      fontFamily: "Lora",
                      fontWeight: "600",
                      fontSize: "14px",
                    },
                  }}
                  label={
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      Retracted Articles
                      <img src={NewIcon} style={{ width: "50px" }} />{" "}
                    </Box>
                  }
                />
              )}
            {Array.isArray(otherFacetData?.has_datasets?.buckets) &&
              otherFacetData?.has_datasets?.buckets?.length > 0 && (
                <FormControlLabel
                  control={<Checkbox />}
                  onChange={handleDateSet}
                  sx={{ fontFamily: "Lora", fontWeight: "600" }}
                  componentsProps={{
                    typography: {
                      fontFamily: "Lora",
                      fontWeight: "600",
                      fontSize: "14px",
                    },
                  }}
                  label={
                    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
                      Dataset Availability
                      <img src={NewIcon} style={{ width: "50px" }} />{" "}
                    </Box>
                  }
                />
              )}
          </FormGroup>
        </Box>
        <Divider sx={{ marginTop: "10px" }} />

        {Array.isArray(otherFacetData?.pubmed_article?.buckets) &&
          otherFacetData?.pubmed_article?.buckets.length > 0 && (
            <Box>
              <Typography
                mt={2}
                mb={2}
                sx={{
                  fontSize: "0.875rem",
                  alignSelf: "center",
                  fontFamily: "Lora",
                  fontWeight: "600",
                }}
              >
                Source Index
              </Typography>
              <Divider />
              <FormGroup>
                <FormControlLabel
                  onChange={handleSourceIndex}
                  control={<Checkbox checked={sourceIndex} />}
                  label="PubMed Articles"
                  sx={{ fontFamily: "Lora", fontWeight: "600" }}
                  componentsProps={{
                    typography: {
                      fontFamily: "Lora",
                      fontWeight: "600",
                      fontSize: "14px",
                    },
                  }}
                />
              </FormGroup>
            </Box>
          )}
      </Box>

      {/* Filter popups */}
      <SourceType
        isOpen={sourceTypeModalStatus}
        handleClose={handleChangeSouceType}
        modalName="Filter Document Type"
        getFilterdValues={getSouceTypeValues}
        filterValues={otherFacetData?.data_type?.buckets}
        applyFilter={applyFilter}
        appliedFilterValues={selectedFilters?.sourceType}
      />

      <FilterCategory
        isOpen={filterCategoryModalStatus}
        handleClose={handleChangeFilterCategory}
        modalName="Filter Category"
        getFilterdValues={getCategoryTypeValues}
        filterValues={otherFacetData?.openaccess_type?.buckets}
      />
      <FilterSubject
        isOpen={filterSubjectModalStatus}
        handleClose={handleChangeFilterSubject}
        modalName="Filter Subject"
        getFilterdValues={getSubjectTypeValues}
        filterData={subjectFacetData?.subjects_name_l3?.buckets}
        applyFilter={applyFilter}
        placeholderName="Subject name"
        appliedFilterValues={selectedFilters?.subject}
        facetKey="subjects_name_l3"
      />
      <FilterYear
        isOpen={filterYearModalStatus}
        handleClose={handleChangeFilterYear}
        modalName="Filter Year`"
        getFilterdValues={getYearTypeValues}
        yearData={yearFromData}
      />

      <FilterAuthor
        isOpen={filterAuthorModalStatus}
        handleClose={handleChangeFilterAuthor}
        modalName="Filter Co-Authors"
        getFilterdValues={getAuthorTypeValues}
        filterData={authorFacetData?.authors_tk?.buckets.filter(function (
          cv: any
        ) {
          return !bucketArray?.find(function (e: any) {
            return e.val == cv.val;
          });
        })}
        // filterData={jsonFacets?.authors_tk?.buckets.filter((data: any) => data.val !== bucketArray.val)}
        applyFilter={applyFilter}
        placeholderName="Co-Author Last name only"
        appliedFilterValues={selectedFilters?.author}
        facetKey="authors_tk"
      />

      <FilterSpeaker
        isOpen={filterSpeakerModalStatus}
        handleClose={handleChangeFilterSpeaker}
        modalName="Filter Speaker"
        getFilterdValues={getSpeakerTypeValues}
        filterData={authorFacetData?.speakers?.buckets}
        applyFilter={applyFilter}
        placeholderName="Speaker Last name only"
        appliedFilterValues={selectedFilters?.speaker}
        facetKey="speakers"
      />
      <FilterResearchGuide
        isOpen={filterResearchModalStatus}
        handleClose={handleChangeFilterResearch}
        modalName="Filter Research Guide"
        getFilterdValues={getResearchTypeValues}
        filterData={authorFacetData?.guide_name_tk?.buckets}
        applyFilter={applyFilter}
        placeholderName="Research Guide Last name only"
        appliedFilterValues={selectedFilters?.research}
        facetKey="guide_name_tk"
      />
      <FilterJournal
        isOpen={filterJournalModalStatus}
        handleClose={handleChangeFilterJournal}
        modalName="Filter Journal"
        getFilterdValues={getJournalTypeValues}
        filterData={otherFacetData?.journal_name?.buckets}
        applyFilter={applyFilter}
        placeholderName="Journal name"
        appliedFilterValues={selectedFilters?.journal}
        facetKey="journal_name"
      />
      <FilterRanking
        isOpen={filterRankingModalStatus}
        handleClose={handleChangeFilterRanking}
        modalName="Filter Journal Ranking"
        getFilterdValues={getRankingTypeValues}
        filterValues={otherFacetData?.filter_metrix?.buckets}
        applyFilter={applyFilter}
        appliedFilterValues={selectedFilters?.journalRank}
      />
      <FilterCountry
        isOpen={filterCountryModalStatus}
        handleClose={handleChangeFilterCountry}
        modalName="Filter Publication Country"
        getFilterdValues={getCopTypeValues}
        filterData={otherFacetData?.primary_publisher_country?.buckets}
        applyFilter={applyFilter}
        placeholderName="Publication Country name"
        appliedFilterValues={selectedFilters?.countryOfPublication}
        facetKey="primary_publisher_country"
      />

      <PublishingAuthor
        isOpen={copaModalStatus}
        handleClose={() => setCopaModalStatus(false)}
        modalName="Filter Author Country"
        getFilterdValues={getCopaTypeValues}
        filterData={authorFacetData?.author_country_name?.buckets}
        applyFilter={applyFilter}
        placeholderName="Author Country name"
        appliedFilterValues={selectedFilters?.countryOfPublishingAuthor}
        facetKey="author_country_name"
      />
      <FilterPublishers
        isOpen={filterPublisherModalStatus}
        handleClose={handleChangeFilterPublisher}
        modalName="Filter Publishers"
        getFilterdValues={getPublisherTypeValues}
        filterData={otherFacetData?.publisher_name?.buckets}
        applyFilter={applyFilter}
        placeholderName="Publisher name"
        appliedFilterValues={selectedFilters?.publisher}
        facetKey="publisher_name"
      />
      <FilterUniversity
        isOpen={filterUnivModalStatus}
        handleClose={handleChangeFilterUniv}
        modalName="Filter Institution"
        getFilterdValues={getUnivTypeValues}
        filterData={authorFacetData?.author_address?.buckets}
        applyFilter={applyFilter}
        placeholderName="Institution name"
        appliedFilterValues={selectedFilters?.institutions}
        facetKey="author_address"
      />
    </>
  );
};

export default AuthorSearchFilter;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
  },
  leftBox: {
    width: "92%",
    maxWidth: "340px",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    marginTop: "3%",
    padding: "7%",
    boxShadow: 1,
  },
  titleHeader: {
    fontFamily: "Lora",
    fontWeight: 700,
    textAlign: "left",
    fontSize: "1.125rem",
  },
};
