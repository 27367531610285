import { queryConstructor } from "../formQuery";
import http from "../httpService";

export type SearchContext =
  | "all"
  | "fulltext"
  | "myConsortia"
  | "myLibrary"
  | "myPersonal"
  | "fq__is_onos_resource"
  | null;
type SearchFor = "records" | "count" | "all"| null;
type JsonFacets = "true" | "false" | null;
type LogHistory = boolean;
type JsonFacetType = 1 | 2 | 3 | 4| "";

export async function setSearch(
  query: string,
  searchContext?: SearchContext,
  searchFor?: SearchFor,
  jsonFacets?: JsonFacets,
  logHistory?: LogHistory,
  collectionFor?: "all" | "fulltext",
  jsonFacetType?: JsonFacetType,

) {
  let sessionKey = sessionStorage.getItem("session_key");
  let userId = sessionStorage.getItem("user_id");
  let informaticscustomerId = sessionStorage.getItem("informaticscustomer_id");

  
  // Set search contexts
  let search_context = "all"; // Can be all/fulltext/myConsortia/myLibrary/myPersonal
  let search_for = "records"; // Can be count or records
  let json_facets = true; // Boolean
  let collection_for = collectionFor ?? "all";
  let jsonFacets_type = 1

  // if (searchContext) {
  //   search_context = searchContext;
  // }

  // if (searchFor) {
  //   search_for = searchFor;
  // }

  // if (jsonFacets === "false") {
  //   json_facets = false;
  // }

  // if(jsonFacetType){
  //   jsonFacets_type = jsonFacetType
  // }

  // let apiUrl = "";
  // if(logHistory) {
  //   apiUrl = `api/set_search/set_search?customerid=${informaticscustomerId}&session_id=${sessionKey}&profileuserid=${userId}&${query}&search_context=${search_context}&search_for=${search_for}&jsonFacets=${json_facets}&logsearchhistory=${true}`;
  // } else {
  //   apiUrl = `api/set_search/set_search?customerid=${informaticscustomerId}&session_id=${sessionKey}&profileuserid=${userId}&${query}&search_context=${search_context}&search_for=${search_for}&jsonFacets=${json_facets}&collection_for=${collection_for}&jsonFacets_type=${jsonFacetType}`
  // }

  const queryObj:any ={
    customerid:informaticscustomerId,
    session_id:sessionKey,
    profileuserid:userId
    }

    if (searchContext) {
    Object.assign(queryObj, {search_context : searchContext})
      
    }
    if (searchFor) {
      Object.assign(queryObj, {search_for : searchFor})
    }
  
    if (jsonFacets) {
      Object.assign(queryObj, {jsonFacets : jsonFacets})
    }

    if (logHistory) {
      Object.assign(queryObj, {logsearchhistory : logHistory})
    }
  
    if(jsonFacetType){
      Object.assign(queryObj, {jsonFacets_type : jsonFacetType})
    }
    if(collectionFor){
      Object.assign(queryObj, {collection_for : collectionFor})
    }

  
  const queryParams = new URLSearchParams(queryObj).toString();
  const apiUrl = `api/set_search/set_search?${queryParams}&${query}`;
  try {
    const { data: response } = await http.get(apiUrl);

    return response.data;
  } catch (error) {
    console.log("Couldnt Fetch Search Results: ", error);
    return error;
  }
}
