import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { Divider } from "@mui/material";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import RssFeedIcon from "@mui/icons-material/RssFeed";
import { useDispatch, useSelector } from "react-redux";
import { getAllPackages } from "../../api/browserGetPackage";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import { useNavigate } from "react-router-dom";
import objectToQueryString from "../../screens/browseJournal/objToQuery";
import { browseJournalSearchResult } from "../../store/slice/browsePackage";
import {
  updateBrowserPage,
  updateBrowserRow,
  updateBrowserSearch_term,
  updateBrowserSearch_type,
} from "../../store/slice/browseFilterSlicePackage";
import { goToTop } from "../../utils/helper";
import Pagination from "../pagination";

function createData(name: string) {
  return {
    name,
  };
}

function Row(props: { row: ReturnType<typeof createData> }) {
  const { row } = props;
  const [open, setOpen] = React.useState(false);

  return (
    <React.Fragment>
      <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
        <TableCell component="th" scope="row">
          {row.name}
        </TableCell>

        <TableCell align="right">
          <IconButton
            aria-label="expand row"
            size="small"
            onClick={() => setOpen(!open)}
          >
            {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
          </IconButton>
        </TableCell>
      </TableRow>
      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse
            in={open}
            timeout="auto"
            unmountOnExit
            sx={{ width: "100%" }}
          >
            <Typography style={webStyles.subText}>Title</Typography>
            <Divider></Divider>
            <Box sx={[webStyles.flexBox]}>
              <Typography sx={webStyles.text}>
                Applied Development Trends
              </Typography>
              <Box sx={[webStyles.flexBox]}>
                <IconButton size="small">
                  <StarBorderIcon></StarBorderIcon>
                </IconButton>
                <IconButton size="small">
                  <RssFeedIcon></RssFeedIcon>
                </IconButton>
              </Box>
            </Box>
            <Divider></Divider>

            <Box sx={[webStyles.flexBox]}>
              <Typography sx={webStyles.text}>
                Applied Development Trends
              </Typography>
              <Box sx={[webStyles.flexBox]}>
                <IconButton size="small">
                  <StarBorderIcon></StarBorderIcon>
                </IconButton>
                <IconButton size="small">
                  <RssFeedIcon></RssFeedIcon>
                </IconButton>
              </Box>
            </Box>
            <Divider></Divider>

            <Box sx={[webStyles.flexBox]}>
              <Typography sx={webStyles.text}>
                Applied Development Trends
              </Typography>
              <Box sx={[webStyles.flexBox]}>
                <IconButton size="small">
                  <StarBorderIcon></StarBorderIcon>
                </IconButton>
                <IconButton size="small">
                  <RssFeedIcon></RssFeedIcon>
                </IconButton>
              </Box>
            </Box>
            <Divider></Divider>
            <Box sx={[webStyles.flexBox]}>
              <Typography sx={webStyles.text}>
                Applied Development Trends
              </Typography>
              <Box sx={[webStyles.flexBox]}>
                <IconButton size="small">
                  <StarBorderIcon></StarBorderIcon>
                </IconButton>
                <IconButton size="small">
                  <RssFeedIcon></RssFeedIcon>
                </IconButton>
              </Box>
            </Box>
            <Divider></Divider>
            <Box sx={[webStyles.flexBox]}>
              <Typography sx={webStyles.text}>
                Applied Development Trends
              </Typography>
              <Box sx={[webStyles.flexBox]}>
                <IconButton size="small">
                  <StarBorderIcon></StarBorderIcon>
                </IconButton>
                <IconButton size="small">
                  <RssFeedIcon></RssFeedIcon>
                </IconButton>
              </Box>
            </Box>
            <Divider></Divider>
            <Box sx={[webStyles.flexBox]}>
              <Typography sx={webStyles.text}>
                Applied Development Trends
              </Typography>
              <Box sx={[webStyles.flexBox]}>
                <IconButton size="small">
                  <StarBorderIcon></StarBorderIcon>
                </IconButton>
                <IconButton size="small">
                  <RssFeedIcon></RssFeedIcon>
                </IconButton>
              </Box>
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </React.Fragment>
  );
}

export default function Packages() {
  const navigate = useNavigate();

  const infoCustomerId =
    useSelector((state: any) => state.login?.informaticscustomer_id) ||
    sessionStorage.getItem("informaticscustomer_id");

  const searchReducer: any = useSelector((state: any) => state);
  const [userData, setUserData] = React.useState<any>({});

  const [journalName, setJournalName] = React.useState("");
  const [selectedListTags, setselectedListTags] = React.useState<any[]>([]);
  const [journalSearchResults, setJournalSearchResults] = React.useState<any[]>(
    []
  );
  const [AllNoOfResults, setAllNoOfResults] = React.useState(0);
  const [loading, setLoading] = React.useState(true);
  const [keywords, setKeywords] = React.useState<any[]>([]);
  const [renderFlag, setRenderFlag] = React.useState(true);
  const [facetFlag, setFacetFlag] = React.useState(false);
  const [facetFlag1, setFacetFlag1] = React.useState(false);
  const [facetFlag2, setFacetFlag2] = React.useState(false);
  const [noOfPackages, setNoOfPackages] = React.useState<any>(null);

  const [sortBy, setSortBy] = React.useState(false);
  const [sort, setSort] = React.useState("");
  const [filters, setFilters] = React.useState<any>("");
  const [jsonFacet, setJsonFacet] = React.useState<any>({});
  const [filterFlag, setFilterFlag] = React.useState(false);
  const [options, setOptions] = React.useState("1");
  const [selectedTag, setSelectedTag] = React.useState("");
  const [showDropDown, setShowDropDown] = React.useState(false);
  const [selectedCollection, setSelectedCollection] = React.useState("");
  const [selectedPublishers, setSelectedPublishers] = React.useState<string[]>(
    []
  );
  const [selectedPublisherCountry, setSelectedPublisherCountry] =
    React.useState<string[]>([]);
  const [selectedSubjects, setSelectedSubjects] = React.useState<string[]>([]);
  let selectedLetterTags = Array(28).fill(false);

  const [selectedTags, setSelectedTags] =
    React.useState<boolean[]>(selectedLetterTags);

  const [selectedTab, setSelectedTab] = React.useState("all");

  const [noOfFilters, setNoOfFilters] = React.useState(0);

  const [apipage, setApiPage] = React.useState(1);

  const dispatch = useDispatch();

  const packageHits = useSelector(
    // @ts-ignore
    (state) => state?.browsePackage?.browserResult
  );

  const search_type = useSelector(
    // @ts-ignore
    (state) => state?.browseFilterSlicePackage.search_type
  );

  const search_term = useSelector(
    // @ts-ignore
    (state) => state?.browseFilterSlicePackage.search_term
  );

  const rows = useSelector(
    // @ts-ignore
    (state) => state?.browseFilterSlicePackage.rows
  );

  const currentPage = useSelector(
    // @ts-ignore
    (state) => state?.browseFilterSlicePackage.page
  );

  let count = Math.ceil(packageHits?.hits / rows);
  const search_terms = useSelector(
    // @ts-ignore
    (state) => state?.browseFilterSlicePackage
  );

  React.useEffect(() => {
    setUserData(searchReducer);
  }, []);

  const setJournlResults = async () => {
    let apiJournalData: any = {
      customer_id: infoCustomerId,
      rows: rows,
      page: currentPage,
    };
    if (search_type !== "") {
      Object.assign(apiJournalData, { search_type: search_type });
    }
    if (search_type == "") {
      Object.assign(apiJournalData, { search_type: "1" });
    }
    if (search_term !== "") {
      Object.assign(apiJournalData, { search_term: search_term });
    }
    let response = await getAllPackages(objectToQueryString(apiJournalData));
    if (response?.data.data[0][0]?.data === null) {
      setNoOfPackages(0);
      setLoading(false);
      setRenderFlag(false);
    } else {
      setApiPage(apipage + 1);
      setJournalSearchResults([
        ...journalSearchResults,
        ...response?.data.data[0][0]?.data,
      ]);
      dispatch(browseJournalSearchResult(response?.data?.data[0][0]));
      setLoading(false);
      setAllNoOfResults(response?.data.data[0][0]?.hits);
    }
  };

  React.useEffect(() => {
    const debounce = setTimeout(() => {
      setJournlResults();
    }, 100);

    return () => {
      clearTimeout(debounce);
    };
  }, [search_term, currentPage, rows]);

  function handlePageChange(p: any) {
    dispatch(updateBrowserPage(p));
    // setPage(p);
    // isMounted.current = true;
    goToTop();
  }

  function getRowsPerPage(value: number) {
    // isMounted.current = true;
    dispatch(updateBrowserRow(value));
    // setRow(value);
  }

  return (
    <Box sx={{ width: "133%" }}>
      <TableContainer component={Paper} sx={{ marginTop: "2%" }}>
        <Table aria-label="collapsible table">
          <TableHead>
            <TableRow sx={{ backgroundColor: "#F9FAFB" }}>
              <TableCell>Package Name</TableCell>

              <TableCell align="right"></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {packageHits?.data &&
              packageHits?.data?.map((e: any) => (
                <TableRow
                  onClick={() =>
                    navigate("/BrowseJournalTableSearch/PackageArticle", {
                      state: {
                        packageId: e?.package_id,
                        // packageName: e?.package_name,
                        //   publisherId:
                        //     element?.doclist?.docs[0]?.publisher_id[0],
                        publisherName: e?.package_name,
                        //   publisherFilter: props.filters,
                        packageDetails: e,
                        from: "package",
                      },
                    })
                  }
                >
                  <TableCell sx={{ cursor: "pointer" }}>
                    {e.package_name}
                  </TableCell>
                  <TableCell>
                    <ArrowForwardIosIcon
                      fontSize="small"
                      style={{ alignSelf: "center", cursor: "pointer" }}
                    ></ArrowForwardIosIcon>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </TableContainer>
      <Box my={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          count={count}
          handlePageChange={handlePageChange}
          rowsPerResult={rows}
          getRowsPerPage={getRowsPerPage}
          currentPage={currentPage}
        />
      </Box>
    </Box>
  );
}

const webStyles = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingTop: "0.3%",
    paddingBottom: "0.3%",

    alignContent: "center",
  },
  text: {
    fontSize: "0.875rem",
    alignSelf: "center",
  },
  subText: {
    fontFamily: "Helvetica Neue",
    fontSize: "1rem",
    paddingTop: "1%",
    paddingBottom: "1%",
    backgroundColor: "#f5f5f5",
  },
};
