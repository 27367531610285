import {
  Box,
  Button,
  Checkbox,
  Divider,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Text from "@mui/material/Typography";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import GreenOpenLock from "../../assets/images/open-green-lock.svg";
import RedCloselockimg from "../../assets/images/red-close-lock.svg";
import CardItem from "../../components/card/cardItemBooksBrowser";
import Pagination from "../../components/pagination";
import {
  bookSearchResult,
  updateLoadingState,
  updateTocPageSearch,
} from "../../store/slice/browseJournal";
import { Colors, dataTypes } from "../../utils/constants";

import { ExpandMore } from "@mui/icons-material";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import CloseIcon from "@mui/icons-material/Close";
import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import StarIcon from "@mui/icons-material/Star";
import StarOutlineIcon from "@mui/icons-material/StarOutline";
import Alert from "@mui/material/Alert";
import Snackbar from "@mui/material/Snackbar";
import { MathJax } from "better-react-mathjax";
import { getBookAlertDetails, getListOfJournal } from "../../api/browseJournal";
import { updateBooksFavorites } from "../../api/MyLibrary/TOCAlerts.api";
import IdIcon from "../../assets/img/id.png";
import RightDrawerOfBook from "../../Book/rightDrawerBook";
import NoResultModal from "../../components/modals/NoResultFound";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { clearAllFilters } from "../../store/slice/filterSlice";
import {
  updateMarkedArticleCount,
  updateMarkedArticleId,
  updateMarkedResult,
} from "../../store/slice/searchSlice";
import {
  goToTop,
  isStringOfArray,
  sortAuthor,
  sortAuthorDetail,
  sortByAuthorRole,
  toTitleCase,
} from "../../utils/helper";
import highlightKeyword from "../../utils/highlightKeyword";
import metaData from "../../utils/metadata";
import objectToQueryString from "./objToQuery";

const BookBrowser: React.FC = () => {
  const selectedFilters = useAppSelector((state) => state.browseFilterSlice);
  const [noresultModal, setNoResultModal] = useState(false);
  const location = useLocation();
  const [details, setDetails] = useState(location?.state?.item);

  const [expanded, setExpanded] = React.useState(false);

  const item = location.state;
  console.log("details main book", item);

  const getDataCount = useAppSelector(
    (state) => state.browseJournalSearch?.getCardDataDetails
  );

  const [valueText, setvalueText] = React.useState("");
  const [keywords, setKeywords] = React.useState<string[]>([]);

  const [filters, setFilters] = React.useState<any>("");
  const [markResult, setMarkResult] = useState(false);

  const searchReducer: any = useSelector(
    (state: any) => state.browseJournalSearch.getCardDataDetails
  );
  const navigate = useNavigate();
  const searchItem: any = useSelector(
    (state: any) => state.browseJournalSearch.tocSearchCard
  );
  const markedArticlesid = useAppSelector(
    (state) => state.searchReducer.markedArticleId
  );

  const [message, setMessage] = useState({ messages: "", open: false });

  const searchParams = new URLSearchParams(location.search);
  const bookid = searchParams.get("id");

  const [results, setResults] = React.useState<any>();
  const dispatch = useAppDispatch();

  const [page, setPage] = useState(1);
  const [rows, setRows] = useState(15);
  const [count, setCount] = useState(0);
  const [favorite_set, setfavorite] = React.useState<any>([]);
  const [dialogueData, setDialogueData] = useState({});
  const [top, setTop] = useState(false);
  const [markedData, setMarkdeData] = useState<string[]>(markedArticlesid);
  const isDSpace = useAppSelector((state) => state.searchReducer.dSpace);
  const markedArticleCount = useAppSelector(
    (state) => state.searchReducer.markedArticleCount
  );

  const searchTerm = useAppSelector((state) => state.searchReducer.searchTerm);
  const filterReducer = useAppSelector((state) => state.filterReducer);

  const markedResult = useAppSelector(
    (state) => state.searchReducer.markedResult
  );

  const isOpac = useAppSelector((state) => state.filterReducer.libraryOpac);

  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const profileData = useAppSelector((state) => state.login);

  const contextId =
    selectedFilters.collectionsValueJournal === "J-GateCollection"
      ? 1
      : selectedFilters.collectionsValueJournal === "Consortia Collections"
      ? 2
      : selectedFilters.collectionsValueJournal === "My Library Collections"
      ? 3
      : selectedFilters.collectionsValueJournal ===
        "MyPersonalLibraryCollection"
      ? 4
      : selectedFilters.collectionsValueJournal === "ONOS Collections"
      ? 5
      : selectedFilters.collectionsValueJournal === "Library OPAC"
      ? 6
      : selectedFilters.collectionsValueJournal === "Open Access Collections"
      ? 7
      : null;

  let userId = sessionStorage.getItem("user_id");
  let informaticscustomer_id = sessionStorage.getItem("informaticscustomer_id");

  const setBookResults = async () => {
    dispatch(updateLoadingState(true));

    let subjects = "";
    let dataTypes = "";
    if (searchReducer.subjectType?.length > 0) {
      searchReducer.subjectType?.map((element: any) => {
        subjects += `${element} OR `;
      });
      subjects = `(${subjects.slice(0, -3)})`;
    }

    if (searchReducer?.defaultDataType?.length > 0) {
      searchReducer?.defaultDataType?.map((element: any) => {
        dataTypes += `${element} OR `;
      });

      dataTypes = `(${dataTypes.slice(0, -3)})`;
    }

    let apiJournalData: any = {
      page: page,
      rows: rows,
      profileuserid: userId,
      customerid: informaticscustomer_id,
      consortiamaster_id: null,
      //   sessionid: session_key,
      datsearchtype_id: 4,
      datproductcontrolitem_id: 55,
      datsearch_action_type_id: 4,
      fq__bookmaster_id: bookid,
      sort: "dateofpublication desc, volumefrom desc, issuenumberfrom desc",
      fq__has_chapter: true,
      // titleKeywordsAbs:
    };
    if (valueText) {
      Object.assign(apiJournalData, { titleKeywordsAbs: valueText });
    }
    let apiResults: any[];

    let response;

    response = await getListOfJournal(
      "articlecore2?" + objectToQueryString(apiJournalData)
    );

    if (
      filters.includes("&fq__fulltext_ACL=") &&
      response?.data.data.docs.length === 0
    ) {
      dispatch(updateLoadingState(false));

      setMessage({
        messages:
          "There are no configured subjects nor subscription information for your library . Please contact your library administrator.",
        open: true,
      });
      return;
    }
    // dispatch(browseJournalSearchResult(response?.data.data));bookSearchResult
    dispatch(bookSearchResult(response?.data.data));
    //tis is were u set it
    if (response?.data?.data?.hits === 0) {
      setNoResultModal(true);
    }
    setCount(response?.data?.data?.hits);

    apiResults = [...response?.data.data.docs];
    setResults(apiResults);
    // setAllNoOfResults(response?.data.data.hits);

    dispatch(updateLoadingState(false));
  };

  const handleClose = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }

    setMessage({
      messages: "",
      open: false,
    });
  };

  async function closeNoResultModal() {
    setvalueText("");
    setKeywords([]);
    dispatch(updateTocPageSearch([]));
    dispatch(clearAllFilters());
    setNoResultModal(false);
  }

  const handlePageChange = (p: any) => {
    setPage(p);
  };

  const getRowsPerPage = (r: any) => {
    setRows(r);
  };

  const handleSubmit = async () => {
    if (valueText == "") {
      setMessage({
        messages: "Try for some appropriate keywords / terms",
        open: true,
      });
      return;
    }
    setKeywords([valueText]);
    // console.log("working on click");
  };

  const onRemove = (index: number) => {
    const removeKeyword = searchItem.filter((e: any) => {
      return e != searchItem[index];
    });
    dispatch(updateTocPageSearch([]));

    setKeywords(removeKeyword);
  };
  function getMarkedResult(res: any, id: string) {
    let data = structuredClone({
      ...res,
      searchTerm: searchTerm.toString(),
      advancedSearchTerm: filterReducer?.allAppliedFilter?.map((val) =>
        val.value.toString()
      ),
    });
    // Handle id
    if (markedData.includes(id)) {
      let filterIds = markedArticlesid.filter((data) => data !== id);
      let filterIcCount = markedArticleCount.filter((data) => data !== id);
      let filterData = markedResult.filter(
        (data: any) => data?.article_id !== id
      );
      dispatch(updateMarkedResult(filterData));
      dispatch(updateMarkedArticleId(filterIds));

      dispatch(updateMarkedArticleCount(filterIcCount));
      setMarkdeData((prev) => prev.filter((obj) => obj !== id));
    } else if (markedData?.length) {
      let articlesIds = [...markedArticlesid, id];
      let markedResultData = [...markedResult, data];
      let idCount = [...markedArticleCount, id];

      dispatch(updateMarkedResult(markedResultData));
      dispatch(updateMarkedArticleId(articlesIds));
      dispatch(updateMarkedArticleCount(idCount));

      setMarkdeData((prev) => [...prev, id]);
    } else {
      if (!markedArticlesid.length) {
        dispatch(updateMarkedArticleId([id]));
        dispatch(updateMarkedResult([data]));
        dispatch(updateMarkedArticleCount([id]));
      } else {
        let articlesIds = [...markedArticlesid, id];
        let markedResultData = [...markedResult, data];
        dispatch(updateMarkedResult(markedResultData));
        dispatch(updateMarkedArticleId(articlesIds));
        dispatch(updateMarkedArticleCount([id]));
      }

      setMarkdeData([id]);
    }
  }
  const getResourceAlerts = async () => {
    const resource_ids = item?.bookmaster_id;

    const res: any = await getBookAlertDetails(resource_ids);
    let favset: any = [];
    res?.[0][0].map((item: any) => {
      if (item?.favorite_set) {
        favset.push(item?.sub_resource_id);
      }
    });
    setfavorite(favset);
  };

  useEffect(() => {
    const debounce = setTimeout(() => {
      setBookResults();
    }, 100);
    return () => {
      clearTimeout(debounce);
    };
  }, [page, rows, keywords]);

  useEffect(() => {
    const debounce = setTimeout(() => {
      getResourceAlerts();
    }, 200);
    return () => {
      clearTimeout(debounce);
    };
  }, [results]);

  const fav = async (ale: any) => {
    const data = {
      usermaster_id: userId,
      resource_ids: [parseInt(ale.bookmaster_id)],
      is_favourite: true,
    };

    const formData = new FormData();
    formData.append("userjson", JSON.stringify(data));
    updateBooksFavorites({ formData });
    setfavorite((prev: any) => [...prev, ale.bookmaster_id]);
  };

  const removeFav = async (ale: any) => {
    const data = {
      usermaster_id: userId,
      resource_ids: [parseInt(ale.bookmaster_id)],
      is_favourite: false,
    };

    const formData = new FormData();
    formData.append("userjson", JSON.stringify(data));
    updateBooksFavorites({ formData });
    setfavorite((prev: any) =>
      prev.filter((id: any) => id !== ale.bookmaster_id)
    );
  };

  const handlerightDrawer = (data: any) => {
    setTop(!top);
    setDialogueData(data);
  };
  useEffect(() => {
    markResultStatus();
  }, [markedData?.length]);

  function markResultStatus() {
    try {
      if (results?.length === 0) return;
      let dataArray = results;

      if (!Array.isArray(dataArray)) return;

      let articlesIds = dataArray?.map((obj) => obj?.article_id);
      let allItemsPresent = articlesIds.every((id) =>
        markedArticlesid.includes(id)
      );
      if (markedData?.length === results?.length && allItemsPresent) {
        setMarkResult(true);
      } else {
        setMarkResult(false);
      }
    } catch (err) {
      console.error(err);
    }
  }
  function selectAllArticles(e: any) {
    try {
      let dataArray = results;
      dataArray = dataArray?.map((x: any) =>
        structuredClone({
          ...x,
          searchTerm: searchTerm,
          advancedSearchTerm: filterReducer?.allAppliedFilter?.map((val) =>
            val.value.toString()
          ),
        })
      );
      if (!Array.isArray(dataArray)) return;
      if (e?.target?.checked) {
        let filteredMarkedIds = [];
        let filteredMarkedData = [];
        let articlesIds = dataArray?.map((obj) => obj?.article_id);
        for (let id of articlesIds) {
          if (!markedArticlesid.includes(id)) filteredMarkedIds.push(id);
        }
        for (let obj of dataArray) {
          if (filteredMarkedIds.includes(obj?.article_id))
            filteredMarkedData.push(obj);
        }
        dispatch(updateMarkedResult([...markedResult, ...filteredMarkedData]));
        dispatch(
          updateMarkedArticleId([...markedArticlesid, ...filteredMarkedIds])
        );
        dispatch(
          updateMarkedArticleCount([...markedArticlesid, ...filteredMarkedIds])
        );
        setMarkdeData(articlesIds);
      } else {
        setMarkdeData([]);
        // curr res ids
        let articlesIds = dataArray?.map((obj) => obj?.article_id);
        // check the current res id in marked articls ids and remove all
        let filteredMarkedIds = [];
        let filteredMarkedData = [];
        for (let id of markedArticlesid) {
          if (!articlesIds.includes(id)) filteredMarkedIds.push(id);
        }
        dispatch(updateMarkedArticleId(filteredMarkedIds));
        dispatch(updateMarkedArticleCount(filteredMarkedIds));
        // keep the data in mark result that matches  "filteredMarkedIds"
        for (let obj of markedResult) {
          if (filteredMarkedIds.includes(obj?.article_id))
            filteredMarkedData.push(obj);
        }
        dispatch(updateMarkedResult(filteredMarkedData));
      }
    } catch (err) {
      console.error(err);
    }
  }
  return (
    <div style={{ backgroundColor: "#f5f5f5" }}>
      <Box
        py={"0.5%"}
        sx={{ paddingInline: "66px", backgroundColor: "#FFFFFF" }}
      >
        <Card sx={{ borderWidth: 0, overflow: "visible" }}>
          <CardContent
            sx={[
              {
                display: "flex",
                flexDirection: "column",
                justifyContent: "space-between",
                padding: "1% 1% 0.5% 1%",
              },
            ]}
          >
            <Box
              sx={{
                ...webStyles.flexRow,
                alignItems: "flex-start",
                justifyContent: "space-between",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "flex-start",
                }}
              >
                <IconButton
                  onClick={() => {
                    navigate(-1);
                  }}
                >
                  <ArrowBackIosIcon
                    sx={{ alignSelf: "center" }}
                    fontSize="small"
                  />
                </IconButton>
                <Box sx={{ ...webStyles.flexCol }}>
                  <Box sx={{ display: "flex", flexDirection: "row", gap: 3 }}>
                    {item?.peer_reviewed ? (
                      <Box
                        sx={{ display: "flex", flexDirection: "row", gap: 1 }}
                      >
                        <PeopleAltOutlinedIcon
                          sx={{
                            alignSelf: "center",
                            color: Colors.primary,
                          }}
                        ></PeopleAltOutlinedIcon>
                        <Typography
                          sx={{
                            textAlign: "center",
                            alignSelf: "center",
                            fontSize: "16px",
                            color: Colors.primary,
                            cursor: "pointer",
                          }}
                        >
                          Peer Reviewed
                        </Typography>
                      </Box>
                    ) : null}
                    {favorite_set?.includes(item?.bookmaster_id) ? (
                      <Tooltip title="Remove Favourite">
                        <IconButton
                          style={{ color: "#FFC000" }}
                          onClick={async (e) => {
                            e.stopPropagation();
                            removeFav(item);
                          }}
                        >
                          <StarIcon></StarIcon>
                        </IconButton>
                      </Tooltip>
                    ) : (
                      <Tooltip title="Set Favourite">
                        <IconButton
                          onClick={async (e) => {
                            e.stopPropagation();
                            fav(item);
                          }}
                        >
                          <StarOutlineIcon></StarOutlineIcon>
                        </IconButton>
                      </Tooltip>
                    )}
                  </Box>

                  <Typography
                    sx={[webStyles.headerText, { margin: 0 }]}
                    variant="h5"
                    gutterBottom
                  >
                    <MathJax>
                      <div
                        id="title"
                        dangerouslySetInnerHTML={{
                          __html: highlightKeyword(
                            item.book_name ? item?.book_name : item?.title,
                            []
                          ),
                        }}
                      />
                    </MathJax>
                  </Typography>
                  <Box
                    sx={{ ...webStyles.flexRow, alignItems: "flex-start" }}
                    mt={1}
                  >
                    <Typography
                      style={webStyles.subText}
                      sx={{ display: "flex" }}
                      gutterBottom
                    >
                      <Typography
                        sx={{
                          whiteSpace: "nowrap",
                          margin: "auto",
                          fontSize: "0.9rem",
                          fontWeight: 700,
                          alignSelf: "center",
                          fontFamily: "Helvetica Neue",
                        }}
                      >
                        Published By : {`${item?.publisher_name}`}
                      </Typography>
                    </Typography>
                  </Box>

                  <Box sx={{ display: "flex", gap: 1 }}>
                    <Typography
                      onClick={() => handlerightDrawer(item)}
                      sx={{
                        textAlign: "center",
                        alignSelf: "center",
                        fontSize: "16px",
                        color: Colors.primary,
                        cursor: "pointer",
                      }}
                    >
                      View Details
                    </Typography>
                    <ExpandMore
                      // expand={expanded}
                      onClick={() => handlerightDrawer(item)}
                      aria-expanded={expanded}
                      aria-label="show more"
                      sx={{
                        color: Colors.primary,
                        padding: 0,
                        cursor: "pointer",
                      }}
                    >
                      {/* <ExpandLessIcon /> */}
                    </ExpandMore>
                    {/* {item?.bookurl && (
                      <Typography variant="body2" sx={{ alignSelf: "center" }}>
                        Book Link :{" "}
                        <a
                          target="_blank"
                          style={{ color: Colors.primary }}
                          href={`${item?.bookurl}`}
                          rel="noreferrer"
                        >
                          {item?.bookurl}
                        </a>
                      </Typography>
                    )} */}
                  </Box>
                </Box>
              </Box>
            </Box>

            <Box
              sx={[
                {
                  display: "flex",
                  flexDirection: "row",
                  paddingTop: 2,
                },
              ]}
            >
              <TextField
                required
                sx={[{ backgroundColor: "#fff" }, webStyles.inputField]}
                placeholder="Search Keyword"
                fullWidth
                value={valueText}
                onChange={(e) => {
                  setvalueText(e.target.value);
                }}
                onKeyPress={(e) => {
                  if (e.key === "Enter" && valueText) handleSubmit();
                }}
              />
              <Button
                variant="outlined"
                sx={webStyles.buttonOne}
                onClick={handleSubmit}
              >
                Search
              </Button>
            </Box>

            <Box
              sx={[
                webStyles.flexRow,
                {
                  marginTop: "1.5%",
                  // marginBottom: "1%",
                  flexWrap: "wrap",
                  gap: 1,
                  alignItems: "center",
                },
              ]}
            >
              <>
                <Button
                  sx={webStyles.clearallText}
                  onClick={() => {
                    setvalueText("");
                    setKeywords([]);
                  }}
                >
                  Clear All
                </Button>
                {searchItem.length !== 0
                  ? searchItem?.map((element: string, i: number) => {
                      return (
                        <Stack direction="row" spacing={2}>
                          <Button
                            size="small"
                            variant="outlined"
                            endIcon={<CloseIcon />}
                            sx={webStyles.searchTextFont}
                            onClick={() => {
                              onRemove(i);
                            }}
                          >
                            {toTitleCase(element)}
                          </Button>
                        </Stack>
                      );
                    })
                  : null}
              </>
            </Box>
          </CardContent>

          <Collapse in={expanded} timeout="auto" unmountOnExit></Collapse>
        </Card>
      </Box>
      <Box mt="1%" sx={{ flex: 1, display: "flex" }}>
        <Typography
          fontWeight={"700"}
          fontSize={"20px"}
          lineHeight={"24px"}
          mt={2.5}
          mb={2.5}
          ml={10}
          sx={{
            fontFamily: "Helvetica Neue",
          }}
        >
          Results {count ? count?.toLocaleString("en-US") : ""}
        </Typography>
        <Box sx={{ display: "flex", width: "50%" }}>
          {!isOpac && (
            <>
              <Checkbox
                sx={{ ml: "5px" }}
                onChange={selectAllArticles}
                checked={markResult}
                inputProps={{ "aria-label": "controlled" }}
                disabled={isDSpace}
              ></Checkbox>
              <Typography
                style={{
                  alignSelf: "center",
                  marginRight: "4%",
                  color: Colors.primary,
                  fontSize: "0.875rem",
                  cursor: "pointer",
                  flexShrink: 0,
                }}
                onClick={() => {
                  if (!markedArticleCount.length || isDSpace) return;
                  navigate("/marked-result");
                }}
              >
                {markedArticleCount?.length
                  ? `View Marked Results`
                  : "Mark Results"}
                {markedArticleCount?.length > 0
                  ? "(" + markedArticleCount?.length + ")"
                  : ""}
              </Typography>
            </>
          )}
        </Box>
      </Box>

      <Box
        sx={[
          webStyles.flexBox,
          {
            width: "100%",
            paddingInline: "66px",
          },
        ]}
      >
        <Box sx={{ flex: 6 }} ml={2.5}>
          {results?.map((data: any) => {
            return (
              <CardItem
                data={data}
                getMarkedResult={getMarkedResult}
                markedArticlesid={markedData}
                searchTerm={searchTerm.toString()}
                resultFrom="searchResult"
              >
                {/***********************************************************************************************************************************************
              Title
            ***********************************************************************************************************************************************/}
                <CardContent sx={{ padding: "0px 14px" }}>
                  {data?.title && (
                    <Text
                      onClick={() => handlerightDrawer(data)}
                      fontWeight={600}
                      fontSize={"1.125rem"}
                      fontFamily={"Lora"}
                      sx={{
                        textAlign: "left",
                        color: "#1A1A1A",
                        cursor: "pointer",
                      }}
                      component={"div"}
                    >
                      <MathJax>
                        <div
                          id="title"
                          dangerouslySetInnerHTML={{
                            __html: data?.title,
                          }}
                        />
                      </MathJax>
                    </Text>
                  )}
                </CardContent>

                {/***********************************************************************************************************************************************
              Author Details
            ***********************************************************************************************************************************************/}

                <CardContent sx={{ display: "flex", padding: "0 14px" }}>
                  {Array.isArray(
                    data?.data_type === dataTypes.books
                      ? data?.authors_tk
                      : data?.author_details
                  ) &&
                    !isStringOfArray(
                      data?.data_type === dataTypes.books
                        ? data?.authors_tk
                        : data?.author_details
                    ) &&
                    sortByAuthorRole(
                      sortAuthorDetail(
                        data?.data_type === dataTypes.books
                          ? data?.authors_tk
                          : data?.author_details
                      ),
                      data?.data_type === dataTypes.dissertationThesis
                    )?.map((obj: any, i) => {
                      const firstName = obj?.author_fname
                        ? obj?.author_fname
                        : "";
                      const lastName = obj?.author_lname
                        ? obj?.author_lname
                        : "";

                      const reversedName = lastName
                        ? lastName + ", " + firstName
                        : firstName;
                      const displayName = lastName
                        ? lastName.replace(/,/g, " ") +
                          ", " +
                          firstName.replace(/,/g, " ")
                        : firstName.replace(/,/g, " ");

                      if (i > 3) return null;

                      return (
                        <Box
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                          alignItems={"center"}
                        >
                          {i < 3 ? (
                            <>
                              <Tooltip
                                title={
                                  "test"
                                  //change here
                                }
                              >
                                <Button
                                  // onClick={() => {
                                  //   handleAuthor(
                                  //     reversedName,
                                  //     data,
                                  //     obj
                                  //   );
                                  // }}
                                  sx={{
                                    fontFamily: "Helvetica Neue",
                                    fontSize: "14px",
                                    textDecoration: "underline",
                                    padding:
                                      i === 0 ? "8px 0px 6px 0px" : "6px 8px",
                                  }}
                                >
                                  {displayName}
                                </Button>
                              </Tooltip>
                              {obj?.orc_id ? (
                                <a
                                  href={`https://orcid.org/${obj?.orc_id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    src={IdIcon}
                                    style={{
                                      paddingLeft: "5px",
                                      marginTop: "8px",
                                      paddingRight: "6px",
                                    }}
                                    alt="IdIcon"
                                  />
                                </a>
                              ) : null}

                              <Divider
                                orientation="vertical"
                                style={{
                                  height: "60%",
                                  alignSelf: "center",
                                }}
                              />
                            </>
                          ) : (
                            <Button
                              sx={{
                                color: "#6B7280",
                                textDecoration: "underline",
                              }}
                              // onClick={() => handleDawer(data)}
                            >
                              +{data.author_details?.length - 3} More
                            </Button>
                          )}
                        </Box>
                      );
                    })}
                  {Array.isArray(
                    data?.data_type === dataTypes.books
                      ? data?.authors_tk
                      : data?.author_details
                  ) &&
                    isStringOfArray(
                      data?.data_type === dataTypes.books
                        ? data?.authors_tk
                        : data?.author_details
                    ) &&
                    sortAuthor(
                      data?.data_type === dataTypes.books
                        ? data?.authors_tk
                        : data?.author_details
                    )?.map((name: string, i: number) => {
                      if (i > 3) return null;
                      return (
                        <Box
                          key={i}
                          display={"flex"}
                          flexDirection={"row"}
                          justifyContent={"space-between"}
                        >
                          {i < 3 ? (
                            <>
                              {name?.length ? (
                                <Button
                                  onClick={
                                    () => {}
                                    // handleAuthor(name, data)
                                  }
                                  sx={{
                                    fontFamily: "Helvetica Neue",
                                    fontSize: "14px",
                                    textDecoration: "underline",
                                    padding:
                                      i === 0 ? "8px 8px 6px 0px" : "6px 8px",
                                  }}
                                >
                                  {name.length
                                    ? name
                                        .trim()
                                        .replace(/^\,+|\,+$/g, "")
                                        .trim()
                                    : ""}{" "}
                                </Button>
                              ) : null}

                              <Divider
                                orientation="vertical"
                                style={{
                                  height: "60%",
                                  alignSelf: "center",
                                }}
                              />
                            </>
                          ) : (
                            <Button
                              sx={{
                                color: "#6B7280",
                                textDecoration: "underline",
                              }}
                              // onClick={() => handleDawer(data)}
                            >
                              +
                              {data.authors
                                ? data.authors?.length - 3
                                : data.author_details?.length - 3}
                              {""}
                              More
                            </Button>
                          )}
                        </Box>
                      );
                    })}
                </CardContent>
                {/***********************************************************************************************************************************************
              Journal name, Data, Year
            ***********************************************************************************************************************************************/}

                <CardContent
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    padding: "4px 14px",
                  }}
                >
                  <Text
                    component={"div"}
                    sx={{
                      textAlign: "left",
                      fontSize: "16px",
                      color: Colors.primary,
                      fontStyle: "italic",
                      cursor: "pointer",
                    }}
                    // onClick={() => handleResource(data, i)}
                  >
                    {metaData(data)}
                  </Text>
                </CardContent>

                {/***********************************************************************************************************************************************
              Footer Section
            ***********************************************************************************************************************************************/}
                <CardActions
                  disableSpacing
                  style={{ justifyContent: "space-between" }}
                  sx={{ padding: "4px 14px" }}
                >
                  <Stack direction="row" spacing={2}>
                    <Stack direction={"row"} gap={1}>
                      <Button
                        onClick={() => handlerightDrawer(data)}
                        size="small"
                        sx={{
                          textDecoration: "underline",
                          color: "#000",
                        }}
                      >
                        Read More
                      </Button>
                    </Stack>
                  </Stack>
                  {data?.access_type_id === 24 ? (
                    <Tooltip title={"Open Access Article"}>
                      <Button
                        color="secondary"
                        variant="outlined"
                        href={data?.bookurl && data?.bookurl}
                        target="_blank"
                        rel="noopener noreferrer"
                        download={"true"}
                        startIcon={<img src={GreenOpenLock} />}
                        // onClick={ () => logUsageAccessButtonData(32, data)
                        // }
                      >
                        Access Full Text
                      </Button>
                    </Tooltip>
                  ) : (
                    <Button
                      sx={{
                        color: Colors.red500,
                        border: "1px solid #f05252",
                        ":hover": { border: "1px solid #f05252" },
                      }}
                      // onClick={() => logRedLockClosedButtonUsageData(34, articleData)}
                      variant="outlined"
                      startIcon={<img src={RedCloselockimg} />}
                      href={data?.bookurl && data?.bookurl}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Subscription Required
                    </Button>
                  )}
                </CardActions>
              </CardItem>
            );
          })}
          <IconButton
            aria-label="Scroll to Top"
            sx={{
              position: "fixed",
              top: "63vh",
              right: "2vw",
              transform: "translateY(-50%)",
              border: "1px solid #D1D5DB",
              backgroundColor: "#FFF",
              color: "primary.main",
              "&:hover": {
                border: "1px solid #D1D5DB",
                backgroundColor: "#FFF",
                color: "primary.main",
                boxShadow: 2,
              },
            }}
            onClick={() => goToTop()}
          >
            <ArrowUpwardIcon />
          </IconButton>
        </Box>
      </Box>

      <Box my={3} sx={{ display: "flex", justifyContent: "flex-end" }}>
        <Pagination
          count={Math.ceil(count / rows)}
          handlePageChange={handlePageChange}
          rowsPerResult={rows}
          getRowsPerPage={getRowsPerPage}
          currentPage={page}
        />
        {/* <Spinner isLoading={isLoading} /> */}
      </Box>
      <NoResultModal isOpen={noresultModal} handleClose={closeNoResultModal} />

      <RightDrawerOfBook
        dialogueData={dialogueData}
        handleDawer={handlerightDrawer}
        state={top}
      />

      {/* <Spinner isLoading={isLoading} /> */}

      <Snackbar
        open={message.open}
        autoHideDuration={1000}
        onClose={handleClose}
        anchorOrigin={{
          vertical: "top",
          horizontal: "center",
        }}
      >
        <Alert severity="warning" sx={{ width: "100%" }}>
          {message.messages}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default BookBrowser;

const webStyles = {
  button: {
    margin: "0.5%",
  },
  flexCol: {
    display: "flex",
    flexDirection: "column",
  },
  flexBox: {
    display: "flex",
    flexDirection: "row",
  },
  mainContainer: {
    flex: 1,
  },
  headerText: {
    fontFamily: "Lora",
    color: Colors.primary,
    fontWeight: 700,
  },
  subText: {
    fontFamily: "Helvetica Neue",
    fontWeight: 700,
    fontSize: "0.9rem",
  },
  checkBox: {
    fontSize: 14,
    color: Colors.primary,
    fontFmaily: "Helvetica Neue",
    fontWeight: 400,
  },
  buttonOne: {
    marginLeft: "0.9%",
    padding: "0.7rem",
    width: "10%",
    minWidth: "128px",
    fontWeight: "500",
  },
  inputField: {
    // width: "77%",
    marginRight: "1%",
    borderRadius: 60,
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderColor: Colors.coolGray300,
        borderRadius: 2,
      },
    },
    fontFamily: "Helvetica Neue",
    fontSize: "0.875rem",
  },
  flexRow: {
    display: "flex",
    flexDirection: "row",
    // backgroundColor: "red",
  },
  textResult: {
    fontFamily: "Lora",
    fontWeight: "700",
    // fontSize: 24,
    color: Colors.black,
    alignSelf: "center",
    backgroundColor: "green",
  },
  peerReviewed: {
    fontFamily: "Helvetica Neue",
    fontStyle: "normal",
    fontWeight: 500,
    fontSize: 14,
    color: "#1A1A1A",
  },
  clearallText: {
    height: 22,
  },
  searchTextFont: {
    fontFamily: "Inter",
    fontWeight: 500,
    color: Colors.gray900,
    display: "flex",
    alignItems: "center",
    textAlign: "center",
    borderColor: Colors.gray_400,
    marginLeft: 1,
    textTransform: "none",
    paddingTop: 0,
    paddingBottom: 0,
    minHeight: 22,
    borderRadius: "6px",
    //  fontSize:"0.875rem"
  },
  gridStyle: {
    position: "absolute",
    width: "330px",
    // height: "118px",
    top: "5px",
    marginTop: "50px",
    background: "#FFFFFF",
    border: "1px dashed rgba(33, 33, 33, 0.1)",
    boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.06)",
    borderRadius: "6px",
    dispay: "flex",
    justifyContent: "flex-end",
    zIndex: "100000",
  },
};
