import RssFeedIcon from "@mui/icons-material/RssFeed";
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { Colors, authorRole, dataTypes } from "../../utils/constants";

import PeopleAltOutlinedIcon from "@mui/icons-material/PeopleAltOutlined";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import { MathJax } from "better-react-mathjax";
import { useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import {
  getAbstractStatus,
  getDataSetDetails,
} from "../../api/Search/BasicSearch.api";
import {
  getCiteApi,
  getRefrencesApi,
  journalLock,
} from "../../api/browseJournal";
import { usagelogApi } from "../../api/usageReportApi";
import RetractedIcon from "../../assets/img/Retracted.svg";
import DatasetPurpleIcon from "../../assets/img/databasepurple.svg";
import DatasetWhiteIcon from "../../assets/img/databasewhite.svg";
import FindInLibraryIcon from "../../assets/img/findinlib.svg";
import IdIcon from "../../assets/img/id.png";
import PreprintIcon from "../../assets/img/preprint.svg";
import { onAuthorResult as authorsearch } from "../../screens/AuthorFinder/onAuthorResult";
import { onSearch as search } from "../../screens/Search/onSearch";
import AccessLock from "../../screens/basicSearchScreen/AccessLock";
import objectToQueryString from "../../screens/browseJournal/objToQuery";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import {
  clearAuthorSearchTerm,
  updateAuthorPage,
  updateAuthorRow,
  updateAuthorSearchTerm,
} from "../../store/slice/authorFinderSlice";
import { updateBrowserPage } from "../../store/slice/browseJournal";
import {
  clearAllFilters,
  clearAllFiltersValue,
  clearPublicationFilter,
  updateAllFilter,
  updateAuthor,
  updateConfrence,
  updatePublisher,
  updateResearch,
  updateResearcher,
  updateResourceId,
  updateSpeaker,
  updateUniversityName,
} from "../../store/slice/filterSlice";
import { clearQuery } from "../../store/slice/queryForCountSlice";
import {
  clearSearchTerm,
  updatePage,
  updateRow,
  updateSearchTerm,
} from "../../store/slice/searchSlice";
import { clearAllSetSearch } from "../../store/slice/setSearch";
import { AuthorFinderObj } from "../../types/interface";
import {
  DownloadFromApi,
  downloadDatasetApi,
} from "../../utils/abstractDownload";
import {
  accessTypeButtons,
  extractMarkedWords,
  isStringOfArray,
  removeSpecialCharacters,
  showIsProfileToast,
  sortAuthor,
  sortAuthorAffliliation,
  sortAuthorDetail,
  sortByAuthorRole,
} from "../../utils/helper";
import {
  highlightAbstract,
  highlightKeys,
  keywordHighlight,
} from "../../utils/highlightKeyword";
import metaData from "../../utils/metadata";
import AbstractDialog from "../basicSearchTopBar/abstractDialog";
import RSSAlertSearch from "../resultsPopups/RSSAlertSearch";
import Spinner from "../spinner";
import AbstractModal from "./AbstractModal";
import SJRModal from "./SJRModal";
import DownloadButton from "../downloadButton/downloadButton";
import { updatedStoredSelectedIssue } from "../../store/slice/browserFilterSliceDetails";

interface P {
  dialogueData: any;
  screen: boolean;
  handleDawer?: any;
  linkToDisplay?: any;
  authorEmailData: any;
  setTop: any;
  resultFrom?: any;
}
type TabType = "abstract" | "author" | "references" | "cite" | "dataset";

const AbstractView: React.FC<P> = ({
  dialogueData,
  screen,
  handleDawer,
  linkToDisplay,
  authorEmailData,
  setTop,
  resultFrom,
}) => {
  const [abstractTop, setAbstractTop] = useState(false);
  const [showDoi, updateDoiStatus] = useState(true);
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  // to open sjr modal
  const [isOpen, setIsOpen] = useState(false);
  const [totSearchTerms, setTotSearchTerms] = useState<any[]>([]);
  const [loading, setLoading] = useState(true);
  const [loading1, setLoading1] = useState(false);
  const [showAbstract, setShowAbstract] = useState(false);
  const [abstractUrl, setAbstractUrl] = useState("");
  const [datasetData, setDatasetData] = useState<any>([]);
  const [tabs, setTabs] = useState<TabType>("abstract");
  const [refrences, setRefrences] = useState<any>();
  const [citeDetails, setCiteDetails] = useState<any>();
  let searchResults: any = useAppSelector(
    (state) => state.searchReducer.searchResult
  );
  const location = useLocation();

  const params = new URLSearchParams();
  let [, setSearchParams] = useSearchParams();

  const isOpac = useAppSelector((state) => state.filterReducer.libraryOpac);
  const searchTerm = useAppSelector((state) => state.searchReducer.searchTerm);
  const tocSearchTerm: any = useSelector(
    (state: any) => state.browseJournalSearch.tocSearchCard
  );
  let keywordData = searchResults?.highlighting;
  const tocGetDataList = useAppSelector(
    (state) => state.browseJournalSearch?.getCardDataDetails?.highlighting
  );
  const isLoading = useAppSelector((state) => state.filterReducer.loading);
  const consortiamaster_id = useAppSelector(
    (state) => state.customer.customerDetails?.consortiamaster_id
  );

  const authorReducer: any = useSelector(
    (state: AuthorFinderObj) => state.authorReducer
  );

  const alertTypeSetting = useAppSelector(
    (state) => state.searchReducer.alertSettingData
  );
  const profileData = useSelector((state: any) => state.login);

  const profileData1 = useSelector(
    (state: any) => state.customer.customerDetails
  );

  const currentYear: number = new Date().getFullYear();

  const authorEmail_data =
    authorEmailData && authorEmailData?.map((val: any) => val?.email_id);

  const fromJournalToc =
    location?.state?.from === "BrowseJournalTableSearch/BrowseJournalSearch" ||
    location?.pathname === "/BrowseJournalTableSearch/BrowseJournalSearch";

  const selectedFullTesxt = useAppSelector(
    (state) => state.searchReducer.fullText
  );

  const selectedCollectionFromSearchResultPageAndAuthorFinder = useAppSelector(
    (state: any) => state.filterReducer
  );
  const selectedCollectionFromBrowseJournalSearch = useAppSelector(
    (state: any) => state.browseFilterSlice
  );

  const isDSPACE = useAppSelector((state) => state.filterReducer.dspace);

  useEffect(() => {
    if (location?.state?.multipleDatasets) {
      setTabs("dataset");
      setDatasetData(location?.state?.res?.dataset_details);
    }
  }, []);

  function getContextId(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "ConsortiaSubscriptions":
        return 2;
      case "MyLibraryCollection":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOSCollections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  function getContextIdFromJournal(collectionValue: any) {
    switch (collectionValue) {
      case "J-GateCollection":
        return 1;
      case "Consortia Collections":
        return 2;
      case "My Library Collections":
        return 3;
      case "MyPersonalLibraryCollection":
        return 4;
      case "ONOS Collections":
        return 5;
      case "Library OPAC":
        return 6;
      case "Open Access Collections":
        return 7;
      default:
        return null;
    }
  }

  const contextIdFromfilterReducer = getContextId(
    selectedCollectionFromSearchResultPageAndAuthorFinder?.collectionValue
  );

  const contextIdFromBrowseFilterSlice = getContextIdFromJournal(
    selectedCollectionFromBrowseJournalSearch?.collectionsValueJournal
  );

  const contextId =
    resultFrom === "searchResult" || resultFrom === "authorFinder"
      ? contextIdFromfilterReducer
      : resultFrom === "BrowseJournalTableSearch/BrowseJournalSearch"
      ? contextIdFromBrowseFilterSlice
      : null;

  const logAbstractUsageData = (usageActionID: any, details?: any) => {
    const articleArticle_Id = details?.article_id ? details?.article_id : null;
    const articleResourcemaster_id = details?.resourcemaster_id
      ? details?.resourcemaster_id
      : null;
    const articleResourceissue_id = details?.resourceissue_id
      ? details?.resourceissue_id
      : null;
    const publisherId = details?.publisher_id ? details?.publisher_id[0] : null;
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      contextId,
      null,
      articleArticle_Id,
      articleResourceissue_id,
      publisherId,
      articleResourcemaster_id,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  let customerId: any =
    useAppSelector((state) => state?.login?.informaticscustomer_id) ||
    sessionStorage.getItem("informaticscustomer_id");
  const profileStatus = sessionStorage.getItem("isProfileUser");

  const articleId = dialogueData?.article_id ? dialogueData?.article_id : null;
  const resourceissueId = dialogueData?.resourceissue_id
    ? dialogueData?.resourceissue_id
    : null;
  const resourcemasterId = dialogueData?.resourcemaster_id
    ? dialogueData?.resourcemaster_id
    : null;
  const resourceType = dialogueData?.resource_type
    ? dialogueData?.resource_type
    : null;

  const getAbstract = async () => {
    try {
      // const query = `?customer_id=${customerId}&article_id=${
      //   dialogueData?.article_id
      // }&issue_id=${
      //   dialogueData?.resourceissue_id ? dialogueData?.resourceissue_id : null
      // }&resource_id=${
      //   dialogueData?.resourcemaster_id ? dialogueData?.resourcemaster_id : null
      // }&resource_type_id=${dialogueData?.resource_type}`;

      const query = `?customer_id=${customerId}&article_id=${articleId}&issue_id=${resourceissueId}&resource_id=${resourcemasterId}&resource_type_id=${resourceType}`;
      let abstract: any;
      if (
        customerId &&
        articleId &&
        resourceissueId &&
        resourcemasterId &&
        resourceType
      )
        abstract = await getAbstractStatus(query);

      setShowAbstract(abstract[0][0].display_abstract);
      setAbstractUrl(abstract[0][0].abstract_url);
      setLoading(false);
      // @ts-ignore
      window.__SCITE?.insertBadges();
      // @ts-ignore
    } catch (error: any) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    // @ts-ignore
    window.__SCITE?.insertBadges();
  }, [showAbstract]);

  // useEffect(() => {
  //   getAbstract();
  // }, [articleId, resourceissueId, resourcemasterId, resourceType]);

  useEffect(() => {
    const debounceTime = setTimeout(async () => {
      try {
        getAbstract();
      } catch (error) {
        console.log(error);
      }
    }, 100);

    return () => {
      clearTimeout(debounceTime);
    };
  }, [articleId, resourceissueId, resourcemasterId, resourceType]);

  // useEffect(() => {
  //   getAbstract();
  // }, []);

  // useEffect(() => {
  //   sciteDetails();
  //   getRefrences();
  // }, [dialogueData]);

  useEffect(() => {
    const debounceTimeout = setTimeout(async () => {
      try {
        sciteDetails();
        getRefrences();
      } catch (error) {
        console.log(error);
      }
    }, 100);

    return () => {
      clearTimeout(debounceTimeout); // Clear timeout if dependencies change
    };
  }, [dialogueData]);

  useEffect(() => {
    if (authorReducer.abstractAuthorStatus === false) {
      let kwds: any[] = [];

      let isArticlePresent = Object.keys(keywordData).includes(
        dialogueData?.article_id
      );

      if (
        isArticlePresent &&
        keywordData[dialogueData?.article_id.toString()].title_fz
      ) {
        if (keywordData[dialogueData?.article_id.toString()].title_fz) {
          if (
            Array.isArray(
              keywordData[dialogueData?.article_id.toString()].title_fz
            )
          ) {
            kwds = extractMarkedWords(
              keywordData[dialogueData?.article_id.toString()].title_fz[0]
            );
          }
        }
      }
      const totTerms = [...searchTerm, ...kwds];
      setTotSearchTerms(totTerms);
    }
  }, []);

  const handlePopUp = () => {
    setAbstractTop(!abstractTop);
  };

  let showAuthorAffiliation: string[] =
    Array.isArray(dialogueData?.author_details) &&
    dialogueData?.author_details.filter((str: string) => str.split("$")[1])
      .length
      ? dialogueData?.author_details.filter((str: string) => str.split("$")[1])
      : sortAuthorDetail(dialogueData?.author_details)
      ? sortAuthorDetail(dialogueData?.author_details).filter(
          (obj: any) => obj?.author_affiliation
        )
      : [];

  // to support new format of author details
  let authorAffiliation =
    Array.isArray(sortAuthorDetail(dialogueData?.author_details)) &&
    sortAuthorDetail(dialogueData?.author_details).filter(
      (obj: any) => obj?.author_affiliation
    );
  //This will also fetch citation details too
  const getRefrences = async () => {
    if (dialogueData?.articledoi || dialogueData?.bookdoi) {
      try {
        setLoading1(true);
        const res = await getRefrencesApi(
          dialogueData?.articledoi || dialogueData?.bookdoi
        );
        setRefrences(res);
        const citeres = await getCiteApi(
          res.data[0].openalex_id,
          res.data[0].cited_by_count
        );
        setCiteDetails(citeres);
      } catch (error) {
        console.log(error);
      } finally {
        setLoading1(false);
      }
    }
  };

  async function handleAuthor(
    authorName: string,
    authorDetail?: any,
    authourData?: any
  ) {
    handleDawer && handleDawer();
    dispatch(clearSearchTerm());
    dispatch(clearAuthorSearchTerm());
    dispatch(clearAllFilters());
    dispatch(clearPublicationFilter());
    dispatch(clearQuery());
    dispatch(clearAllFiltersValue());
    dispatch(clearAllSetSearch());

    dispatch(updatePage(1));
    dispatch(updateRow(15));

    dispatch(updateAuthorPage(1));
    dispatch(updateAuthorRow(15));

    if (
      authourData.data_type === dataTypes.journalArticle ||
      authourData.data_type === dataTypes.journals ||
      authourData.data_type === dataTypes.conferenceProceedings ||
      authourData.data_type === dataTypes.magazine ||
      authourData.data_type === dataTypes.preprint ||
      authourData.data_type === dataTypes.books
    ) {
      logAbstractUsageData(19, authourData);
    }

    if (authourData.data_type === dataTypes.dissertationThesis) {
      logAbstractUsageData(19, authourData);
      logAbstractUsageData(20, authourData);
    }

    if (authourData.data_type === dataTypes.audioVideo) {
      logAbstractUsageData(22, authourData);
    }

    if (
      dialogueData?.authors?.length ||
      authorRole[authorDetail?.datauthorrole_id] === "Author"
    ) {
      dispatch(updateAuthor([authorName]));
      dispatch(updateAuthorSearchTerm(authorName));
      let appliedFilter = {
        key: "author",
        value: authorName,
      };

      dispatch(updateAllFilter(appliedFilter));
    } else if (
      dialogueData?.speakers?.length ||
      authorRole[authorDetail?.datauthorrole_id] === "Speaker"
    ) {
      dispatch(updateSpeaker([authorName]));
      let appliedFilter = {
        key: "speaker",
        value: authorName,
      };

      dispatch(updateAllFilter(appliedFilter));
    } else if (
      dialogueData?.guide_name === authorName ||
      authorRole[authorDetail?.datauthorrole_id] === "Guide"
    ) {
      dispatch(updateResearch([authorName]));
      dispatch(
        updateAllFilter({
          key: "research",
          value: authorName,
        })
      );
    } else if (
      dialogueData?.researcher_tk === authorName ||
      authorRole[authorDetail?.datauthorrole_id] === "Researcher"
    ) {
      dispatch(updateResearcher([authorName]));
      dispatch(
        updateAllFilter({
          key: "researcher",
          value: authorName,
        })
      );
    } else if (
      dialogueData?.editors?.length ||
      authorRole[authorDetail?.datauthorrole_id] === "Editor"
    ) {
      dispatch(updateResearcher([authorName]));
      dispatch(
        updateAllFilter({
          key: "editor",
          value: authorName,
        })
      );
    }

    // usageReport("19");

    // let data = dispatch(search());
    // !!data && navigate("/basicSearchScreen");

    if (authorReducer.isAuthorResult === true) {
      let res = dispatch(authorsearch("", null, true));
      !!res && navigate("/authorfindersearch");
    } else if (authorReducer.isAuthorResult === false) {
      let res = dispatch(search("", null, true));
      !!res && navigate("/basicSearchScreen");
      logAbstractUsageData(11, authourData);
      if (selectedFullTesxt == true) {
        logAbstractUsageData(29, authourData);
      } else {
        logAbstractUsageData(28, authourData);
      }
    }
  }

  async function handleResource(data: any, clearProp: Boolean = true) {
    // Make new search, claar all filters
    setTop(false);
    if (
      data?.data_type !== dataTypes.journalArticle ||
      data?.data_type !== dataTypes.journals
      // && clearProp
    ) {
      dispatch(clearSearchTerm());
      dispatch(clearAllFilters());
      dispatch(clearPublicationFilter());
      dispatch(clearAllFiltersValue());
      dispatch(clearAllSetSearch());
      dispatch(clearQuery());
      params.delete("searchTerm");
      setSearchParams(params, {
        replace: true,
      });
    }
    if (
      data?.data_type === dataTypes.journalArticle ||
      data?.data_type === dataTypes.journals
    ) {
      const item = data;

      // Get acces lock type
      let accessLockType;
      if (item?.openaccess_type !== "Open Access" || !item?.openaccess_type) {
        const payloadData = {
          customer_id: customerId,
          resource_id: item?.resourcemaster_id,
          access_type: 1,
        };
        let journalLockRes = await journalLock(
          "?" + objectToQueryString(payloadData)
        );
        accessLockType = Array.isArray(journalLockRes?.data?.data)
          ? journalLockRes?.data?.data[0]
          : {};
      }
      dispatch(updateBrowserPage(1));
      const yearData = Array.isArray(data?.yearfrom)
        ? data?.yearfrom[0]
        : data?.yearfrom;
      if (currentYear === yearData) {
        logAbstractUsageData(90, data);
      } else {
        logAbstractUsageData(87, data);
      }
      logAbstractUsageData(91, data);
      logAbstractUsageData(92, data);
      dispatch(updatedStoredSelectedIssue(false));
      navigate(`/BrowseJournalTableSearch/BrowseJournalSearch`, {
        state: {
          item: item,
          // from: "basicSearch",
          from: "abstractFullScreen",
          lock: accessLockType,
          previousScreenData: data,
        },
      });
    } else if (data?.data_type === dataTypes.conferenceProceedings) {
      dispatch(updateConfrence([data?.conference_name]));
      dispatch(
        updateAllFilter({
          key: "conference_name",
          value: data?.conference_name,
        })
      );
      let res = dispatch(search());
      logAbstractUsageData(115, data);
      logAbstractUsageData(11, data);
      if (selectedFullTesxt == true) {
        logAbstractUsageData(29, data);
      } else {
        logAbstractUsageData(28, data);
      }

      !!res && navigate("/basicSearchScreen");
    } else if (data?.data_type === dataTypes.dissertationThesis) {
      dispatch(updateUniversityName([data?.university_name]));
      dispatch(
        updateAllFilter({
          key: "university_name",
          value: data?.university_name,
        })
      );
      let res = dispatch(search());
      logAbstractUsageData(23, data);
      logAbstractUsageData(11, data);
      if (selectedFullTesxt == true) {
        logAbstractUsageData(29, data);
      } else {
        logAbstractUsageData(28, data);
      }

      !!res && navigate("/basicSearchScreen");
    } else if (data?.data_type === dataTypes.audioVideo) {
      dispatch(updatePublisher(data?.publisher_name));
      dispatch(
        updateAllFilter({
          key: "publisher",
          value:
            Array.isArray(data?.publisher_name) && data?.publisher_name.length
              ? data?.publisher_name[0]
              : "",
        })
      );
      let res = dispatch(search());
      logAbstractUsageData(25, data);
      logAbstractUsageData(11, data);
      if (selectedFullTesxt == true) {
        logAbstractUsageData(29, data);
      } else {
        logAbstractUsageData(28, data);
      }

      !!res && navigate("/basicSearchScreen");
    } else if (data?.data_type === dataTypes.preprint) {
      if (data?.publisher_name?.length)
        dispatch(updatePublisher(data?.publisher_name));
      dispatch(
        updateAllFilter({
          key: "publisher",
          value:
            Array.isArray(data?.publisher_name) && data?.publisher_name.length
              ? data?.publisher_name[0]
              : "",
        })
      );
      let res = dispatch(search());
      logAbstractUsageData(24, data);
      logAbstractUsageData(11, data);
      if (selectedFullTesxt == true) {
        logAbstractUsageData(29, data);
      } else {
        logAbstractUsageData(28, data);
      }

      !!res && navigate("/basicSearchScreen");
    } else if (data.data_type === "Magazine Articles") {
      dispatch(updateResourceId([data?.resourcemaster_id]));
      dispatch(
        updateAllFilter({
          key: "magazine",
          value: data?.resource_name_tk,
        })
      );
      dispatch(search());
      let res = dispatch(search());
      logAbstractUsageData(116, data);
      logAbstractUsageData(11, data);
      if (selectedFullTesxt == true) {
        logAbstractUsageData(29, data);
      } else {
        logAbstractUsageData(28, data);
      }

      !!res && navigate("/basicSearchScreen");
    } else if (data.data_type === dataTypes.books) {
      if (data?.publisher_name?.length)
        dispatch(updatePublisher(data?.publisher_name));
      dispatch(
        updateAllFilter({
          key: "publisher",
          value:
            Array.isArray(data?.publisher_name) && data?.publisher_name.length
              ? data?.publisher_name[0]
              : "",
        })
      );
      let res = dispatch(search());
      !!res && navigate("/basicSearchScreen");
    }
  }

  //set alert
  const [openRSSViewModal, setOpenRSSViewModal] = useState<Boolean>(false);
  const [rssdata, setRssdata] = useState("");

  const handleOpenViewModal = (data: any) => {
    setOpenRSSViewModal(true);

    setRssdata(data);
  };

  const handleCloseViewModal = () => setOpenRSSViewModal(false);

  async function handleKeyword(keyword: string, details?: any, data?: any) {
    try {
      handleDawer && handleDawer();
      dispatch(clearSearchTerm());
      dispatch(clearAllFilters());
      dispatch(clearPublicationFilter());
      dispatch(clearQuery());
      dispatch(clearAllFiltersValue());
      dispatch(clearAllSetSearch());

      const searchedData = {
        key: "search-term",
        value: `"${keyword}"`,
      };

      dispatch(updateSearchTerm(`"${keyword}"`));
      dispatch(updateAllFilter(searchedData));

      let data = dispatch(search("", null, true));
      logAbstractUsageData(11, data);
      logAbstractUsageData(21, data);
      if (selectedFullTesxt == true) {
        logAbstractUsageData(29, data);
      } else {
        logAbstractUsageData(28, data);
      }

      !!data && navigate("/basicSearchScreen");
    } catch (err) {
      console.log(err);
    }
  }

  let keywords =
    Array.isArray(dialogueData?.keywords) &&
    dialogueData?.keywords.filter((item: any) => item !== "");

  function closeSjrModal() {
    setIsOpen(false);
  }

  function toTitleCase(text: string) {
    const words = text.split(" ");
    const titleCasedWords = words?.map((word) => {
      return word.charAt(0).toUpperCase() + word.slice(1).toLowerCase();
    });
    return titleCasedWords.join(" ");
  }

  async function sciteDetails() {
    try {
      if (!dialogueData?.articledoi) {
        updateDoiStatus(false);
        return;
      }
      let arr = [dialogueData?.articledoi];
      let url = "https://api.scite.ai/tallies";
      let response = await fetch(url, {
        method: "POST",
        body: JSON.stringify(arr),
        headers: {
          Accept: "application/json",
          "Content-Type": "application/json",
        },
      });
      // Error res
      if (response?.status == 404) {
        updateDoiStatus(false);
        return;
      }

      let data = await response.json();
      let doiKey = dialogueData?.articledoi?.toLowerCase();
      let scietData = data.tallies[doiKey];

      if (
        scietData?.citingPublications === 0 &&
        scietData?.contradicting === 0 &&
        scietData?.mentioning === 0 &&
        scietData?.supporting === 0
      ) {
        updateDoiStatus(false);
      } else {
        setTimeout(() => {
          // @ts-ignore
          window.__SCITE?.insertBadges();
        }, 100);
        updateDoiStatus(true);
      }
    } catch (err) {
      console.log(err);
    }
  }
  function findAlertTypeSetting(
    resourcemaster_id: string | number
  ): boolean | null {
    try {
      if (!resourcemaster_id) return false;
      if (!Array.isArray(alertTypeSetting)) return null;

      const articleData = alertTypeSetting.filter(
        (obj) => obj?.resource_id === resourcemaster_id.toString()
      );
      if (
        articleData[0]?.alert_type === 1 ||
        articleData[0]?.alert_type === 2 ||
        articleData[0]?.alert_type === 3
      ) {
        return true;
      }

      return null;
    } catch (err) {
      console.log(err);
      return null;
    }
  }

  function getButtonType(article_id: string) {
    try {
      if (!article_id) return;
      if (!linkToDisplay) return;

      for (let obj of linkToDisplay) {
        if (obj?.article_id === article_id) {
          return obj?.button_to_display;
        }
      }
    } catch (err) {
      console.error(err);
    }
  }

  const handleDoiClick = (id: any) => {
    window.open(`/abstractFullScreen?id=${id}`);
  };

  const removeUrl = (datastring: string) => {
    const underlineregex = /<u>.*?<\/u>/g;
    const cleanedText = datastring.replace(underlineregex, "");
    return cleanedText;
  };

  function handleBoooks(data: any) {
    navigate(`/BookSearch?searchTerm=${data?.book_name}`);
  }

  async function handleDataset() {
    const res = await getDataSetDetails(dialogueData?.article_id);

    if (res?.total_count == 1) {
      window.open(res?.dataset_details[0]?.data_link);
    } else {
      setDatasetData(res?.dataset_details);
      setTabs("dataset");
    }
  }

  return (
    <>
      {!loading ? (
        <>
          <>
            <Box bgcolor={"#FFF"}>
              {/************************************************************************************************************************************* 
          dataTypes Tags and peer review
        *************************************************************************************************************************************/}
              <Box
                display={"flex"}
                flexDirection={"row"}
                justifyContent={"space-between"}
                sx={{ width: "95%", marginInline: "auto" }}
                pt={1}
              >
                <Box
                  display={"flex"}
                  flexDirection={"row"}
                  justifyContent={"space-between"}
                >
                  {/* DATATYPE TAG */}
                  {dialogueData?.data_type && !dialogueData?.sub_data_type && (
                    <Typography
                      // width={48}
                      fontFamily={"Inter"}
                      fontWeight="500"
                      fontSize={12}
                      sx={{
                        alignSelf: "center",

                        textAlign: "center",
                        borderRadius: 1,
                        backgroundColor: Colors.purple100,
                        margin: 1,
                        padding: "2px  10px",
                      }}
                    >
                      {dialogueData?.data_type === dataTypes.journals
                        ? dataTypes.journalArticle
                        : dialogueData?.data_type}
                    </Typography>
                  )}
                  {dialogueData?.sub_data_type && (
                    <Typography
                      // width={48}
                      fontFamily={"Inter"}
                      fontWeight="500"
                      fontSize={12}
                      sx={{
                        alignSelf: "center",

                        textAlign: "center",
                        borderRadius: 1,
                        backgroundColor: Colors.purple100,
                        margin: 1,
                        padding: "2px  10px",
                      }}
                    >
                      {dialogueData?.sub_data_type}
                    </Typography>
                  )}

                  {/* FULLTEXT TAG*/}
                  {dialogueData?.fulltext ? (
                    <Typography
                      // width={48}
                      fontFamily={"Inter"}
                      fontWeight="500"
                      fontSize={12}
                      sx={{
                        alignSelf: "center",

                        textAlign: "center",
                        borderRadius: 1,
                        backgroundColor: Colors.green100,
                        margin: 1,
                        padding: "2px  10px",
                      }}
                    >
                      Full Text
                    </Typography>
                  ) : null}

                  {/* PEER REVIEW */}
                  {dialogueData?.peer_reviewed ? (
                    <Tooltip title="Peer Reviewed">
                      <PeopleAltOutlinedIcon
                        sx={{
                          alignSelf: "center",
                          color: Colors.primary,
                        }}
                      ></PeopleAltOutlinedIcon>
                    </Tooltip>
                  ) : null}
                  {accessTypeButtons(dialogueData, customerId) !==
                    "GreenLock" &&
                    dialogueData?.has_preprint && (
                      <Tooltip title="View Pre-Print">
                        <Button
                          href={dialogueData?.preprint_link}
                          rel="noopener noreferrer"
                          target="_blank"
                          sx={{ minWidth: 0 }}
                        >
                          <img src={PreprintIcon} alt="PreprintIcon" />
                        </Button>
                      </Tooltip>
                    )}
                </Box>
                {!screen && (
                  <Box sx={{ display: "flex" }}>
                    {getButtonType(dialogueData?.article_id)?.includes(
                      "find_in_library"
                    ) &&
                      !dialogueData?.fulltext &&
                      (dialogueData?.data_type === dataTypes.journalArticle ||
                        dialogueData.data_type ===
                          dataTypes.conferenceProceedings ||
                        dialogueData.data_type === dataTypes.journals) &&
                      !dialogueData?.is_onos_resource && (
                        <Tooltip title="Find in Library">
                          <Button
                            sx={{ minWidth: 0 }}
                            onClick={async () => {
                              logAbstractUsageData(38, dialogueData);
                              navigate("/basicSearchScreen/findlibrary", {
                                state: { data: dialogueData },
                              });
                            }}
                          >
                            <img
                              src={FindInLibraryIcon}
                              alt="FindInLibraryIcon"
                            />
                          </Button>
                        </Tooltip>
                      )}
                    <>
                      {/* {dialogueData?.has_datasets ? (
                        <Tooltip title="Dataset">
                          <IconButton
                            sx={{ paddingRight: 2 }}
                            href={
                              dialogueData?.dataset_link &&
                              dialogueData?.dataset_link[0]
                            }
                            target="_blank"
                            rel="noopener noreferrer"
                            onClick={() => {
                              logAbstractUsageData(37, dialogueData);
                              alert("modal")
                            }}
                          >
                            <img src={DatasetIcon} alt="DatasetIcon" />
                          </IconButton>
                        </Tooltip>
                      ) : (
                        ""
                      )} */}
                      {dialogueData?.pmcid &&
                      !dialogueData?.is_onos_resource ? (
                        <Button
                          href={`https://www.ncbi.nlm.nih.gov/pmc/articles/${dialogueData?.pmcid}`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          PubMed Link
                        </Button>
                      ) : (
                        ""
                      )}
                      {isDSPACE ? (
                        <DownloadButton
                          link={dialogueData?.fulltext_url}
                          articleData={dialogueData}
                          resultFrom={"searchResult"}
                        />
                      ) : (
                        <AccessLock
                          data={dialogueData}
                          linkToDisplay={linkToDisplay}
                          resultFrom={resultFrom}
                        />
                      )}
                      <>
                        {!dialogueData?.fulltext &&
                          !dialogueData?.is_onos_resource &&
                          getButtonType(dialogueData?.article_id)?.includes(
                            "request_article"
                          ) && (
                            <Button
                              variant="outlined"
                              onClick={async () => {
                                navigate("/basicSearchScreen/DdrScreen", {
                                  state: {
                                    data: dialogueData,
                                    resultFrom: resultFrom,
                                  },
                                });
                              }}
                            >
                              Request Article
                            </Button>
                          )}
                      </>
                    </>
                  </Box>
                )}
              </Box>

              <Box
                sx={{
                  width: "95%",
                  marginInline: "auto",
                  marginTop: "5px",
                  paddingInline: "10px",
                  pb: 0.5,
                }}
              >
                {/*************************************************************************************************************************************
            Title Section
          *************************************************************************************************************************************/}
                <MathJax>
                  <div>
                    {dialogueData?.title &&
                    authorReducer.abstractAuthorStatus === false ? (
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "600",
                          fontSize: "1.2rem",
                          fontFamily: "Lora",
                          py: "10px",
                        }}
                      >
                        <div
                          id="title"
                          dangerouslySetInnerHTML={{
                            __html: fromJournalToc
                              ? keywordHighlight(
                                  tocGetDataList,
                                  searchTerm,
                                  dialogueData?.article_id,
                                  dialogueData?.title
                                )
                              : keywordHighlight(
                                  keywordData,
                                  searchTerm,
                                  dialogueData?.article_id,
                                  dialogueData?.title
                                ),
                          }}
                        />

                        {/* {dialogueData?.title}</div> */}
                      </Typography>
                    ) : null}
                    {authorReducer.abstractAuthorStatus === true ? (
                      <Typography
                        variant="body1"
                        sx={{
                          fontWeight: "600",
                          fontSize: "1.2rem",
                          fontFamily: "Lora",
                          py: "10px",
                        }}
                      >
                        <div id="title">{dialogueData?.title}</div>
                      </Typography>
                    ) : null}
                  </div>
                </MathJax>

                {/*************************************************************************************************************************************
            Author details section
          *************************************************************************************************************************************/}
                {dialogueData?.data_type == dataTypes.journalArticle ||
                dialogueData?.data_type == dataTypes.journals ||
                dialogueData?.data_type === dataTypes.bookSeries ||
                dialogueData?.data_type == dataTypes.dissertationThesis ||
                dialogueData?.data_type == dataTypes.preprint ||
                dialogueData?.data_type == dataTypes.conferenceProceedings ||
                dialogueData?.data_type == dataTypes.magazine ||
                dialogueData?.data_type == dataTypes.books ||
                dialogueData?.data_type == dataTypes.dataset ||
                dialogueData?.data_type == dataTypes.patents ||
                isDSPACE ||
                isOpac ||
                dialogueData?.data_type == dataTypes.audioVideo ? (
                  <div>
                    {(dialogueData?.author_details?.length ||
                      dialogueData?.authors_tk?.length) &&
                    isStringOfArray(
                      dialogueData?.data_type == dataTypes.books &&
                        !dialogueData?.has_chapter
                        ? dialogueData?.authors_tk
                        : dialogueData?.author_details
                    ) ? (
                      <Box
                        sx={{ display: "flex", marginTop: "5px" }}
                        flexWrap="wrap"
                      >
                        {sortAuthor(
                          dialogueData?.data_type == dataTypes.books &&
                            !dialogueData?.has_chapter
                            ? dialogueData?.authors_tk
                            : dialogueData?.author_details
                        )?.map((name: string, i) => {
                          return (
                            <Box sx={webStyle.flexBox}>
                              <Button
                                // @ts-ignore
                                sx={{
                                  "&:hover": {
                                    background: authorReducer.searchTerm
                                      ? !authorReducer.searchTerm?.includes(
                                          name
                                        )
                                        ? "transparent"
                                        : "#E4DCFE"
                                      : "transparent",
                                    textDecoration: !isDSPACE
                                      ? "underline"
                                      : "none",
                                  },

                                  background: authorReducer.searchTerm
                                    ? !authorReducer.searchTerm?.includes(name)
                                      ? ""
                                      : "#E4DCFE"
                                    : "",
                                  padding: 0,
                                  textDecoration: !isDSPACE
                                    ? "underline"
                                    : "none",
                                  minWidth: 0,
                                  paddingLeft: i === 0 ? 0 : 1,
                                  paddingRight: 1,
                                  color:
                                    fromJournalToc || isDSPACE ? "black" : "",
                                  cursor: !isDSPACE ? "pointer" : "default",
                                }}
                                onClick={() => {
                                  if (!fromJournalToc && !isDSPACE) {
                                    handleAuthor(name, null, dialogueData);
                                  }
                                }}
                              >
                                {/* {name.length ? name : ""}{" "} */}
                                {name.length
                                  ? toTitleCase(
                                      name
                                        .trim()
                                        .replace(/^\,+|\,+$/g, "")
                                        .trim()
                                    )
                                  : ""}{" "}
                              </Button>
                              <Divider
                                orientation="vertical"
                                style={{
                                  height: "60%",
                                  alignSelf: "center",
                                }}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    ) : null}

                    {(dialogueData?.author_details?.length ||
                      dialogueData?.authors_tk?.length) &&
                    !isStringOfArray(
                      dialogueData?.data_type == dataTypes.books &&
                        !dialogueData?.has_chapter
                        ? dialogueData?.authors_tk
                        : dialogueData?.author_details
                    ) ? (
                      <Box
                        sx={{ display: "flex", marginTop: "5px" }}
                        flexWrap="wrap"
                      >
                        {sortByAuthorRole(
                          sortAuthorDetail(
                            dialogueData?.data_type == dataTypes.books &&
                              !dialogueData?.has_chapter
                              ? dialogueData?.authors_tk
                              : dialogueData?.author_details
                          ),
                          dialogueData?.data_type ===
                            dataTypes.dissertationThesis
                        )?.map((obj: any, i) => {
                          const firstName = obj?.author_fname
                            ? obj?.author_fname
                            : "";
                          const lastName = obj?.author_lname
                            ? obj?.author_lname
                            : "";

                          const reversedName = lastName
                            ? lastName + ", " + firstName
                            : firstName;
                          const displayName = lastName
                            ? lastName.replace(/,/g, " ") +
                              ", " +
                              firstName.replace(/,/g, " ")
                            : firstName.replace(/,/g, " ");
                          return (
                            <Box sx={webStyle.flexBox}>
                              <Tooltip
                                title={authorRole[obj?.datauthorrole_id]}
                              >
                                <Button
                                  sx={{
                                    "&:hover": {
                                      background: authorReducer.searchTerm
                                        ? !authorReducer.searchTerm?.includes(
                                            reversedName
                                          )
                                          ? "transparent"
                                          : "#E4DCFE"
                                        : "transparent",
                                      textDecoration: "underline",
                                    },

                                    background: authorReducer.searchTerm
                                      ? !authorReducer.searchTerm?.includes(
                                          reversedName
                                        )
                                        ? ""
                                        : "#E4DCFE"
                                      : "",
                                    padding: 0,
                                    textDecoration: "underline",
                                    minWidth: 0,
                                    paddingLeft: i === 0 ? 0 : 1,
                                    paddingRight: 1,
                                    color: fromJournalToc ? "black" : "",
                                  }}
                                  onClick={() => {
                                    if (!fromJournalToc) {
                                      handleAuthor(
                                        reversedName,
                                        obj,
                                        dialogueData
                                      );
                                    }
                                  }}
                                >
                                  {displayName.length ? displayName : ""}{" "}
                                </Button>
                              </Tooltip>
                              {obj?.orc_id ? (
                                <a
                                  href={`https://orcid.org/${obj?.orc_id}`}
                                  target="_blank"
                                  rel="noreferrer"
                                >
                                  <img
                                    src={IdIcon}
                                    style={{
                                      paddingLeft: "5px",
                                      marginTop: "8px",
                                      paddingRight: "6px",
                                    }}
                                    alt="IdIcon"
                                  />
                                </a>
                              ) : null}
                              <Divider
                                orientation="vertical"
                                style={{
                                  height: "60%",
                                  alignSelf: "center",
                                }}
                              />
                            </Box>
                          );
                        })}
                      </Box>
                    ) : null}
                  </div>
                ) : null}
                {dialogueData?.has_chapter && (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      justifyContent: "space-between",
                    }}
                  >
                    {" "}
                    <Typography
                      component={"div"}
                      sx={{
                        textAlign: "left",
                        fontSize: "16px",
                        color: Colors.primary,
                        fontStyle: "italic",
                        cursor: "pointer",
                      }}
                      onClick={() => {
                        handleBoooks(dialogueData);
                      }}
                    >
                      {dialogueData?.book_name}
                    </Typography>
                  </Box>
                )}
                {/*************************************************************************************************************************************
            Journal Name, date, month, year
          *************************************************************************************************************************************/}
                {dialogueData?.data_type == dataTypes.journalArticle ||
                dialogueData?.data_type == dataTypes.journals ||
                dialogueData?.data_type === dataTypes.bookSeries ||
                dialogueData?.data_type == dataTypes.dissertationThesis ||
                dialogueData?.data_type == dataTypes.preprint ||
                dialogueData?.data_type == dataTypes.conferenceProceedings ||
                dialogueData?.data_type == dataTypes.audioVideo ||
                dialogueData?.data_type === dataTypes.magazine ||
                dialogueData?.data_type === dataTypes.books ||
                dialogueData?.data_type === dataTypes.dataset ||
                dialogueData?.data_type == dataTypes.patents ||
                isDSPACE ||
                isOpac ? (
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: 1,
                      my: "10px",
                      justifyContent: "space-between",
                    }}
                  >
                    <Box display={"flex"} alignItems={"center"} gap={1}>
                      {!fromJournalToc ? (
                        dialogueData?.data_type ===
                          dataTypes.dissertationThesis ||
                        dialogueData?.data_type === dataTypes.audioVideo ||
                        dialogueData?.data_type === dataTypes.preprint ||
                        dialogueData?.data_type === dataTypes.magazine ||
                        dialogueData?.data_type == dataTypes.dataset ||
                        dialogueData?.data_type == dataTypes.patents ||
                        dialogueData?.data_type ===
                          dataTypes.conferenceProceedings ||
                        dialogueData?.data_type === dataTypes.books ||
                        dialogueData?.data_type == dataTypes.dspaceThesis ||
                        dialogueData?.data_type ==
                          dataTypes.dspaceBookChapter ||
                        dialogueData?.data_type == dataTypes.dspaceBook ||
                        dialogueData?.data_type ==
                          dataTypes.dspaceDataTypeNull ? null : (
                          <Tooltip
                            // title="Set Alert"
                            title={
                              findAlertTypeSetting(
                                dialogueData?.resourcemaster_id
                              )
                                ? "Modify Alert"
                                : "Set Alert"
                            }
                            onClick={() => {
                              if (profileStatus === "true") {
                                handleOpenViewModal(dialogueData);
                              } else {
                                showIsProfileToast(
                                  "Please login as profile user to set alert"
                                );
                              }
                            }}
                          >
                            <IconButton
                              sx={{
                                "&:hover": {
                                  color: "#F48120",
                                },
                                color: findAlertTypeSetting(
                                  dialogueData?.resourcemaster_id
                                )
                                  ? "#F48120"
                                  : "",
                                padding: 0,
                              }}
                            >
                              <RssFeedIcon fontSize="small"></RssFeedIcon>
                            </IconButton>
                          </Tooltip>
                        )
                      ) : null}

                      <Typography
                        // @ts-ignore
                        sx={{
                          textAlign: "left",
                          fontSize: "0.875rem",
                          color: !fromJournalToc && !isDSPACE && Colors.primary,
                          fontStyle: "italic",
                          cursor: !isDSPACE ? "pointer" : "default",
                        }}
                        // onClick={() => handleResource(dialogueData)}
                        onClick={() => {
                          if (!fromJournalToc && !isDSPACE) {
                            handleResource(dialogueData, false);
                          }
                        }}
                      >
                        {metaData(dialogueData)}
                      </Typography>
                    </Box>
                    {screen && (
                      <>
                        <Box sx={{ display: "flex" }}>
                          {getButtonType(dialogueData?.article_id)?.includes(
                            "find_in_library"
                          ) &&
                            !dialogueData?.fulltext &&
                            (dialogueData?.data_type ===
                              dataTypes.journalArticle ||
                              dialogueData.data_type ===
                                dataTypes.conferenceProceedings ||
                              isDSPACE ||
                              dialogueData.data_type === dataTypes.journals) &&
                            !dialogueData?.is_onos_resource && (
                              <Tooltip title="Find in Library">
                                <Button
                                  sx={{ minWidth: 0 }}
                                  onClick={async () => {
                                    logAbstractUsageData(38, dialogueData);
                                    navigate("/basicSearchScreen/findlibrary", {
                                      state: { data: dialogueData },
                                    });
                                  }}
                                >
                                  <img
                                    src={FindInLibraryIcon}
                                    alt="FindInLibraryIcon"
                                  />
                                </Button>
                              </Tooltip>
                            )}
                          {/* {dialogueData?.has_datasets ? (
                            <Tooltip title="Dataset">
                              <IconButton
                                sx={{ paddingRight: 1 }}
                                href={
                                  dialogueData?.dataset_link &&
                                  dialogueData?.dataset_link[0]
                                }
                                target="_blank"
                                rel="noopener noreferrer"
                                onClick={() => {
                                  logAbstractUsageData(37, dialogueData);
                                  alert("abstract screen")
                                }}
                              >
                                <img src={DatasetIcon} alt="DatasetIcon" />
                              </IconButton>
                            </Tooltip>
                          ) : (
                            ""
                          )} */}

                          {dialogueData?.pmcid &&
                          !dialogueData?.is_onos_resource ? (
                            <Button
                              href={`https://www.ncbi.nlm.nih.gov/pmc/articles/${dialogueData?.pmcid}`}
                              target="_blank"
                              rel="noopener noreferrer"
                            >
                              PubMed Link
                            </Button>
                          ) : (
                            ""
                          )}
                          {isDSPACE ? (
                            <DownloadButton
                              link={dialogueData?.fulltext_url}
                              articleData={dialogueData}
                              resultFrom={"searchResult"}
                            />
                          ) : (
                            <AccessLock
                              data={dialogueData}
                              linkToDisplay={linkToDisplay}
                              resultFrom={resultFrom}
                            />
                          )}
                          <>
                            {!dialogueData?.fulltext &&
                              !dialogueData?.is_onos_resource &&
                              screen &&
                              getButtonType(dialogueData?.article_id)?.includes(
                                "request_article"
                              ) && (
                                <Button
                                  variant="outlined"
                                  onClick={async () => {
                                    navigate("/basicSearchScreen/DdrScreen", {
                                      state: {
                                        data: dialogueData,
                                        resultFrom: resultFrom,
                                      },
                                    });
                                  }}
                                >
                                  Request Article
                                </Button>
                              )}
                          </>
                        </Box>
                      </>
                    )}
                  </Box>
                ) : null}

                {/*************************************************************************************************************************************
            SJR & H-Index Section
          *************************************************************************************************************************************/}
                {dialogueData?.data_type == dataTypes.journalArticle ||
                dialogueData?.data_type == dataTypes.journals ||
                isOpac ? (
                  <div>
                    {dialogueData?.sjr_score || dialogueData ? (
                      <Box
                        sx={{
                          display: "flex",
                          flexDirection: "row",
                          // marginTop: "1.5%",
                          justifyContent: "space-between",
                          alignItems: "center",
                        }}
                      >
                        <Box sx={{ display: "flex" }}>
                          <Typography
                            variant="body2"
                            sx={{
                              fontWeight: 700,
                              fontFamily: "Helvetica Neue",
                              marginRight: "0.5rem",
                            }}
                          >
                            {dialogueData.sjr_score && "Journal Ranking:"}
                          </Typography>

                          {dialogueData.sjr_score ? (
                            <>
                              <Typography variant="body2">
                                {/* {dialogueData.filter_metrix[0]}: */}
                                SJR :
                              </Typography>{" "}
                              <Typography
                                variant="body2"
                                style={{
                                  marginRight: "0.5rem",
                                  color: Colors.primary,
                                  cursor: "pointer",
                                }}
                                onClick={() => setIsOpen(true)}
                              >
                                {dialogueData.sjr_score}
                              </Typography>
                            </>
                          ) : null}
                          {dialogueData.sjr_hindex ? (
                            <>
                              <Typography variant="body2">H-Index :</Typography>
                              <Typography
                                variant="body2"
                                sx={{ color: Colors.gray_700, mr: ".5rem" }}
                              >
                                {dialogueData.sjr_hindex}
                              </Typography>
                            </>
                          ) : null}

                          {consortiamaster_id === 1874 &&
                          dialogueData.naas_value ? (
                            <>
                              <Typography variant="body2">NAAS :</Typography>
                              <Typography
                                variant="body2"
                                sx={{ color: Colors.gray_700 }}
                              >
                                {dialogueData.naas_value}
                              </Typography>
                            </>
                          ) : null}
                          {
                            <Box sx={{ display: "flex", marginLeft: "10px" }}>
                              {dialogueData?.is_retracted ? (
                                <Tooltip title="Retracted Article">
                                  <img
                                    src={RetractedIcon}
                                    alt="RetractedIcon"
                                  />
                                </Tooltip>
                              ) : (
                                ""
                              )}
                            </Box>
                          }
                        </Box>
                      </Box>
                    ) : null}
                  </div>
                ) : null}

                {dialogueData?.data_type == dataTypes.patents ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <>
                      {" "}
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Typography variant="body2">
                          Application No. :
                        </Typography>
                        <Typography
                          variant="body2"
                          style={{
                            marginRight: "0.5rem",
                            color: Colors.primary,
                          }}
                        >
                          {dialogueData.application_no}
                        </Typography>
                      </Box>
                    </>
                  </Box>
                ) : null}
                {dialogueData?.data_type == dataTypes.patents ? (
                  <>
                    {dialogueData?.applicant_details?.map((item: any) => {
                      return (
                        <>
                          <Box sx={{ display: "flex", gap: 2 }}>
                            <Typography variant="body2">
                              Applicant Name :
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{
                                marginRight: "0.5rem",
                                color: Colors.primary,
                              }}
                            >
                              {JSON.parse(item)?.applicant_name}
                            </Typography>

                            <Typography variant="body2">
                              Applicant Affiliation :
                            </Typography>
                            <Typography
                              variant="body2"
                              style={{
                                marginRight: "0.5rem",
                                color: Colors.primary,
                              }}
                            >
                              {JSON.parse(item)?.author_affiliation}
                            </Typography>
                          </Box>
                        </>
                      );
                    })}
                  </>
                ) : null}

                {dialogueData?.data_type == dataTypes.patents ? (
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                      justifyContent: "space-between",
                      alignItems: "center",
                    }}
                  >
                    <>
                      {" "}
                      <Box sx={{ display: "flex", gap: 2 }}>
                        <Typography variant="body2">Country :</Typography>
                        <Typography
                          variant="body2"
                          style={{
                            marginRight: "0.5rem",
                            color: Colors.primary,
                          }}
                        >
                          {dialogueData.primary_publisher_country}
                        </Typography>
                      </Box>
                    </>
                  </Box>
                ) : null}
              </Box>
            </Box>

            <SJRModal
              isOpen={isOpen}
              handleClose={closeSjrModal}
              sjrId={dialogueData?.sjr_id}
            />
            {screen ? (
              <Box
                bgcolor={"#F9F8F6"}
                sx={{ paddingInline: "10px", paddingBottom: "3rem" }}
              >
                {/*************************************************************************************************************************************
          Conference proceedings Section
        *************************************************************************************************************************************/}
                {dialogueData?.data_type === dataTypes.conferenceProceedings &&
                dialogueData?.issue_title ? (
                  <Box
                    sx={[
                      screen
                        ? webStyle.fullScreenStylesflex
                        : webStyle.subContainer,
                    ]}
                  >
                    <Typography
                      variant="body2"
                      sx={screen ? webStyle.titleText2 : webStyle.titleText}
                    >
                      Proceedings:
                    </Typography>
                    <Typography
                      variant="body2"
                      style={
                        screen
                          ? webStyle.informationText2
                          : webStyle.informationText
                      }
                    >
                      {dialogueData?.issue_title}
                    </Typography>
                  </Box>
                ) : null}
                {/*************************************************************************************************************************************
          Citation Section
        *************************************************************************************************************************************/}
                {
                  // tabs==="references" && (
                  dialogueData?.data_type == dataTypes.journalArticle ||
                  dialogueData?.data_type == dataTypes.journals ||
                  isOpac ? (
                    <div>
                      {showDoi && dialogueData?.articledoi ? (
                        <Box
                          sx={
                            screen
                              ? webStyle.fullScreenStylesflex
                              : webStyle.subContainer
                          }
                        >
                          <Typography
                            variant="body2"
                            sx={
                              screen ? webStyle.titleText2 : webStyle.titleText
                            }
                          >
                            Citation:
                          </Typography>
                          <div
                            className="scite-badge"
                            data-doi={dialogueData?.articledoi}
                            data-layout="horizontal"
                            data-show-zero="false"
                          ></div>
                        </Box>
                      ) : null}
                    </div>
                  ) : null
                  // )
                }
                {/*************************************************************************************************************************************
          Keywords Section
        *************************************************************************************************************************************/}

                {keywords?.length ? (
                  <Box
                    sx={[
                      screen
                        ? webStyle.fullScreenStylesflex
                        : webStyle.subContainer,
                    ]}
                  >
                    <Typography
                      variant="body2"
                      sx={screen ? webStyle.titleText2 : webStyle.titleText}
                    >
                      Keywords:
                    </Typography>

                    <Box
                      sx={
                        screen
                          ? { display: "flex", flexDirection: "row" }
                          : webStyle.subContainer
                      }
                      flexWrap="wrap"
                    >
                      {dialogueData?.keywords?.map((val: string, i: number) => {
                        return (
                          <Box sx={webStyle.flexBox}>
                            <Button
                              sx={{
                                fontFamily: "Helvetica Neue",
                                paddingTop: 0,
                                paddingBottom: 0,
                                display: "flex",
                                flexDirection: "row",
                                textTransform: "capitalize",
                                padding:
                                  i === 0 ? "0px 8px 0px 0px" : "0px 8px",
                                ":hover": { textDecoration: "underline" },
                                color: fromJournalToc ? "black" : "",
                              }}
                              onClick={() => {
                                if (!fromJournalToc) {
                                  handleKeyword(val, null, dialogueData);
                                }
                              }}
                            >
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: fromJournalToc
                                    ? highlightKeys(val, tocSearchTerm)
                                    : highlightKeys(val, searchTerm),
                                }}
                              />
                            </Button>
                            <Divider
                              orientation="vertical"
                              style={{
                                height: "50%",
                                alignSelf: "center",
                              }}
                            />
                          </Box>
                        );
                      })}
                    </Box>
                  </Box>
                ) : null}
                {/* Tabs Sections*/}
                {
                  <Box
                    sx={{
                      ...(screen
                        ? webStyle.fullScreenStylesflex
                        : webStyle.subContainer),
                      gap: 3,
                      display: "flex",
                      paddingTop: 5,
                    }}
                  >
                    <Button
                      variant={tabs === "abstract" ? "contained" : "outlined"}
                      onClick={() => {
                        setTabs("abstract");
                      }}
                    >
                      {dialogueData?.data_type == dataTypes.books &&
                      !dialogueData?.has_chapter
                        ? "About"
                        : "Abstract"}{" "}
                    </Button>
                    {(dialogueData?.author_details ||
                      authorEmailData?.length !== 0) && (
                      <Button
                        variant={tabs === "author" ? "contained" : "outlined"}
                        disabled={showAuthorAffiliation?.length === 0}
                        onClick={() => {
                          setTabs("author");
                        }}
                      >
                        Author Affiliations
                      </Button>
                    )}
                    <Button
                      variant={tabs === "references" ? "contained" : "outlined"}
                      onClick={() => {
                        setTabs("references");
                      }}
                      disabled={
                        !loading1 &&
                        (refrences?.data[0]?.reference_count === 0 ||
                          refrences?.data[0]?.reference_count === undefined ||
                          refrences?.data[0]?.reference_count === null)
                      }
                    >
                      References{" "}
                      {!loading1 &&
                        refrences?.data[0]?.reference_count &&
                        `(${refrences?.data[0]?.reference_count})`}
                      {loading1 && <CircularProgress size="1rem" />}
                    </Button>
                    <Button
                      variant={tabs === "cite" ? "contained" : "outlined"}
                      onClick={() => {
                        setTabs("cite");
                      }}
                      disabled={
                        !loading1 &&
                        (parseInt(citeDetails?.data[0]?.cited_by_count) === 0 ||
                          citeDetails?.data[0]?.cited_by_count === undefined ||
                          citeDetails?.data[0]?.cited_by_count === null)
                      }
                    >
                      Cited By{" "}
                      {!loading1 &&
                        citeDetails?.data[0]?.cited_by_count &&
                        `(${citeDetails?.data[0]?.cited_by_count})`}
                      {loading1 && <CircularProgress size="1rem" />}
                    </Button>
                    {dialogueData?.has_datasets && (
                      <Button
                        variant={tabs === "dataset" ? "contained" : "outlined"}
                        startIcon={
                          <img
                            src={
                              tabs === "dataset"
                                ? DatasetWhiteIcon
                                : DatasetPurpleIcon
                            }
                            alt="DatasetIcon"
                          />
                        }
                        onClick={handleDataset}
                      >
                        Dataset
                      </Button>
                    )}
                  </Box>
                }
                {/*************************************************************************************************************************************
          Abstract Section
        *************************************************************************************************************************************/}
                {tabs === "abstract" &&
                  (dialogueData?.abstract ? (
                    <Box
                      sx={[
                        screen
                          ? webStyle.fullScreenStylesflex
                          : webStyle.subContainer,
                      ]}
                    >
                      <MathJax>
                        <Typography
                          variant="body2"
                          style={
                            screen
                              ? webStyle.informationText2
                              : webStyle.informationText
                          }
                        >
                          <div
                            dangerouslySetInnerHTML={{
                              __html: fromJournalToc
                                ? highlightAbstract(
                                    tocGetDataList,
                                    tocSearchTerm,
                                    dialogueData?.article_id,
                                    dialogueData?.abstract
                                  )
                                : highlightAbstract(
                                    keywordData,
                                    searchTerm,
                                    dialogueData?.article_id,
                                    dialogueData?.abstract
                                  ),
                            }}
                          />
                        </Typography>
                      </MathJax>
                    </Box>
                  ) : (
                    <div>
                      {abstractUrl != "" && abstractUrl != null ? (
                        <Box
                          sx={
                            screen
                              ? webStyle.fullScreenStylesflex
                              : webStyle.subContainer
                          }
                        >
                          <Typography
                            variant="body2"
                            sx={
                              screen ? webStyle.titleText2 : webStyle.titleText
                            }
                          ></Typography>
                          <Typography
                            variant="body2"
                            style={webStyle.informationText}
                          >
                            <a
                              target="_blank"
                              style={{ color: Colors.primary }}
                              href={`${abstractUrl}`}
                              rel="noreferrer"
                            >
                              {abstractUrl}
                            </a>
                          </Typography>
                        </Box>
                      ) : // for DF - 1342
                      // <Typography
                      //   style={{ textAlign: "center", marginTop: 20 }}
                      // >
                      //   Abstract not available
                      // </Typography>
                      null}
                    </div>
                  ))}

                {/*************************************************************************************************************************************
          DOI Section
        *************************************************************************************************************************************/}
                {tabs === "abstract" &&
                  (dialogueData?.data_type == dataTypes.journalArticle ||
                  dialogueData?.data_type == dataTypes.journals ||
                  dialogueData?.data_type == dataTypes.magazine ||
                  dialogueData?.data_type == dataTypes.dissertationThesis ||
                  dialogueData?.data_type == dataTypes.preprint ||
                  dialogueData?.data_type == dataTypes.conferenceProceedings ||
                  dialogueData?.data_type == dataTypes.audioVideo ||
                  dialogueData?.data_type == dataTypes.dataset ||
                  dialogueData?.data_type == dataTypes.books ||
                  isOpac ? (
                    <div>
                      {dialogueData?.articledoi && !dialogueData?.bookdoi ? (
                        <Box
                          sx={
                            screen
                              ? webStyle.fullScreenStylesflex
                              : webStyle.subContainer
                          }
                        >
                          <Typography
                            variant="body2"
                            sx={
                              screen ? webStyle.titleText2 : webStyle.titleText
                            }
                          >
                            DOI:
                          </Typography>
                          <Typography
                            variant="body2"
                            style={webStyle.informationText}
                          >
                            <a
                              target="_blank"
                              style={{ color: Colors.primary }}
                              href={`https://doi.org/${dialogueData?.articledoi}`}
                              rel="noreferrer"
                            >
                              https://doi.org/{dialogueData?.articledoi}
                            </a>
                          </Typography>
                        </Box>
                      ) : null}
                      {dialogueData?.bookdoi ? (
                        <Box
                          sx={
                            screen
                              ? webStyle.fullScreenStylesflex
                              : webStyle.subContainer
                          }
                        >
                          <Typography
                            variant="body2"
                            sx={
                              screen ? webStyle.titleText2 : webStyle.titleText
                            }
                          >
                            Doi:
                          </Typography>
                          <Typography
                            variant="body2"
                            style={webStyle.informationText}
                          >
                            <a
                              target="_blank"
                              style={{ color: Colors.primary }}
                              href={`https://dx.doi.org/${dialogueData?.bookdoi}`}
                              rel="noreferrer"
                            >
                              https://dx.doi.org/{dialogueData?.bookdoi}
                            </a>
                          </Typography>
                        </Box>
                      ) : null}
                    </div>
                  ) : null)}

                {abstractTop ? (
                  <AbstractDialog
                    handlePopUp={handlePopUp}
                    state={abstractTop}
                    dialogueData={dialogueData}
                    screen={screen}
                  ></AbstractDialog>
                ) : null}

                {/*************************************************************************************************************************************
          Author Affiliation Section
        *************************************************************************************************************************************/}
                {tabs === "author" &&
                  (dialogueData?.data_type == dataTypes.journalArticle ||
                  dialogueData?.data_type == dataTypes.journals ||
                  dialogueData?.data_type == dataTypes.audioVideo ||
                  dialogueData?.data_type == dataTypes.preprint ||
                  dialogueData?.data_type == dataTypes.conferenceProceedings ||
                  dialogueData?.data_type == dataTypes.magazine ||
                  dialogueData?.data_type == dataTypes.bookSeries ||
                  dialogueData?.data_type == dataTypes.dissertationThesis ||
                  dialogueData?.data_type == dataTypes.books ||
                  isOpac ? (
                    <div>
                      {showAuthorAffiliation?.length ? (
                        <Box
                          sx={
                            screen
                              ? webStyle.fullScreenStylesflex
                              : webStyle.subContainer
                          }
                        >
                          <Typography
                            variant="body2"
                            sx={
                              screen ? webStyle.titleText2 : webStyle.titleText
                            }
                            pt="1.2rem"
                          >
                            Author Affiliations:
                          </Typography>
                          <Box
                            sx={screen ? { width: "100%" } : { width: "70%" }}
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                                size="medium"
                              >
                                <TableHead>
                                  <TableRow sx={{ backgroundColor: "#F3F4F6" }}>
                                    <TableCell>Author Name</TableCell>
                                    <TableCell>Affliliation</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {isStringOfArray(
                                    dialogueData?.author_details
                                  ) ? (
                                    sortAuthorAffliliation(
                                      dialogueData?.author_details
                                    )?.map((row: string, i: number) => (
                                      <>
                                        {row.split("$")[1] && (
                                          <TableRow
                                            key={row}
                                            sx={{
                                              "&:last-child td, &:last-child th":
                                                {
                                                  border: 0,
                                                },
                                            }}
                                          >
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {row.split("$")[0]}
                                            </TableCell>
                                            <TableCell
                                              component="th"
                                              scope="row"
                                            >
                                              {removeSpecialCharacters(
                                                row.split("$")[1]
                                              )}
                                            </TableCell>
                                          </TableRow>
                                        )}
                                      </>
                                    ))
                                  ) : (
                                    <>
                                      {Array.isArray(authorAffiliation) &&
                                        authorAffiliation?.map(
                                          (obj: any, i: number) => (
                                            <TableRow
                                              key={i}
                                              sx={{
                                                "&:last-child td, &:last-child th":
                                                  {
                                                    border: 0,
                                                  },
                                              }}
                                            >
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {obj?.author_fname +
                                                  " " +
                                                  obj?.author_lname}
                                              </TableCell>
                                              <TableCell
                                                component="th"
                                                scope="row"
                                              >
                                                {removeSpecialCharacters(
                                                  obj?.author_affiliation
                                                )}
                                                {obj.author_country_name
                                                  ? ", " +
                                                    obj?.author_country_name
                                                  : ""}
                                              </TableCell>
                                            </TableRow>
                                          )
                                        )}
                                    </>
                                  )}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Box>
                      ) : null}
                    </div>
                  ) : null)}

                {/**==================== Author Email =============================*/}

                {tabs === "author" &&
                  (dialogueData?.data_type == dataTypes.journalArticle ||
                  dialogueData?.data_type == dataTypes.journals ||
                  dialogueData?.data_type == dataTypes.audioVideo ||
                  dialogueData?.data_type == dataTypes.preprint ||
                  dialogueData?.data_type == dataTypes.conferenceProceedings ||
                  dialogueData?.data_type == dataTypes.magazine ||
                  dialogueData?.data_type == dataTypes.bookSeries ||
                  dialogueData?.data_type == dataTypes.dissertationThesis ||
                  dialogueData?.data_type == dataTypes.dataset ||
                  dialogueData?.data_type == dataTypes.books ||
                  isOpac ? (
                    <div>
                      {authorEmailData?.length ? (
                        <Box
                          sx={
                            screen
                              ? webStyle.fullScreenStylesflex
                              : webStyle.subContainer
                          }
                        >
                          <Typography
                            variant="body2"
                            sx={
                              screen ? webStyle.titleText2 : webStyle.titleText
                            }
                          >
                            Correspondence Email:
                          </Typography>
                          <Typography
                            variant="body2"
                            style={webStyle.informationText}
                          >
                            {authorEmail_data}
                          </Typography>
                        </Box>
                      ) : null}
                    </div>
                  ) : null)}
                {/**==================== Refrences =============================*/}
                {tabs === "references" &&
                  (dialogueData?.data_type == dataTypes.journalArticle ||
                  dialogueData?.data_type == dataTypes.journals ||
                  dialogueData?.data_type == dataTypes.magazine ||
                  dialogueData?.data_type == dataTypes.dissertationThesis ||
                  dialogueData?.data_type == dataTypes.preprint ||
                  dialogueData?.data_type == dataTypes.conferenceProceedings ||
                  dialogueData?.data_type == dataTypes.audioVideo ||
                  dialogueData?.data_type == dataTypes.books ||
                  isOpac ? (
                    <div>
                      {dialogueData?.articledoi || dialogueData?.bookdoi ? (
                        <Box
                          sx={
                            screen
                              ? webStyle.fullScreenStylesflex
                              : webStyle.subContainer
                          }
                        >
                          <Box>Refrences : </Box>
                          <Box
                            sx={{
                              paddingLeft: 2,
                              paddingTop: 2,
                              display: "flex",
                              gap: 1,
                              flexDirection: "column",
                              width: "100%",
                            }}
                          >
                            {refrences?.data[0]?.reference?.map(
                              (item: any, index: any) => {
                                return (
                                  <Box>
                                    <Typography variant="body2" key={item?.key}>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            `[${index + 1}]. ` +
                                            removeUrl(item?.refernce_data),
                                        }}
                                      ></div>
                                      {item?.jgate_article_id && (
                                        <div
                                          style={{
                                            textDecoration: "underline",
                                            color: "darkblue",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            if (item?.jgate_article_id)
                                              handleDoiClick(
                                                item?.jgate_article_id
                                              );
                                          }}
                                        >
                                          {item?.article_url}
                                        </div>
                                      )}
                                    </Typography>
                                  </Box>
                                );
                              }
                            )}
                            {refrences?.data[0]?.reference_count > 50 && (
                              <Typography style={{ color: "green" }}>
                                Note : Please access the Full Text of the
                                article for the Complete Cited Reference List
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      ) : null}
                    </div>
                  ) : null)}

                {/**==================== Citations =============================*/}
                {tabs === "cite" &&
                  (dialogueData?.data_type == dataTypes.journalArticle ||
                  dialogueData?.data_type == dataTypes.journals ||
                  dialogueData?.data_type == dataTypes.magazine ||
                  dialogueData?.data_type == dataTypes.dissertationThesis ||
                  dialogueData?.data_type == dataTypes.preprint ||
                  dialogueData?.data_type == dataTypes.conferenceProceedings ||
                  dialogueData?.data_type == dataTypes.audioVideo ||
                  dialogueData?.data_type == dataTypes.books ||
                  isOpac ? (
                    <div>
                      {dialogueData?.articledoi || dialogueData?.bookdoi ? (
                        <Box
                          sx={
                            screen
                              ? webStyle.fullScreenStylesflex
                              : webStyle.subContainer
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              justifyContent: "space-between",
                            }}
                          >
                            Cited By :{" "}
                            <Button
                              onClick={() => {
                                DownloadFromApi(
                                  refrences?.data[0]?.openalex_id,
                                  refrences?.data[0]?.cited_by_count
                                );
                              }}
                            >
                              Download CSV
                            </Button>
                          </Box>
                          <Box
                            sx={{
                              paddingLeft: 2,
                              paddingTop: 1,
                              display: "flex",
                              gap: 1,
                              flexDirection: "column",
                            }}
                          >
                            {citeDetails?.data[0]?.cited_records?.map(
                              (item: any, index: any) => {
                                return (
                                  <Box>
                                    <Typography
                                      variant="body2"
                                      sx={{
                                        cursor: item?.jgate_article_id
                                          ? "pointer"
                                          : "default",
                                      }}
                                      key={item?.key}
                                      onClick={() => {
                                        if (item?.jgate_article_id)
                                          handleDoiClick(
                                            item?.jgate_article_id
                                          );
                                      }}
                                    >
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            `[${index + 1}]. ` +
                                            removeUrl(item?.refernce_data),
                                        }}
                                      ></div>
                                      {item?.jgate_article_id && (
                                        <div
                                          style={{
                                            textDecoration: "underline",
                                            color: "darkblue",
                                            cursor: "pointer",
                                          }}
                                          onClick={() => {
                                            if (item?.jgate_article_id)
                                              handleDoiClick(
                                                item?.jgate_article_id
                                              );
                                          }}
                                        >
                                          {item?.article_url}
                                        </div>
                                      )}
                                    </Typography>
                                  </Box>
                                );
                              }
                            )}
                            {citeDetails?.data[0]?.cited_by_count > 50 && (
                              <Typography style={{ color: "green" }}>
                                Note : Please download to view complete list
                              </Typography>
                            )}
                          </Box>
                        </Box>
                      ) : null}
                    </div>
                  ) : null)}

                {/*************************************************************************************************************************************
          Dataset links Section
        *************************************************************************************************************************************/}
                {tabs === "dataset" &&
                  (dialogueData?.has_datasets || isOpac ? (
                    <div>
                      {datasetData?.length ? (
                        <Box
                          sx={
                            screen
                              ? webStyle.fullScreenStylesflex
                              : webStyle.subContainer
                          }
                        >
                          <Box
                            sx={{
                              display: "flex",
                              minWidth: "150px",
                              alignContent: "center",
                              justifyContent: "space-between",
                            }}
                          >
                            {" "}
                            <Typography
                              variant="body2"
                              sx={
                                screen
                                  ? webStyle.titleText2
                                  : webStyle.titleText
                              }
                              pt="1.2rem"
                            >
                              Dataset Links:
                            </Typography>
                            <Button
                              variant="outlined"
                              size="small"
                              sx={{ cursor: "pointer" }}
                              onClick={() => {
                                downloadDatasetApi(dialogueData?.article_id);
                              }}
                            >
                              Export
                            </Button>
                          </Box>

                          <Box
                            sx={
                              screen
                                ? { width: "100%", paddingTop: "1rem" }
                                : { width: "70%", paddingtop: "1rem" }
                            }
                          >
                            <TableContainer component={Paper}>
                              <Table
                                sx={{ minWidth: 650 }}
                                aria-label="simple table"
                                size="medium"
                              >
                                <TableHead>
                                  <TableRow sx={{ backgroundColor: "#F3F4F6" }}>
                                    <TableCell>Dataset Title</TableCell>
                                    <TableCell>Dataset Link</TableCell>
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {datasetData?.map((item: any) => {
                                    return (
                                      <TableRow>
                                        <TableCell>
                                          {item?.dataset_title}
                                        </TableCell>
                                        <TableCell>
                                          <a
                                            target="_blank"
                                            style={{ color: Colors.primary }}
                                            href={item?.data_link}
                                            rel="noreferrer"
                                          >
                                            {item?.data_link}
                                          </a>
                                        </TableCell>
                                      </TableRow>
                                    );
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Box>
                        </Box>
                      ) : null}
                    </div>
                  ) : null)}
              </Box>
            ) : (
              // Component only render in when abstract open in Dialog
              <AbstractModal
                dialogueData={dialogueData && dialogueData}
                handleKeyword={handleKeyword}
                searchTerm={searchTerm}
                keywords={keywords}
                keywordData={keywordData}
                isOpac={isOpac}
                showDoi={showDoi}
                showAuthorAffiliation={showAuthorAffiliation}
                isStringOfArray={isStringOfArray}
                sortAuthorAffliliation={sortAuthorAffliliation}
                authorAffiliation={authorAffiliation}
                showAbstract={showAbstract}
                location={location}
                tocSearchTerm={tocSearchTerm}
                tocGetDataList={tocGetDataList}
                abstractUrl={abstractUrl}
                fromJournalToc={fromJournalToc}
                authorEmailData={authorEmailData}
                authorEmail_data={authorEmail_data}
                refrences={refrences}
                citeDetails={citeDetails}
                loading1={loading1}
              />
            )}
            <RSSAlertSearch
              openViewModal={openRSSViewModal}
              handleCloseViewModal={handleCloseViewModal}
              maxWidth={false}
              resourceData={rssdata}
              resourcetype={rssdata}
            />
            <Spinner isLoading={isLoading} />
          </>
        </>
      ) : (
        <CircularProgress
          sx={{
            width: "70px",
            height: "70px",
            alignSelf: "center",
            marginTop: 5,
          }}
        />
      )}
    </>
  );
};

export default AbstractView;

const webStyle = {
  flexBox: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    //  backgroundColor:"red"
  },
  leftBox: {
    width: "90%",
    backgroundColor: Colors.white,
    borderWidth: 1,
    borderColor: Colors.gray_1000,
    borderRadius: 2,
    // marginTop: "2%",
    paddingLeft: "3%",
  },
  titleText: {
    width: "20%",
    fontFamily: "Helvetica Neue",
    fontWeight: "700",
    marginLeft: "3%",
  },
  informationText: {
    width: "70%",
  },
  subContainer: {
    display: "flex",
    flexDirection: "row",
    margin: " 0.2rem 0 0.3rem 0",
  },

  fullScreenStylesflex: {
    paddingTop: "1%",
    width: "95%",
    marginInline: "auto",
    // display: "flex",
    // marginBottom: 8,
  },
  titleText2: {
    // width: "20%",
    fontFamily: "Helvetica Neue",
    fontWeight: "700",
    // marginTop: "1.2%",
    // marginBottom: "1.2%",
    marginBlock: "5px",
  },
  informationText2: {
    // width: "90%",
  },
};
