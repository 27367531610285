import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import MoreVert from "@mui/icons-material/MoreVert";
import RSSIcon from "@mui/icons-material/RssFeed";
import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Paper,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from "@mui/material";
import dayjs from "dayjs";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { checkMultipleArticleExistence } from "../../api/MyLibrary/MyFolders.api";
import { getAlertSettings } from "../../api/MyLibrary/TOCAlerts.api";
import {
  authorJsonFacetQuery,
  basicSearchForCounts,
  basicSearchMain,
  basicSearchQuery,
  jsonFacetQuery,
  subjectJsonFacetQuery,
} from "../../api/Search/BasicSearch.api";
import {
  getPersonalLibraryCollection,
  personalCoreCount,
} from "../../api/Search/CollectionApi";
import { setSearch } from "../../api/Search/SetSearch";
import api from "../../api/endpoints";
import { queryConstructor } from "../../api/formQuery";
import { getBulkLinkRecords } from "../../api/linkResolverApi";
import { usagelogApi } from "../../api/usageReportApi";
import SplitButton from "../../components/Switch/SplitButton";
import { useAppDispatch, useAppSelector } from "../../store/hooks";
import { updateQueries } from "../../store/slice/appliedQueries";
import {
  updateAuthorSearchResults,
  updateAuthorSort,
} from "../../store/slice/authorFinderSlice";
import {
  clearAllFilters,
  clearAllFiltersValue,
  clearPublicationFilter,
  updateAllFilter,
  updateAuthor,
  updateConsortiaCollection,
  updateCountryOfPublication,
  updateCountryOfPublishingAuthor,
  updateDateset,
  updateEarlyOnline,
  updateInstitution,
  updateJournal,
  updateJournalRank,
  updateMonthFrom,
  updateMyLibraryCollection,
  updateOpenAccess,
  updatePersonalLibrary,
  updatePublisher,
  updateResearch,
  updateResearcher,
  updateRetractedArticle,
  updateSort,
  updateSourceIndex,
  updateSourceType,
  updateSpeaker,
  updateSubject,
  updateYearFrom,
} from "../../store/slice/filterSlice";
import { updateQuery } from "../../store/slice/queryForCountSlice";
import {
  clearSearchTerm,
  updateAdvSearchQuery,
  updateAlertSettingData,
  updateAll,
  updateAllFacets,
  updateAuthorFacets,
  updateDataType,
  updateFullText,
  updateLinkToDisplay,
  updateNewSearchStatus,
  updatePage,
  updateRow,
  updateSavedArticles,
  updateSearchResults,
  updateSearchTerm,
  updateSubjectFacets,
} from "../../store/slice/searchSlice";
import {
  updateSetInNumber,
  updateSetSearchQuery,
  updateSetSearchStatus,
} from "../../store/slice/setSearch";
import { SaverArticlesType } from "../../types/interface";
import {
  getQueryParams,
  removeOperatorFromEnd,
  showIsProfileToast,
} from "../../utils/helper";
import { CustomTooltip } from "../MyLibrary/MyAlerts/Alerts.style";
import { SearchHistory, stringSanitizer } from "./SearchHistory";
import { searchHistoryStyles as sx } from "./SearchHistory.styles";
import SearchHistoryAlert from "./SearchHistoryAlert";
import SearchHistoryMenu from "./SearchHistoryMenu";
import { Delete, Save } from "@mui/icons-material";
import {
  deleteSearchHistory,
  savedSearchAlert,
} from "../../api/searchHistoryapi";
import { notify } from "../../utils/Notify";
import SaveSearchModal from "./SaveSearchModal";
import { checkAlertTopicAvailability } from "../../api/MyLibrary/SearchAlerts.api";
import {
  searchHistorySuccess,
  updateSearchHistoryResults,
} from "../../store/slice/searchHistorySlice";
import QueryBuilder from "./QueryBuilder";
import { Colors } from "../../utils/constants";

type Props = {
  searchHistory: SearchHistory[];
  fetchSearchHistory: () => Promise<void>;
};

export async function checkArticleExistance(
  dataArray: any[],
  userId: string,
  dispatch: any
) {
  if (!Array.isArray(dataArray)) return;
  const articleData = new FormData();

  const articleIds =
    Array.isArray(dataArray) && dataArray?.map((x: any) => x?.article_id);
  articleData.append("profile_id", userId);
  articleData.append("article_ids", articleIds.toString());

  const checkArticleExistance = await checkMultipleArticleExistence(
    articleData
  );

  const filter_is_saved =
    Array.isArray(checkArticleExistance) &&
    checkArticleExistance.filter(
      (x: SaverArticlesType) => x.is_saved !== false
    );
  dispatch(updateSavedArticles(filter_is_saved));
}

export async function linkToDisplay(
  dataArray: any[],
  customerData: any,
  dispatch: any
) {
  if (!Array.isArray(dataArray)) return;
  let formData = new FormData();

  let data = dataArray?.map((obj: any) => {
    let object: any = {
      customer_id: customerData?.informaticscustomer_id,
      article_id: obj?.article_id,
      consortia_virtualid: customerData?.consortia_virtualid,
      resource_type: obj?.resource_type,
      access_type: obj?.article_open_status || null,
      is_oa_article: obj?.fulltext || false,
    };

    if (obj?.articledoi) {
      object.article_doi = obj?.articledoi;
    }

    if (obj?.resourceissue_id) {
      object.resourceissue_id = obj?.resourceissue_id;
    }

    return object;
  });

  let formApiData = {
    data,
  };

  formData.append("detailed_json", JSON.stringify(formApiData));
  const response = await getBulkLinkRecords(formData);

  if (response?.message === "Ok") {
    dispatch(updateLinkToDisplay(response?.data));
  }
}

export async function findAlertTypeSetting(
  dataArray: any[],
  userId: string,
  dispatch: any
) {
  try {
    // const resourcemaster_ids =
    //   Array.isArray(dataArray) &&
    //   dataArray
    //     ?.map((x: any) => x?.resourcemaster_id)

    const resourcemaster_ids =
      Array.isArray(dataArray) &&
      dataArray
        ?.map((x: any) => x?.resourcemaster_id)
        .filter((id): id is number => id !== null && id !== undefined);

    if (Array.isArray(resourcemaster_ids)) {
      const alertSettingData = await getAlertSettings(
        userId,
        resourcemaster_ids
      );
      let alertDataRes = alertSettingData.data.data[0][0];
      dispatch(updateAlertSettingData(alertDataRes));
    }
  } catch (err) {
    console.log(err);
  }
}

const HistoryTable = ({ searchHistory, fetchSearchHistory }: Props) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const userId = useAppSelector((state) => state.login.user_id);
  const profileData = useAppSelector((state) => state.login);
  const customerData = useAppSelector(
    ({ customer }) => customer.customerDetails
  );
  const { user_id, csrf_token } = useAppSelector((state) => state.login);

  const [selectedSearches, setSelectedSearches] = useState<SearchHistory[]>([]);
  const [multiple, setMultiple] = useState(false);
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const [openRSSModal, setOpenRSSModal] = useState<boolean>(false);
  const [selectedMenuHistory, setSelectedMenuHistory] =
    useState<SearchHistory>();
  const [isSearching, setIsSearching] = useState<boolean>(false);
  const [rowNumber, setRowNumber] = useState<null | number>(null);
  const [saveSearchModal, setSaveSearchModal] = useState(false);
  const [alertTopic, setAlertTopic] = useState("");
  const [singleRow, setSingleRow] = useState<any>();
  const profileData1 = useAppSelector(
    (state) => state.customer.customerDetails
  );

  const logBasicSearchUsageData = (usageActionID: any, searchtypeId?: any) => {
    const user_ipv4_address =
      sessionStorage.getItem("user_ipv4_address") || null;

    const userMasterId = profileData.user_id ? profileData.user_id : null;
    const informaticscustomerId = profileData.informaticscustomer_id
      ? profileData.informaticscustomer_id
      : null;
    const consortiamasterId =
      profileData1 && profileData1.consortiamaster_id
        ? profileData1.consortiamaster_id
        : null;
    const sessionKey = profileData.session_key ? profileData.session_key : null;
    usagelogApi(
      userMasterId,
      informaticscustomerId,
      consortiamasterId,
      usageActionID,
      null,
      searchtypeId,
      null,
      null,
      null,
      null,
      // profileData.ip_v4,
      user_ipv4_address,
      null,
      sessionKey,
      1,
      null
    );
  };

  const handleKeywordClick = async (
    dirtyString: string,
    searchURL: string,
    searchType: string,
    row_number: number
  ) => {
    if (isSearching) return;

    const searchTerm = stringSanitizer(dirtyString);

    if (searchTerm.toLocaleUpperCase().includes("#S")) {
      handleSetSearchClick(searchURL);
      return;
    }
    if (!searchTerm) {
      dispatch(clearSearchTerm());
      setIsSearching(false);
      return;
    }
    const advanceSearchTerms = [
      "resourcemaster_id",
      "title_fz",
      "yearfrom",
      "keywords_fz",
      "author_address_fz",
      "issn",
      "articledoi",
      "author_all_fz",
      "resource_name_tk",
    ];
    const anotherSetOfAdvSearchTerms = [
      "(resourcemaster_id",
      "(title_fz",
      "(yearfrom",
      "(keywords_fz",
      "(author_address_fz",
      "(issn",
      "(articledoi",
      "(author_all_fz",
      "(resource_name_tk",
      "(titleKeywordsAbs",
      "((title_fz",
      "((yearfrom",
      "((keywords_fz",
      "((author_address_fz",
      "((issn",
      "((articledoi",
      "((author_all_fz",
      "((resource_name_tk",
      "((titleKeywordsAbs",
    ];
    let isAdvanceSearchTerm = advanceSearchTerms.some((term) =>
      new RegExp(`\\b${term}\\b`).test(dirtyString)
    );
    try {
      dispatch(clearSearchTerm());
      dispatch(clearAllFilters());
      dispatch(clearAllFiltersValue());
      dispatch(clearPublicationFilter());

      const missingQueryObj = {
        rows: 15,
        op_mode: "and",
      };

      const fullTextQuery = await queryConstructor({
        "fq__(fulltext": `true OR acl_group=(${profileData.informaticscustomer_id}))`,
        ...missingQueryObj,
        sort: "dateofpublication desc",
        datsearchtype_id:
          searchType === "Advanced Search"
            ? "2"
            : searchType === "Basic Search"
            ? "1"
            : searchType === "Author Search"
            ? "3"
            : "1",
      });
      const allTextQuery = await queryConstructor({
        ...missingQueryObj,
        sort: "dateofpublication desc",
        datsearchtype_id:
          searchType === "Advanced Search"
            ? "2"
            : searchType === "Basic Search"
            ? "1"
            : searchType === "Author Search"
            ? "3"
            : "1",
      });

      // replace the "&" with "~_~"
      let replacedQuery = searchURL.replace(/ & /g, ` ~_~ `);

      // fq__profileuser_id should be removed form the query
      let fqProfileQuery = `&fq__profileuser_id=${profileData.user_id}`;

      let actualFullTextQuery = fullTextQuery + "&" + replacedQuery;
      let actualAllextQuery =
        replacedQuery.replace(
          `&fq__(fulltext=true OR acl_group=(${profileData.informaticscustomer_id}))`,
          ""
        ) +
        "&" +
        allTextQuery;
      const queryParams = getQueryParams(actualAllextQuery);
      let countApiCall, recordApiCall;

      if (queryParams?.fq__profileuser_id) {
        countApiCall = personalCoreCount;
        recordApiCall = getPersonalLibraryCollection;
      } else {
        countApiCall = basicSearchForCounts;
        //recordApiCall = basicSearchMain;
        recordApiCall = basicSearchQuery;
      }

      actualFullTextQuery = actualFullTextQuery.replace(fqProfileQuery, "");
      actualAllextQuery = actualAllextQuery.replace(fqProfileQuery, "");

      dispatch(
        updateQuery({
          fullTextQuery: actualFullTextQuery,
          allTextQuery: actualAllextQuery,
        })
      );

      const queryParamsKeys = Object.keys(queryParams);
      let isAdv = anotherSetOfAdvSearchTerms.some((term) =>
        queryParamsKeys.includes(term)
      );
      isAdvanceSearchTerm = isAdvanceSearchTerm || isAdv;

      updateQueryParamsToRedux(
        queryParams,
        dirtyString,
        isAdvanceSearchTerm,
        row_number
      );
      //updateNewFormateQueryParamsToRedux(row_number);

      const fullTextData = await recordApiCall(actualFullTextQuery);
      const allText = await countApiCall(actualAllextQuery);
      const fulltext = await countApiCall(actualFullTextQuery);

      dispatch(updateSearchResults(fullTextData));
      dispatch(updateFullText(true));
      dispatch(updateAll(false));
      dispatch(updateRow(15));
      dispatch(updatePage(1));
      dispatch(updateSort("dateofpublication desc"));
      dispatch(updateNewSearchStatus(true));

      if (isAdvanceSearchTerm) {
        let advSearchObj: any = {};
        let arr = [...advanceSearchTerms, ...anotherSetOfAdvSearchTerms];

        arr.forEach((term) => {
          if (queryParams[term]) {
            advSearchObj[term] = queryParams[term];
          }
        });

        const advQuery = await queryConstructor(advSearchObj);
        let decodedQuery = decodeURIComponent(advQuery);
        dispatch(updateAdvSearchQuery(decodedQuery));
      }

      setIsSearching(false);
      const titleKeywordsAbs = queryParams?.titleKeywordsAbs;
      let x = dirtyString.replace(
        /author_address_fz:|title_fz:|keywords_fz:|author_all_fz:|issn:|resource_name_tk|articledoi:|resourcemaster_id:|yearfrom:/gi,
        ""
      );
      if (searchType === "Author Search" && fullTextData?.docs) {
        dispatch(updateAuthorSearchResults(fullTextData));
        navigate("/authorfindersearch?searchterm=" + searchTerm);
        dispatch(updateAuthorSort("dateofpublication desc"));
        return;
      }

      if (fullTextData?.docs) {
        navigate("/basicSearchScreen?searchterm=" + searchTerm, {
          //navigate("/basicSearchScreen?searchterm=" + titleKeywordsAbs, {
          state: {
            searchTerm: x,
            fullTextQuery: fullTextQuery,
            allQuery: searchURL,
            allCount: allText?.hits,
            fullCount: fulltext?.hits,
          },
        });
      }
      // const fullTextData = await recordApiCall(actualFullTextQuery);
      // const allText = await countApiCall(actualAllextQuery);
      // const fulltext = await countApiCall(actualFullTextQuery);

      await checkArticleExistance(fullTextData?.docs, userId, dispatch);
      await findAlertTypeSetting(fullTextData?.docs, userId, dispatch);
      await linkToDisplay(fullTextData?.docs, customerData, dispatch);

      const subjectFacetData = await subjectJsonFacetQuery(actualFullTextQuery);
      const authorFacetData = await authorJsonFacetQuery(actualFullTextQuery);
      const otherFacetData = await jsonFacetQuery(actualFullTextQuery);

      //JsonFacets
      dispatch(updateSubjectFacets(subjectFacetData?.jsonfacets));
      dispatch(updateAuthorFacets(authorFacetData?.jsonfacets));
      dispatch(updateAllFacets(otherFacetData?.jsonfacets));

      // dispatch(
      //   updateQuery({
      //     fullTextQuery: actualFullTextQuery,
      //     allTextQuery: actualAllextQuery,
      //   })
      // );

      // const queryParamsKeys = Object.keys(queryParams);
      // let isAdv = anotherSetOfAdvSearchTerms.some((term) =>
      //   queryParamsKeys.includes(term)
      // );
      // isAdvanceSearchTerm = isAdvanceSearchTerm || isAdv;

      // updateQueryParamsToRedux(queryParams, dirtyString, isAdvanceSearchTerm);

      // dispatch(updateSearchResults(fullTextData));
      // dispatch(updateFullText(true));
      // dispatch(updateAll(false));
      // dispatch(updateRow(15));
      // dispatch(updatePage(1));
      // dispatch(updateSort("dateofpublication desc"));
      // dispatch(updateNewSearchStatus(true));

      // if (isAdvanceSearchTerm) {
      //   let advSearchObj: any = {};
      //   let arr = [...advanceSearchTerms, ...anotherSetOfAdvSearchTerms];

      //   arr.forEach((term) => {
      //     if (queryParams[term]) {
      //       advSearchObj[term] = queryParams[term];
      //     }
      //   });

      //   const advQuery = await queryConstructor(advSearchObj);
      //   let decodedQuery = decodeURIComponent(advQuery);
      //   dispatch(updateAdvSearchQuery(decodedQuery));
      // }

      //console.log("queryParams", queryParams);
      //   setIsSearching(false);
      //   const titleKeywordsAbs = queryParams?.titleKeywordsAbs;
      //   let x = dirtyString.replace(
      //     /author_address_fz:|title_fz:|keywords_fz:|author_all_fz:|issn:|resource_name_tk|articledoi:|resourcemaster_id:|yearfrom:/gi,
      //     ""
      //   );
      //   if (searchType === "Author Search" && fullTextData?.docs) {
      //     dispatch(updateAuthorSearchResults(fullTextData));
      //     navigate("/authorfindersearch?searchterm=" + searchTerm);
      //     dispatch(updateAuthorSort("dateofpublication desc"));
      //     return;
      //   }

      //   if (fullTextData?.docs) {
      //     // navigate("/basicSearchScreen?searchterm=" + searchTerm, {
      //     navigate("/basicSearchScreen?searchterm=" + searchTerm, {
      //       state: {
      //         searchTerm: x,
      //         fullTextQuery: fullTextQuery,
      //         allQuery: searchURL,
      //         allCount: allText?.hits,
      //         fullCount: fulltext?.hits,
      //       },
      //     });
      //   }
    } catch (error) {
      setIsSearching(false);
    }
  };

  async function handleSetSearchClick(searchUrl: string) {
    const queryParams = getQueryParams(searchUrl);
    Object.assign(queryParams, { sort: "dateofpublication desc" });
    const isOnlyConsortiaAccess = customerData?.product_type === "7";
    if (isOnlyConsortiaAccess) {
      Object.assign(queryParams, {
        fq__acl_group: customerData.consortia_filter,
      });
    }
    console.log("asfadsfasd", queryParams);
    const query = await queryConstructor(queryParams);
    const response = await setSearch(query, "fulltext");
    // await linkToDisplay(response?.docs, customerData, dispatch);

    dispatch(updateSearchResults(response));
    queryParams?.set_number_in &&
      dispatch(updateSetInNumber(queryParams?.set_number_in));

    dispatch(
      updateAllFilter({
        key: "search-term",
        value: queryParams?.set_number_in,
      })
    );

    dispatch(
      updateQueries({
        key: "searchTerm",
        value: queryParams?.set_number_in,
      })
    );

    if (queryParams?.fq__yearfrom) {
      dispatch(updateYearFrom(queryParams.fq__yearfrom));
      dispatch(
        updateQueries({
          key: "currentYear",
          value: queryParams.fq__yearfrom,
        })
      );
    }

    dispatch(updateSetSearchQuery(query));
    dispatch(updateSetSearchStatus(true));
    const allText = await setSearch(query, "all", "count");
    const fullText = await setSearch(query, "fulltext", "count");

    if (response?.docs) {
      navigate("/basicSearchScreen?searchterm=" + queryParams?.set_number_in, {
        state: {
          allCount: allText?.hits,
          fullCount: fullText?.hits,
        },
      });
    }

    await linkToDisplay(response?.docs, customerData, dispatch);

    const subjectFacetData = await setSearch(
      query,
      "fulltext",
      "all",
      "true",
      false,
      "all",
      1
    );
    const authorFacetData = await setSearch(
      query,
      "fulltext",
      "all",
      "true",
      false,
      "all",
      2
    );
    const otherFacetData = await setSearch(
      query,
      "fulltext",
      "all",
      "true",
      false,
      "all",
      3
    );
    //JsonFacets states
    dispatch(updateSubjectFacets(subjectFacetData?.jsonfacets));
    dispatch(updateAuthorFacets(authorFacetData?.jsonfacets));
    dispatch(updateAllFacets(otherFacetData?.jsonfacets));
  }

  const updateNewFormateQueryParamsToRedux = (row_number?: number) => {
    // Updating search term
    dispatch(
      updateAllFilter({
        key: "search-term",
        value: `#S${row_number}`,
      })
    );
  };

  const updateQueryParamsToRedux = (
    queryParams: any,
    dirtyString: any,
    isAdvSearchTerm?: boolean,
    row_number?: number
  ) => {
    // Updating search term
    const titleKeywordsAbs = queryParams?.titleKeywordsAbs;
    console.log("df-2113", queryParams);
    titleKeywordsAbs && dispatch(updateSearchTerm(titleKeywordsAbs));
    if (
      queryParams?.author_address_fz ||
      queryParams?.title_fz ||
      queryParams?.keywords_fz ||
      queryParams?.author_all_fz ||
      queryParams?.issn ||
      queryParams?.articledoi ||
      queryParams?.resourcemaster_id ||
      queryParams?.yearfrom ||
      queryParams?.resource_name_tk ||
      isAdvSearchTerm
    ) {
      // let x = removeDuplicateTerms(dirtyString);
      if (titleKeywordsAbs != null && titleKeywordsAbs !== "") {
        dispatch(
          updateAllFilter({
            key: "search-term",
            value: titleKeywordsAbs,
          })
        );
      } else {
        dispatch(
          updateAllFilter({
            key: "search-term",
            value: `#S${row_number}`,
          })
        );
      }
    } else {
      if (titleKeywordsAbs?.replace("titleKeywordsAbs=", "")) {
        dispatch(
          updateAllFilter({
            key: "search-term",
            value: titleKeywordsAbs?.replace("titleKeywordsAbs=", ""),
          })
        );
      }
    }
    dispatch(
      updateQueries({
        key: "searchTerm",
        value: titleKeywordsAbs,
      })
    );
    //Update independent filters - fix for df 2113
    if (queryParams?.fq__fulltext) {
      dispatch(updateOpenAccess(true));
    }

    if (queryParams?.fq__has_datasets) {
      dispatch(updateDateset(true));
    }

    if (queryParams?.fq__is_retracted) {
      dispatch(updateRetractedArticle(true));
    }

    if (queryParams?.fq__pubmed_article) {
      dispatch(updateSourceIndex(true));
    }

    // Updating publication date filter
    if (queryParams?.fq__early_online) {
      dispatch(updateEarlyOnline(true));
      dispatch(
        updateQueries({
          key: "earlyOnline",
          value: true,
        })
      );
    }

    if (queryParams?.fq__yearfrom) {
      dispatch(updateYearFrom(queryParams.fq__yearfrom));
      dispatch(
        updateQueries({
          key: "currentYear",
          value: queryParams.fq__yearfrom,
        })
      );
    }

    if (queryParams?.fq__dateofpublication) {
      dispatch(updateMonthFrom(queryParams.fq__dateofpublication));
    }

    // Updating Collection filter
    if (queryParams?.fq__acl_group) {
      if (queryParams.fq__acl_group === customerData?.my_library_filter) {
        dispatch(updateMyLibraryCollection(true));
        dispatch(
          updateQueries({
            key: "myLibraryCollection",
            value: true,
          })
        );
      } else if (queryParams.fq__acl_group === customerData?.consortia_filter) {
        dispatch(updateConsortiaCollection(true));
        dispatch(
          updateQueries({
            key: "consortiaSubscriptions",
            value: true,
          })
        );
      }
    }

    if (queryParams?.fq__profileuser_id) {
      dispatch(updatePersonalLibrary(true));
      dispatch(
        updateQueries({
          key: "myPersonalLibraryCollection",
          value: true,
        })
      );
    }

    // Updating resource type
    if (queryParams?.fq__resource_type) {
      let arr = queryParams?.fq__resource_type
        .replace(/[^a-zA-Z0-9\s]/g, "")
        .split(" OR ")
        ?.map((str: string) => Number(str));

      dispatch(updateDataType(arr));
    }

    // Updating Filters
    if (queryParams?.fq__data_type) {
      // Document type filter
      const dataType = queryParams?.fq__data_type
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      console.log(queryParams?.fq__data_type.split("OR"));
      dispatch(updateSourceType(dataType));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(dataType) &&
        dataType.forEach((val: string) => {
          appliedFilter.push({
            key: "data_type",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__subjects_name_l3) {
      let subjectQuery = queryParams?.fq__subjects_name_l3
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });

      // Update subject filter
      dispatch(updateSubject(subjectQuery));
      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(subjectQuery) &&
        subjectQuery.forEach((val: string) => {
          appliedFilter.push({
            key: "subject",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__authors_tk) {
      const authorParam = queryParams?.fq__authors_tk
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateAuthor(authorParam));
      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(authorParam) &&
        authorParam.forEach((val: string) => {
          appliedFilter.push({
            key: "author",
            value: val,
          });
        });
      dispatch(updateAllFilter(appliedFilter));
      dispatch(updateQueries(appliedFilter));
    }

    if (queryParams?.fq__speakers) {
      const speakerParam = queryParams?.fq__speakers
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateSpeaker(speakerParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(speakerParam) &&
        speakerParam.forEach((val: string) => {
          appliedFilter.push({
            key: "speaker",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__guide_name_tk) {
      const guideParam = queryParams?.fq__guide_name_tk
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateResearch(guideParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(guideParam) &&
        guideParam.forEach((val: string) => {
          appliedFilter.push({
            key: "research",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__researcher_tk) {
      const researcherParam = queryParams?.fq__researcher_tk
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateResearcher(researcherParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(researcherParam) &&
        researcherParam.forEach((val: string) => {
          appliedFilter.push({
            key: "researcher",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__journal_name) {
      const journalParam = queryParams?.fq__journal_name
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateJournal(journalParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(journalParam) &&
        journalParam.forEach((val: string) => {
          appliedFilter.push({
            key: "journal",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__primary_publisher_country) {
      const primaryPublisherParam = queryParams?.fq__primary_publisher_country
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateCountryOfPublication(primaryPublisherParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(primaryPublisherParam) &&
        primaryPublisherParam.forEach((val: string) => {
          appliedFilter.push({
            key: "countryOfPublication",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__publisher_name) {
      const publisherNameParam = queryParams?.fq__publisher_name
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updatePublisher(publisherNameParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(publisherNameParam) &&
        publisherNameParam.forEach((val: string) => {
          appliedFilter.push({
            key: "publisher",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__author_address) {
      const authorAddressParam = queryParams?.fq__author_address
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateInstitution(authorAddressParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(authorAddressParam) &&
        authorAddressParam.forEach((val: string) => {
          appliedFilter.push({
            key: "institutions",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__filter_metrix) {
      const journalRankParam = queryParams?.fq__filter_metrix
        .split("OR")
        .map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateJournalRank(journalRankParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(journalRankParam) &&
        journalRankParam.forEach((val: string) => {
          appliedFilter.push({
            key: "journalRank",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }

    if (queryParams?.fq__author_country_name) {
      const countryOfPublishingAuthorParam =
        queryParams?.fq__author_country_name.split("OR").map((x: string) => {
          let str = x;
          str = str.trim().replace(/[()"]/g, "").replace("~_~", "&");
          return str;
        });
      dispatch(updateCountryOfPublishingAuthor(countryOfPublishingAuthorParam));

      // to Update the breadcurm
      let appliedFilter: any[] = [];
      Array.isArray(countryOfPublishingAuthorParam) &&
        countryOfPublishingAuthorParam.forEach((val: string) => {
          appliedFilter.push({
            key: "countryOfPublishingAuthor",
            value: val,
          });
        });
      dispatch(updateQueries(appliedFilter));
      dispatch(updateAllFilter(appliedFilter));
    }
  };

  const handleMenuClick =
    (row: SearchHistory) => (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      setSelectedMenuHistory(row);
      setAnchorEl(e.currentTarget);
    };

  const handleRSSClick =
    (row: SearchHistory) => (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();

      const profileStatus = sessionStorage.getItem("isProfileUser");
      if (profileStatus !== "true") {
        showIsProfileToast("Please login as profile user to set alert");
        return;
      }

      setSelectedMenuHistory(row);
      setOpenRSSModal(true);
    };

  const handleUsageLog = (data: any) => {
    const serachTypeId =
      data === "Basic Search"
        ? 1
        : data === "Advanced Search"
        ? 2
        : data === "Author Search"
        ? 3
        : data === "Browse By"
        ? 4
        : data === "Library Search"
        ? 5
        : data === "Consortium Search"
        ? 6
        : data === "Search History"
        ? 7
        : null;
    logBasicSearchUsageData(96, serachTypeId);
  };

  function removeDuplicateTerms(searchTerm: string) {
    // Split the search term into individual terms
    if (searchTerm.includes("title_fz") && searchTerm.includes("keywords_fz")) {
      let term = searchTerm.replace(
        /author_address_fz:|title_fz:|keywords_fz:|author_all_fz:|issn:|articledoi:|resourcemaster_id:|yearfrom:/gi,
        ""
      );
      const splitedArr = term?.split(/\s+(?![^()]*\))/);
      let newArr = [];

      for (let obj of splitedArr) {
        if (obj.startsWith("(") && obj.endsWith(")")) {
          let arr1 = obj?.slice(1, -1)?.replace(/[()]/g, "").split(" ");

          let arr2: string[] = [];
          for (let i = 0; i < arr1.length; i++) {
            if (!arr2.includes(arr1[i])) {
              arr2.push(arr1[i]);
            }
          }

          let val = `${removeOperatorFromEnd(arr2.join(" "))}`;
          newArr.push(val);
        } else {
          newArr.push(obj);
        }
      }
      for (let i = 0; i < newArr.length; i++) {
        if (newArr[i] === newArr[i + 2]) {
          newArr.splice(i + 1, 2);
        }
      }
      term = newArr.join(" ");
      return term;
    } else {
      let term = searchTerm.replace(
        /author_address_fz:|title_fz:|keywords_fz:|author_all_fz:|issn:|articledoi:|resourcemaster_id:|yearfrom:/gi,
        ""
      );
      return term;
    }
  }

  function handleSelected(item: SearchHistory) {
    if (
      selectedSearches
        ?.map((item) => {
          return item?.search_id;
        })
        ?.includes(item?.search_id)
    ) {
      setSelectedSearches(
        selectedSearches?.filter((obj) => obj?.search_id !== item?.search_id)
      );
    } else {
      setSelectedSearches([...selectedSearches, item]);
    }
  }

  function handleSelectAll() {
    if (
      selectedSearches?.length ===
      searchHistory?.filter((obj) => obj?.is_delete === false)?.length
    ) {
      setSelectedSearches([]);
    } else {
      setSelectedSearches(
        searchHistory?.filter((obj) => obj?.is_delete === false)
      );
    }
  }
  //Check if its Dev Environment
  const isDev = api.baseApi === "https://betaapi.jgatenext.com";

  const handleSingleDelete = async (row: any) => {
    const search_log_ids = row?.search_id;
    await deleteSearchHistory(search_log_ids, csrf_token).then(() => {
      fetchSearchHistory();
      notify("success", "Search Deleted");
      setSelectedSearches((prev) =>
        prev.filter((item) => item.search_id !== search_log_ids)
      );
    });
  };

  const handleMultilpeDelete = async () => {
    const search_log_ids = selectedSearches
      ?.map((item: any) => {
        return item?.search_id;
      })
      ?.join(",");

    await deleteSearchHistory(search_log_ids, csrf_token).then(() => {
      fetchSearchHistory();
      setSelectedSearches([]);
      notify("success", "Selected Searches Deleted");
    });
  };

  const handleSaveSearchHistory = async () => {
    if (!alertTopic) {
      notify("error", "Alert Topic is required!");
      return;
    }
    logBasicSearchUsageData(94);
    if (sessionStorage.getItem("isProfileUser") === "true") {
      const usersearchlog_id = singleRow?.search_id;

      const profileuser_id = user_id;
      const is_saved = true;

      // Check the alert topic availability
      const isTopicAvailable = await checkAlertTopicAvailability(alertTopic);
      if (isTopicAvailable === "Alert Topic already exists") {
        notify("error", isTopicAvailable);
        return;
      }

      try {
        let multipleData: any[] = [];
        multiple &&
          selectedSearches?.forEach((ele: any, index: number) => {
            multipleData.push({
              usermaster_id: profileuser_id,
              usersearchlog_id: ele.search_id,
              search_topic: ele?.search_type,
              is_saved: is_saved,
              alert_topic: alertTopic ? alertTopic + `(${index})` : "",
            });
          });
        const searchhistoryjson = multiple
          ? { data: multipleData }
          : {
              data: [
                {
                  usermaster_id: profileuser_id,
                  usersearchlog_id: usersearchlog_id,
                  search_topic: singleRow?.search_type,
                  is_saved: is_saved,
                  alert_topic: alertTopic ?? "",
                },
              ],
            };

        await savedSearchAlert({
          searchhistoryjson,
          profileuser_id,
        })
          .then((res) => {
            dispatch(updateSearchHistoryResults(res));
            dispatch(searchHistorySuccess(res));
          })
          .then(() => {
            fetchSearchHistory();
            notify("success", "Search Saved");
          });
        setSaveSearchModal(false);
        setMultiple(false);
        setAlertTopic("");
      } catch (err) {
        console.log(err);
      }
    } else {
      showIsProfileToast("Please login as Profile user to save the Search");
    }
  };

  const handleAlertTopic = (e: string) => {
    setAlertTopic(e);
  };

  const closeSaveSearchModal = () => {
    setSaveSearchModal(false);
  };

  const openSaveSearchModal = () => {
    if (sessionStorage.getItem("isProfileUser") !== "true") {
      showIsProfileToast("Please login as Profile user to save the Search");
      return;
    }
    setSaveSearchModal(true);
  };

  return (
    <Stack sx={sx.historyTable}>
      <Typography sx={sx.heading}>Search History</Typography>
      <QueryBuilder searchHistory={searchHistory} />

      <Box
        sx={{
          alignSelf: "flex-end",
          paddingBottom: "17px",
          paddingTop: "10px",
        }}
      >
        <SplitButton />
      </Box>

      <TableContainer component={Paper}>
        <Table sx={{ width: "100%" }} aria-label="simple table">
          <TableHead sx={sx.tableHead}>
            <TableRow sx={{ backgroundColor: Colors.coolGray100 }}>
              {
                <TableCell
                  sx={{
                    ...sx.BorderStyles,
                    paddingTop: "0px",
                    paddingBottom: "0px",
                  }}
                >
                  <Checkbox
                    checked={
                      selectedSearches?.length > 0 &&
                      selectedSearches?.length ===
                        searchHistory?.filter((obj) => obj?.is_delete === false)
                          ?.length
                    }
                    onClick={handleSelectAll}
                  />
                </TableCell>
              }
              <TableCell
                sx={{
                  ...sx.BorderStyles,
                  textAlign: "center",
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <Typography>#S</Typography>
              </TableCell>
              <TableCell
                sx={{
                  ...sx.BorderStyles,
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <Typography>Search Term</Typography>
              </TableCell>
              <TableCell
                sx={{
                  ...sx.BorderStyles,
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <Typography>Result Count</Typography>
              </TableCell>
              <TableCell
                sx={{
                  ...sx.BorderStyles,
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <Typography>Date</Typography>
              </TableCell>
              <TableCell
                sx={{
                  ...sx.BorderStyles,
                  paddingTop: "0px",
                  paddingBottom: "0px",
                }}
              >
                <Typography>Search Type</Typography>
              </TableCell>

              <TableCell>
                {selectedSearches.length > 0 && (
                  <Box sx={sx.actionBar}>
                    <IconButton
                      onClick={() => {
                        setMultiple(true);
                        openSaveSearchModal();
                      }}
                    >
                      <Save />
                    </IconButton>
                    <IconButton onClick={handleMultilpeDelete}>
                      <Delete />
                    </IconButton>
                  </Box>
                )}
              </TableCell>
              <TableCell />
            </TableRow>
          </TableHead>
          <TableBody>
            {searchHistory?.length > 0 &&
              searchHistory
                ?.filter((obj) => obj?.is_delete === false)
                ?.map((row, index) => (
                  <TableRow key={index}>
                    {
                      <TableCell sx={sx.BorderStyles}>
                        <Checkbox
                          checked={selectedSearches
                            ?.map((item) => {
                              return item?.search_id;
                            })
                            ?.includes(row?.search_id)}
                          onClick={() => {
                            handleSelected(row);
                          }}
                        />
                      </TableCell>
                    }
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{ ...sx.BorderStyles, textAlign: "center" }}
                    >
                      <Typography>{`S${row?.row_number}`}</Typography>
                    </TableCell>
                    <TableCell
                      // onClick={() => {
                      //   handleKeywordClick(
                      //     row.SearchTerms,
                      //     row.search_url,
                      //     row?.search_type
                      //   );
                      //   setIsSearching(true);
                      //   setRowNumber(row?.row_number);
                      //   handleUsageLog(row?.search_type);
                      // }}
                      sx={{
                        ...sx.BorderStyles,
                        // cursor: "pointer",
                        width: "30% !important",
                      }}
                    >
                      <Typography>
                        {removeDuplicateTerms(row.SearchTerms)}
                        {/* {row.SearchTerms.replace(
                        /author_address_fz:|title_fz:|keywords_fz:|author_all_fz:|issn:|articledoi:|resourcemaster_id:|yearfrom:/gi,
                        ""
                      )}{" "} */}
                        {/* ({row.result_count?.toLocaleString("en-US")}) */}
                      </Typography>
                    </TableCell>
                    <TableCell
                      onClick={() => {
                        handleKeywordClick(
                          row.SearchTerms,
                          row.search_url,
                          row?.search_type,
                          row?.row_number
                        );
                        setIsSearching(true);
                        setRowNumber(row?.row_number);
                        handleUsageLog(row?.search_type);
                      }}
                      sx={{
                        ...sx.BorderStyles,
                        ...sx.viewResult,
                        cursor: "pointer",
                        width: "20% !important",
                      }}
                    >
                      <Typography>
                        View Results{" "}
                        <span style={{ color: "#000", opacity: 0.8 }}>
                          ({row.result_count?.toLocaleString("en-US")})
                        </span>
                      </Typography>
                    </TableCell>
                    <TableCell sx={sx.BorderStyles}>
                      <Typography>
                        {" "}
                        {dayjs(row.createddate).format("MMM DD, YYYY")}
                      </Typography>
                    </TableCell>
                    <TableCell sx={sx.BorderStyles}>
                      <Typography>
                        {" "}
                        {row.search_type === "Advanced Search"
                          ? "Search Builder"
                          : row.search_type}
                        {/* <CustomTooltip
                        title={row?.mouse_over_data}
                        arrow
                        placement="top"
                      >
                        <IconButton size="small">
                          <InfoOutlinedIcon fontSize="inherit" />
                        </IconButton>
                      </CustomTooltip>  */}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Box sx={sx.actionBar}>
                        <Tooltip
                          title={
                            row.datalerttypeid > 0
                              ? "Modify Alert"
                              : "Set Alert"
                          }
                        >
                          <IconButton onClick={handleRSSClick(row)}>
                            <RSSIcon
                              sx={{
                                color: row.datalerttypeid > 0 ? "#F48120" : "",
                              }}
                            />
                          </IconButton>
                        </Tooltip>
                        {/* <IconButton onClick={handleMenuClick(row)}>
                          <MoreVert />
                        </IconButton> */}
                        <Tooltip
                          title={row?.is_saved ? "Search Saved" : "Save"}
                        >
                          <IconButton
                            onClick={() => {
                              setSingleRow(row);
                              openSaveSearchModal();
                            }}
                            disabled={row?.is_saved ? true : false}
                          >
                            <Save />
                          </IconButton>
                        </Tooltip>
                        <Tooltip title="Delete">
                          <IconButton onClick={() => handleSingleDelete(row)}>
                            <Delete />
                          </IconButton>
                        </Tooltip>
                      </Box>
                    </TableCell>
                    {rowNumber && rowNumber === row.row_number && (
                      <TableCell>
                        <CircularProgress color="primary" size={28} />
                      </TableCell>
                    )}
                  </TableRow>
                ))}
          </TableBody>
        </Table>

        <SearchHistoryMenu
          open={!!anchorEl}
          anchor={anchorEl}
          onClose={() => {
            setAnchorEl(null);
            setMultiple(false);
          }}
          selectedMenuHistory={selectedMenuHistory}
          refetch={fetchSearchHistory}
          multiple={multiple}
          selectedSearches={selectedSearches}
        />

        <SearchHistoryAlert
          selectedMenuHistory={selectedMenuHistory}
          open={openRSSModal}
          onClose={() => setOpenRSSModal(false)}
          refetch={fetchSearchHistory}
        />

        <SaveSearchModal
          open={saveSearchModal}
          headerText="Save the search"
          onClose={closeSaveSearchModal}
          handleSave={handleSaveSearchHistory}
          alertTopic={alertTopic}
          handleAlertTopicChange={handleAlertTopic}
        />
      </TableContainer>
    </Stack>
  );
};
export default HistoryTable;
